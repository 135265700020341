// TODO QUESTION: Does it make sense to hardcode the country to fr? Isn't it better to get it from the browser or the device language (if we're already doing that)

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useAuth } from '@/hooks/useAuth';
import './styles.css';

type InputPhoneNumberProps = {
    value?: string;
    onChange?: (value: string) => void;
    country?: string;
    disabled?: boolean;
    size?: 'small' | 'middle' | 'large';
};

export const InputPhoneNumber: React.FC<InputPhoneNumberProps> = ({ value, onChange, country, disabled = false, size = 'middle' }) => {
    const { user } = useAuth();

    return (
        <PhoneInput //
            country={country ?? user?.address?.country ?? 'fr'}
            value={value}
            onChange={onChange}
            disabled={disabled}
            containerClass="input-phone-number-container"
            inputClass={`input-phone-number-input input-phone-number-${size}`}
            dropdownClass="input-phone-number-dropdown"
            buttonClass={`input-phone-number-button input-phone-number-${size}`}
        />
    );
};
