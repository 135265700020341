import type React from 'react';
import { useTranslation } from 'react-i18next';
import { FormItemsBuilder, type FormItemsBuilderItem } from '@/components/FormItemsBuilder';
import { SimpleForm, type SimpleFormPropsFactory } from '@/components/SimpleForm';
import { useCyberpassConfigurationLookupByRef, useCyberpassVulnerabilityCreate } from '@/query';
import moment from 'moment';
import { useAuth } from '@/hooks/useAuth';
import { notifyWithIcon } from '@/utils/notifications';
import { type Vulnerability, type VulnerabilityProps } from '@red-alert-labs/cyberpass-client';

type VulnerabilityReportFormProps = SimpleFormPropsFactory<'vulnerability', Vulnerability> & { productId: string };

const buildDynamicFormItems = (items: FormItemsBuilderItem[], initialValue: Record<string, any>) => {
    return items.map((item) => {
        const property = Array.isArray(item.property) ? item?.property.join('.') : item.property;

        return {
            ...item,
            ...{
                ...(property != null
                    ? {
                          value: initialValue[property] ?? undefined,
                      }
                    : {}),
            },
        };
    });
};

export const VulnerabilityReportForm: React.FC<VulnerabilityReportFormProps> = ({ productId, vulnerability, editable, onSubmit, onSuccess, onError }) => {
    const { t } = useTranslation();
    const { user } = useAuth();

    const { data: vulnerabilityForm } = useCyberpassConfigurationLookupByRef('vulnerability-report', { onlyActive: true });

    const items = buildDynamicFormItems((vulnerabilityForm?.definition ?? []) as unknown as FormItemsBuilderItem[], vulnerability?.responses ?? {});
    const createVulnerability = useCyberpassVulnerabilityCreate();

    const handleSubmit = (values: VulnerabilityProps['responses'], done: () => void) => {
        onSubmit?.(values);

        const options = {
            onSuccess: (data: any) => {
                notifyWithIcon('success', t('COMPONENTS.VULNERABILITY_REPORT_FORM.NOTIFY.SUBMIT_SUCCEEDED'));
                if (data != null) {
                    onSuccess?.(data);
                }
            },
            onError: () => {
                notifyWithIcon('error', t('COMPONENTS.VULNERABILITY_REPORT_FORM.NOTIFY.SUBMIT_FAILED'));
                onError?.();
            },
            onSettled: () => {
                done();
            },
        };

        createVulnerability.mutate({ productId, responses: values }, options);
    };

    if (vulnerabilityForm?.definition == null || items.length === 0) {
        return null;
    }

    // TODO - Replace with a more robust solution
    const checkboxFieldIndex = items.findIndex((item) => item.property === 'checkbox-value');
    const filteredItems = items.filter((item, index) => {
        if (editable === false && (index === checkboxFieldIndex || index === checkboxFieldIndex - 1)) {
            return false;
        }
        if (item.property === 'files' && user == null) {
            return false;
        }
        return true;
    });

    return (
        <SimpleForm<VulnerabilityProps['responses']> //
            editable={editable}
            initialValues={
                vulnerability?.responses ?? {
                    'report-date': moment().toISOString(),
                    'reporter-email': user?.email,
                    'reporter-name': user?.fullName,
                }
            }
            onSubmit={handleSubmit}
            showCancel={false}
        >
            <FormItemsBuilder //
                items={filteredItems}
                editable={editable}
                showHelp={editable}
            />
        </SimpleForm>
    );
};
