import { type WorkspaceSearchFilters, type Query } from '@/utils/cyberpass';

const keys = {
    all: ['workspaces'],
    lists: () => [...keys.all, 'list'],
    list: (query: Query<WorkspaceSearchFilters> | undefined) => [...keys.lists(), query],
    details: () => [...keys.all, 'detail'],
    detail: (id: string | undefined) => [...keys.details(), id],
};

export const workspaceKeys = keys;
