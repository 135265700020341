import type React from 'react';
import { useState } from 'react';
import cyberpass, { type Product } from '@/utils/cyberpass';
import { VulnerabilityDrawer } from '../VulnerabilityDrawer';
import { Button, Space } from 'antd';
import { BugOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { VulnerabilityTable } from '../VulnerabilityTable';
import { InfoActionBar } from '../../InfoActionBar';
import { ExportSelector, ExportType } from '@/components/ExportSelector';
import { useAuth } from '@/hooks/useAuth';
import { useCyberpassVulnerabilitySearch } from '@/query';
import './styles.css';

type VulnerabilityTabProps = {
    product: Product;
};

export const VulnerabilityTab: React.FC<VulnerabilityTabProps> = ({ product }) => {
    const [openDrawer, setOpenDrawer] = useState<boolean>(false);
    const { user } = useAuth();
    const { t } = useTranslation();

    const { data: vulnerabilities } = useCyberpassVulnerabilitySearch(
        {
            pageNumber: 1,
            pageSize: 10000,
            filters: {
                productIds: [product?.id],
            },
        },
        {
            enabled: product?.id != null,
        },
    );

    return (
        <>
            <InfoActionBar
                extra={
                    <Space className="vulnerability-tab-info-action-bar">
                        {product.creatorUserId === user?.id && vulnerabilities != null && vulnerabilities?.items.length > 0 && (
                            <ExportSelector
                                exportItems={[
                                    {
                                        key: 'export-vulnerabilities',
                                        label: t('SHARED.EXPORT'),
                                        fileName: `vulnerabilities-${product.id}`,
                                        exportType: ExportType.ZIP,
                                        blobGetter: () => cyberpass.api.vulnerabilities.exportZip(product.id),
                                    },
                                ]}
                            />
                        )}
                        <Button
                            icon={<BugOutlined />}
                            onClick={() => {
                                setOpenDrawer(true);
                            }}
                        >
                            {t('COMPONENTS.PRODUCT_DRAWER.TAB_VULNERABILITY.BUTTON_REPORT_VULNERABILITY')}
                        </Button>
                    </Space>
                }
            />
            <VulnerabilityTable product={product} />
            <VulnerabilityDrawer
                productId={product?.id}
                open={openDrawer}
                onClose={() => {
                    setOpenDrawer(false);
                }}
            />
        </>
    );
};
