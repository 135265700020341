import { Button } from 'antd';
import type { FormInstance } from 'antd';
import { useIsFormSubmittable } from '@/hooks/useIsFormSubmittable';

type FormSubmitButtonProps = {
    form: FormInstance;
    label: string;
    loading?: boolean;
};

/**
 * @returns a submit button that is disabled until the attached form is fully validated!
 */
export const FormSubmitButton: React.FC<FormSubmitButtonProps> = ({ form, label, loading }) => {
    const { submittable } = useIsFormSubmittable(form);

    return (
        <Button htmlType="submit" type="primary" loading={loading} disabled={!submittable}>
            {label}
        </Button>
    );
};
