/* eslint-disable no-console */
const showLogs = false;

function fatal(msg: unknown, ...args: unknown[]): void {
    if (showLogs) {
        console.error(msg, ...args);
    }
}

function error(msg: unknown, ...args: unknown[]): void {
    if (showLogs) {
        console.error(msg, ...args);
    }
}

function warn(msg: unknown, ...args: unknown[]): void {
    if (showLogs) {
        console.warn(msg, ...args);
    }
}

function info(msg: unknown, ...args: unknown[]): void {
    if (showLogs) {
        console.info(msg, ...args);
    }
}

function debug(msg: unknown, ...args: unknown[]): void {
    if (showLogs) {
        console.debug(msg, ...args);
    }
}

function trace(msg: unknown, ...args: unknown[]): void {
    if (showLogs) {
        console.trace(msg, ...args);
    }
}

export const log = {
    fatal,
    error,
    warn,
    info,
    debug,
    trace,
};
