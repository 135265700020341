const keys = {
    all: ['schemes'],
    list: () => [...keys.all, 'list'],
    definition: (schemeId: string | undefined) => [...keys.all, 'definition', schemeId],
    lookupObjectsByCertificationId: (id: string | undefined) => [...keys.all, 'lookupObjectsByCertificationId', id],
    forms: () => [...keys.all, 'forms'],
    lookupFormById: (id: string | undefined) => [...keys.forms(), id],
    reportByCertificationId: (certificationId: string | undefined) => [...keys.all, 'reportByCertificationId', certificationId],
};

export const schemeKeys = keys;
