import { type Query } from '@/utils/cyberpass';

const keys = {
    all: ['forms'],
    lists: () => [...keys.all, 'list'],
    list: (query: Query | undefined) => [...keys.lists(), query],
    details: () => [...keys.all, 'detail'],
    detail: (id: string | undefined) => [...keys.details(), id],
    definitions: () => [...keys.all, 'definitions'],
    definitionsNodes: () => [...keys.all, 'nodes'],
    definition: (reference: string | undefined) => [...keys.definitions(), reference],
    // todo better names
    definitionMultiple: (references: string[] | undefined) => [...keys.definitions(), references],
    definitionNodes: (reference: string | undefined) => [...keys.definitionsNodes(), reference],
};

export const formKeys = keys;
