import { Form, Tooltip, type FormRule } from 'antd';

type FormItemProps = {
    name?: string | undefined | Array<string | undefined>;
    rules?: FormRule[] | undefined;
    className?: string;
    children?: React.ReactNode;
    tooltip?: string;
};

export const FormItem: React.FC<FormItemProps> = ({ name, rules, className, children, tooltip }) => {
    return (
        <Tooltip title={tooltip} placement="topLeft">
            <div id={`x-${name?.toString()}`}>
                <Form.Item className={className} name={name} rules={rules} hasFeedback>
                    {children}
                </Form.Item>
            </div>
        </Tooltip>
    );
};
