import { Divider } from 'antd';
import './styles.css';
type FormTitleFieldProps = {
    label?: React.ReactNode;
    disabled?: boolean;
};

export const FormTitleField: React.FC<FormTitleFieldProps> = ({ label }) => {
    return (
        <span className="form-title-field-container">
            {label}
            <Divider className="form-title-field-divider" />
        </span>
    );
};
