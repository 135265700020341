import type React from 'react';
import { Image } from 'antd';
import { defaultImage } from './defaults';
import { useCyberpassFileLookup } from '@/query';

type FileImageProps = {
    fileId: string | undefined;
    showPreview?: boolean;
    size?: number | 'small' | 'default' | 'large';
    className?: string;
};

// TODO add a wrapper which will split this component to be able to fetch file via lookup and to be able to use existing file

export const FileImage: React.FC<FileImageProps> = ({ fileId, size, className, showPreview = false }) => {
    const { data: file } = useCyberpassFileLookup(fileId ?? undefined);
    return (
        <Image
            src={file?.url ?? defaultImage}
            height={size}
            className={className}
            fallback={defaultImage}
            alt={file?.props.originalFileName}
            preview={showPreview}
        />
    );
};
