import { useState, useEffect, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Form, Space, Button } from 'antd';
import debounce from 'lodash.debounce';
import { FormSubmitButton } from '@/components/FormSubmitButton';

export * from './types';

type SimpleFormProps<T> = {
    initialValues?: Partial<T>;
    refreshOnInitialValuesChange?: boolean;
    // refreshOnInitialValuesChange prop enables updating current form value when it is changed externally(e.g. by cheat button)
    editable?: boolean;
    onValueChanges?: (values: T) => void;
    submitLabel?: string;
    onSubmit?: (values: T, done: () => void) => void;
    resetLabel?: string;
    cancelLabel?: string;
    onCancel?: () => void;
    onChange?: () => void;
    onInvalid?: (isInvalid: boolean) => void;
    showCancel?: boolean;
    showReset?: boolean;
    /**
     * - interactive: shows control buttons, saves updates when clicking on 'submit'
     * - automated: hides control buttons, auto-saves on every change
     */
    mode?: 'interactive' | 'automated';
    children: any;
};

/**
 * renders a fully working form with 3 typical submit/reset/cancel buttons, outsourcing only data handling
 */
export const SimpleForm = <T,>({
    initialValues = {},
    refreshOnInitialValuesChange = false,
    editable = true,
    onValueChanges,
    submitLabel,
    onSubmit,
    resetLabel,
    cancelLabel,
    onCancel,
    onChange,
    onInvalid,
    showCancel = true,
    showReset = true,
    mode = 'interactive',
    children,
}: SimpleFormProps<T>) => {
    const { t } = useTranslation();
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [formProps, setFormProps] = useState<T>(initialValues as T);
    const [form] = Form.useForm();
    const [isDirty, setIsDirty] = useState(false);

    const handleFinish = () => {
        setSubmitting(true);
        onSubmit?.(formProps, () => {
            setSubmitting(false);
            setIsDirty(false);
        });
    };

    const handleFinishFailed = () => {
        setSubmitting(false);
    };

    const handleCancel = () => {
        setSubmitting(false);
        onCancel?.();
    };

    const onSubmitRef = useRef(onSubmit);
    useEffect(() => {
        onSubmitRef.current = onSubmit;
    }, [onSubmit]);

    useEffect(() => {
        if (refreshOnInitialValuesChange && !isDirty) {
            form.setFieldsValue(initialValues);
            setFormProps(initialValues as T);
        }
    }, [initialValues]);

    const handleValuesChangeDebounced = useCallback(
        debounce(
            (_: any, values: T) => {
                setFormProps(values);
                onChange?.();
                onValueChanges?.(values);
                if (mode === 'automated') {
                    const isValid = !form.getFieldsError().some(({ errors }) => errors.length);
                    if (isValid) {
                        setSubmitting(true);
                        onSubmitRef.current?.(values, () => {
                            setSubmitting(false);
                            setIsDirty(false);
                            onInvalid?.(false);
                        });
                    }
                }
            },
            mode === 'automated' ? 500 : 0,
        ),
        [],
    );

    useEffect(() => {
        return () => {
            handleValuesChangeDebounced?.cancel();
        };
    }, []);

    return (
        <Form //
            form={form}
            initialValues={initialValues}
            onValuesChange={(changedValues, values) => {
                setTimeout(() => {
                    const hasErrors = form.getFieldsError().some((field) => field.errors.length);
                    onInvalid?.(hasErrors);
                }, 0);
                setIsDirty(true);
                handleValuesChangeDebounced(changedValues, values);
            }}
            onFinish={handleFinish}
            onFinishFailed={handleFinishFailed}
        >
            {children}
            {editable && mode === 'interactive' && (
                <>
                    <Divider />
                    <Space>
                        <FormSubmitButton form={form} loading={submitting} label={submitLabel ?? t('COMPONENTS.SIMPLE_FORM.BUTTON_SUBMIT')} />
                        {showReset && (
                            <Button htmlType="reset" disabled={submitting}>
                                {resetLabel ?? t('COMPONENTS.SIMPLE_FORM.BUTTON_RESET')}
                            </Button>
                        )}
                        {showCancel && (
                            <Button onClick={handleCancel} disabled={submitting}>
                                {cancelLabel ?? t('COMPONENTS.SIMPLE_FORM.BUTTON_CANCEL')}
                            </Button>
                        )}
                    </Space>
                </>
            )}
        </Form>
    );
};
