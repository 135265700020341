import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Select } from 'antd';
import { useCyberpassProductSearch } from '@/query';
import { type Product, ProductSearchFilters, Query, QuerySortDirection } from '@/utils/cyberpass';
import { ProductInfo } from '@/components/ProductInfo';
import { AddProductDrawer } from '@/components/AddProductDrawer';
import './styles.css';

type InputProductProps = {
    id?: string;
    value?: string; // product-id
    onChange?: (value: string | undefined) => void;
    disabled?: boolean;
    size?: 'small' | 'middle' | 'large';
    addNew?: {
        allow: boolean;
        workspaceId?: string;
        initialValue?: any;
    };
    query?: Query<ProductSearchFilters>;
};

export const InputProduct: React.FC<InputProductProps> = ({
    //
    id,
    value,
    onChange,
    disabled,
    size = 'middle',
    addNew,
    query,
}) => {
    const { t } = useTranslation();
    const [openAddProductDrawer, setOpenAddProductDrawer] = useState<boolean>(false);

    const { data: products, isLoading: loading } = useCyberpassProductSearch({
        filters: {
            asOwner: 'true',
        },
        sortBy: [
            {
                field: 'createdAt',
                direction: QuerySortDirection.DESC,
            },
        ],
        pageSize: 1000,
        ...query,
    });

    const handleChange = (productId: string | undefined) => {
        onChange?.(productId);
    };

    const selectedProduct = products?.items.find((product) => product.id === value);

    return (
        <div className="input-product-container">
            <Select
                id={id}
                showSearch
                size={size}
                showArrow={false}
                disabled={disabled}
                placeholder={t('COMPONENTS.INPUT_PRODUCT.INPUT_PLACEHOLDER')}
                loading={loading}
                onChange={handleChange}
                autoClearSearchValue
                allowClear
                value={null}
                options={(products?.items ?? []).map((product: Product) => ({
                    label: <ProductInfo id={product.id} showImage showName showDescription />,
                    value: product.id,
                    searchValue: product.name,
                }))}
                dropdownRender={(menu) => {
                    return (
                        <div>
                            {addNew?.allow && (
                                <Button className="input-product-add-button" type="link" onClick={() => setOpenAddProductDrawer(true)}>
                                    {t('COMPONENTS.INPUT_PRODUCT.ADD_NEW_PRODUCT_LABEL')}
                                </Button>
                            )}
                            {menu}
                        </div>
                    );
                }}
                optionFilterProp="children"
                filterOption={(input, option) => (option?.searchValue ?? '').toLowerCase().includes(input.toLowerCase())}
            />

            {selectedProduct != null && (
                <ProductInfo className="input-product-selected-container" id={selectedProduct?.id} showImage showName showDescription />
            )}

            <AddProductDrawer
                open={openAddProductDrawer}
                onClose={() => {
                    setOpenAddProductDrawer(false);
                }}
                onSuccess={(product: Product) => {
                    setOpenAddProductDrawer(false);
                    handleChange(product.id);
                }}
                creatorWorkspaceId={addNew?.workspaceId}
                initialValues={addNew?.initialValue}
            />
        </div>
    );
};
