import { UserOutlined, SafetyOutlined } from '@ant-design/icons';
import { useCyberpassUserGetDetails, useCyberpassWorkspaceLookupById } from '@/query';
import { WorkspaceType } from '@/utils/cyberpass';
import { FileAvatar } from '@/components/FileAvatar';
import { useAuth } from '@/hooks/useAuth';
import './styles.css';

type WorkspaceInfoProps = {
    id: string | undefined;
    className?: any;
    hidePrefix?: boolean;
    hideName?: boolean;
    hideAvatar?: boolean;
    hideRole?: boolean;
    hideOwner?: boolean;
    hideCompany?: boolean;
};

export const WorkspaceInfo: React.FC<WorkspaceInfoProps> = ({
    id,
    className,
    hideName = false,
    hidePrefix = false,
    hideAvatar = false,
    hideRole = false,
    hideOwner = true,
    hideCompany = true,
}) => {
    const { user } = useAuth();
    const { data: workspace } = useCyberpassWorkspaceLookupById(id);
    const { data: workspaceCreator } = useCyberpassUserGetDetails(workspace?.creatorUserId);

    if (workspace == null) {
        return null;
    }

    return (
        <div className={`workspace-info-container ${className ?? ''}`}>
            {!hideAvatar && (
                <FileAvatar className="workspace-info-avatar" fileId={workspace?.avatarFileId ?? undefined} size={hideRole && hideCompany ? 24 : 48} />
            )}
            <span className="workspace-info-content">
                <span className="workspace-info-workspace-name">
                    {!hidePrefix && workspace.type === WorkspaceType.PERSONAL ? (
                        <div>
                            <UserOutlined />
                        </div>
                    ) : null}
                    {((!hidePrefix && workspace.capabilities?.some((capability) => capability.startsWith('lab:'))) ?? false) ? (
                        <div>
                            <SafetyOutlined />
                        </div>
                    ) : null}
                    {!hideName && workspace.name}
                </span>
                {!hideRole && <span className="workspace-info-role">{workspace?.creatorUserId === user?.id ? 'Owner' : 'Member'}</span>}
                {!hideOwner && <span className="workspace-info-role">{workspaceCreator?.fullName ?? workspaceCreator?.email ?? '-'}</span>}
                {!hideCompany && <span className="workspace-info-role">{workspaceCreator?.company?.name ?? '-'}</span>}
            </span>
        </div>
    );
};
