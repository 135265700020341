import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';
import { ShoppingOutlined, DeploymentUnitOutlined } from '@ant-design/icons';
import { type Certification } from '@/utils/cyberpass';
import { ShadowCard } from '@/components/ShadowCard';
import { ResultTabTitle } from './ResultTabTitle';
import { ResultCapabilityTab } from './ResultCapabilityTab';
// import { AssuranceLevelTab } from './AssuranceLevelTab';
import './style.css';

const capabilityGroups = {
    PRODUCT_CAPABILITIES: [
        'HW_SW_HARDENING',
        'SECURE_UPDATES',
        'INTERFACE_ACCESS_CONTROL',
        'DATA_PROTECTION',
        'PRIVACY',
        'COMMUNICATE_SECURELY',
        'ENSURE_SOFTWARE_INTEGRITY',
        'RESILIENCE_TO_OUTAGES',
        // 'SECURE_BY_DEFAULT_CONFIGURATION',
        'LOGGING_NON_REPUDIATION',
    ],
    SUPPORTING_CAPABILITIES: [
        //
        'VULNERABILITY_HANDLING',
        // 'RISK_ASSESSMENT',
        'EASINESS_OF_INSTALLATION_AND_MAINTENANCE',
        'PII_TRANSPARENCY',
        // 'SECURE_BY_DESIGN',
    ],
};

type ResultTabsCardProps = {
    certification: Certification | undefined;
    isProductOwner?: boolean;
};

export const ResultTabsCard: React.FC<ResultTabsCardProps> = ({ certification, isProductOwner = false }) => {
    const { t } = useTranslation();

    const certificationCapabilities = certification?.evaluationResultProps?.scoring?.capabilities;

    const getCapabilities = (group: string[]) => {
        return group.map((capabilityName) => ({
            labelI18nKey: capabilityName,
            score: (certificationCapabilities?.[capabilityName]?.overallMaturityScore ?? 0) * 100,
        }));
    };

    return (
        <ShadowCard //
        >
            <Tabs
                defaultActiveKey="1"
                items={[
                    {
                        key: '1',
                        label: <ResultTabTitle icon={<ShoppingOutlined />} title={t('PAGES.RESULT.PRODUCT_CAPABILITIES')} />,
                        children: (
                            <>
                                <div className="result-tabs-card-capabilities-description">{t('PAGES.RESULT.PRODUCT_CAPABILITIES_DESCRIPTION')}</div>
                                <ResultCapabilityTab capabilities={getCapabilities(capabilityGroups.PRODUCT_CAPABILITIES)} />
                            </>
                        ),
                    },
                    {
                        key: '2',
                        label: <ResultTabTitle icon={<DeploymentUnitOutlined />} title={t('PAGES.RESULT.SUPPORTING_CAPABILITIES')} />,
                        children: (
                            <>
                                <div className="result-tabs-card-capabilities-description">{t('PAGES.RESULT.SUPPORTING_CAPABILITIES_DESCRIPTION')}</div>
                                <ResultCapabilityTab capabilities={getCapabilities(capabilityGroups.SUPPORTING_CAPABILITIES)} />
                            </>
                        ),
                    },
                    /* Hiding this tab until implementation */
                    // {
                    //     key: '3',
                    //     label: <ResultTabTitle icon={<DeploymentUnitOutlined />} title={t('PAGES.RESULT.ASSURANCE_LEVEL')} result="YES" />,
                    //     children: <AssuranceLevelTab />,
                    // },
                    // ...(certification != null && isProductOwner
                    //     ? [
                    //           {
                    //               key: '4',
                    //               label: <ResultTabTitle icon={<SafetyCertificateOutlined />} title={t('PAGES.RESULT.EVALUATION_BREAKDOWN')} />,
                    //               children: <QuestionnaireTable certificationId={certification.id} />,
                    //           },
                    //       ]
                    //     : []),
                ]}
            />
        </ShadowCard>
    );
};
