import type React from 'react';
import { Row, Col } from 'antd';
import './styles.css';

type FormItemLayoutProps = {
    description?: string;
    label?: React.ReactNode;
    value?: React.ReactNode;
    className?: string;
    containerClassName?: string;
    editable?: boolean;
    showLabel?: boolean;
    showBackground?: boolean;
    hidden?: boolean;
    required?: boolean;
    help?: React.ReactNode;
    labelSuffix?: React.ReactNode;
};

export const FormItemLayout: React.FC<FormItemLayoutProps> = ({
    description = '',
    label = '',
    labelSuffix,
    editable = true,
    value,
    className = '',
    containerClassName = '',
    showLabel = true,
    showBackground = true,
    hidden = false,
    required = false,
    help,
}) => {
    return showLabel ? (
        <Row hidden={hidden} className={containerClassName}>
            {description !== '' && (
                <Col span={24}>
                    <span className="form-item-layout-description" dangerouslySetInnerHTML={{ __html: description }} />
                </Col>
            )}
            {label !== '' && (
                <Col xs={24} md={8} xl={8} className={`form-item-layout-label ${editable ? 'form-item-layout-label-input' : ''}`}>
                    {label}:&nbsp;{editable && required ? <span style={{ color: 'var(--red-6)' }}>*</span> : null} {labelSuffix ?? null}
                </Col>
            )}

            <Col
                xs={24}
                md={16}
                xl={16}
                className={`form-item-layout-value ${showBackground ? 'form-item-layout-value-background' : 'form-item-layout-value-no-background'} ${editable ? 'form-item-layout-value-input' : ''} ${className}`}
            >
                {value}
                {help != null &&
                    (typeof help === 'string' ? (
                        <span className="form-item-layout-help" dangerouslySetInnerHTML={{ __html: help }} />
                    ) : (
                        <span className="form-item-layout-help">{help}</span>
                    ))}
            </Col>
        </Row>
    ) : (
        <Row hidden={hidden} className={containerClassName}>
            <Col
                span={24}
                className={`form-item-layout-value ${showBackground ? 'form-item-layout-value-background' : 'form-item-layout-value-no-background'} ${editable ? 'form-item-layout-value-input' : ''} ${className}`}
            >
                {value}
                {help != null &&
                    (typeof help === 'string' ? (
                        <span className="form-item-layout-help" dangerouslySetInnerHTML={{ __html: help }} />
                    ) : (
                        <span className="form-item-layout-help">{help}</span>
                    ))}
            </Col>
        </Row>
    );
};
