import { useQuery, useQueryClient, type UseQueryResult } from '@tanstack/react-query';
import cyberpass, { type Query, type FormIntegrationNode, type QueryResponse, type Form, type FormSearchFilters } from '@/utils/cyberpass';
import { formKeys as keys } from './index.keys';

export const useCyberpassFormSearch = (query: Query<FormSearchFilters> | undefined, options?: { enabled?: boolean }) => {
    const queryClient = useQueryClient();
    return useQuery<QueryResponse<Form> | undefined>(
        keys.list(query),
        async () => {
            if (query != null) {
                const response = await cyberpass.api.forms.search(query);
                response?.items?.map((form) => queryClient.setQueryData(keys.detail(form.id), form));
                return response;
            }
        },
        {
            enabled: query != null && (options?.enabled ?? true),
        },
    );
};

export const useCyberpassFormGetNodes = (reference: string | undefined): UseQueryResult<FormIntegrationNode[]> => {
    return useQuery(
        keys.definitionNodes(reference),
        async () => {
            if (reference != null) {
                return cyberpass.api.forms.getFormNodes(reference);
            }
        },
        {
            enabled: reference != null,
        },
    );
};
