import moment, { type Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';
import { TbCalendarStats } from 'react-icons/tb';
import './styles.css';

type SmartDateProps = {
    date: string | Moment | undefined;
    defaultText?: string;
    tooltip?: string;
    showExpirationColors?: boolean;
    showTime?: boolean;
    showIcon?: boolean;
    icon?: JSX.Element;
    className?: string;
};

export const SmartDate: React.FC<SmartDateProps> = ({
    date,
    defaultText = '-',
    tooltip,
    showExpirationColors = false,
    showTime = false,
    showIcon = false,
    icon = <TbCalendarStats />,
    className,
}) => {
    const { t } = useTranslation();

    const now = moment();
    const theDate = moment(date);
    const threeMonthBeforeDate = moment(theDate).subtract(3, 'months');
    const expirationClassNames = showExpirationColors
        ? now.isBefore(threeMonthBeforeDate)
            ? 'smart-date-before'
            : now.isAfter(theDate)
              ? 'smart-date-after'
              : 'smart-date-danger'
        : 'smart-date-before';

    return (
        <Tooltip title={tooltip}>
            <span className={`smart-date-container ${expirationClassNames} ${className}`}>
                {showIcon && icon}
                {date == null ? defaultText : theDate.format(t(showTime ? 'LOCALE.DATE_TIME' : 'LOCALE.DATE'))}
            </span>
        </Tooltip>
    );
};
