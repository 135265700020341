import { type RcFile } from 'antd/lib/upload';
import { FileType, FileValidationError } from '@/types/file';
import cyberpass from './cyberpass';
import { notifyWithIcon } from './notifications';

// TODO cleanup / move elsewhere ...

export const formatByteFileSize = (bytes: number, decimals = 2): string => {
    if (bytes <= 0) {
        return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${Number.parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const getFileTypeByMimeType = (mimeType: string): FileType => {
    if (mimeType.startsWith('application/pdf')) {
        return FileType.PDF;
    }
    if (mimeType.startsWith('image/') || mimeType === 'application/octet-stream') {
        return FileType.IMAGE;
    }
    if (mimeType.startsWith('text/') || mimeType.startsWith('application/') || ['application/xml', 'application/json'].includes(mimeType)) {
        return FileType.DOCUMENT;
    }
    if (mimeType.startsWith('video/')) {
        return FileType.VIDEO;
    }
    return FileType.UNKNOWN;
};

export const isFileMimeTypeValid = (fileMimeType: string, supportedMimeTypes: string[]): boolean => {
    if (supportedMimeTypes.includes('*')) {
        return true;
    }
    if (supportedMimeTypes.includes('image/*') && fileMimeType.startsWith('image/')) {
        return true;
    }
    if (supportedMimeTypes.includes(fileMimeType)) {
        return true;
    }
    return false;
};

export const saveBlob = (blob: Blob, fileName?: string) => {
    const a = document.createElement('a');
    document.body.append(a);
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    if (fileName != null) {
        a.download = fileName;
    }
    a.click();
    window.URL.revokeObjectURL(url);
};

export const triggerFileDownload = async (file: any) => {
    try {
        const blob = await cyberpass.api.files.proxy(file.id ?? file.uid);
        saveBlob(blob, file?.props?.originalFileName ?? file.name);
        notifyWithIcon('success', `${file?.props?.originalFileName ?? file.name} is downloaded successfully`);
    } catch (error) {
        if (error instanceof Error) {
            notifyWithIcon('error', `${error.message}`);
        }
    }
};

export const validateFileBeforeUpload = ({
    file,
    fileList,
    totalFilesCount,
    maxFileCount,
    supportedMimeTypes,
}: {
    /** file that is getting validating before uploading */
    file: RcFile;
    /** all files selected to be uploaded together */
    fileList: RcFile[];
    totalFilesCount?: number;
    maxFileCount?: number;
    supportedMimeTypes?: string[];
}): { isValid: boolean; error?: FileValidationError } => {
    if (maxFileCount != null) {
        const currentFilesNumber = totalFilesCount ?? 0;
        const totalAfterUpload = currentFilesNumber + fileList.length;
        if (totalAfterUpload > maxFileCount) {
            return { isValid: false, error: FileValidationError.MAX_FILE_COUNT };
        }
    }
    if (supportedMimeTypes != null && !isFileMimeTypeValid(file.type, supportedMimeTypes)) {
        return {
            isValid: false,
            error: FileValidationError.INVALID_FILE_TYPE,
        };
    }
    return { isValid: true };
};

/**
 * @description
 * This function is called after "beforeUpload" returns true
 * It uploads the file to our backend, notifies the user and calls "onSuccess" or "onError" to continue the upload process
 */
// export const uploadFile = async ({ fileProps, options }: { fileProps?: FilePropsProps; options: UploadRequestOption }) => {
//     const { onSuccess, onError, file } = options;
//     try {
//         const formData = new FormData();
//         formData.append('files[]', file);
//         const res = await cyberpass.api.files.create(formData);
//         if (fileProps != null) {
//             await cyberpass.api.files.update(res.data[0].id, { props: fileProps });
//         }
//         const uploadedFile: File = res.data[0];
//         if (onSuccess != null) {
//             onSuccess(uploadedFile);
//         }
//     } catch (error) {
//         if (onError != null) {
//             onError(new Error(JSON.stringify(error)));
//         }
//     }
// };
