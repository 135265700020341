import de from '@/locales/de.json';
import en from '@/locales/en.json';
import fr from '@/locales/fr.json';
import ko from '@/locales/ko.json';
import zh from '@/locales/zh.json';

const resources = {
    de: { translation: de },
    en: { translation: en },
    fr: { translation: fr },
    ko: { translation: ko },
    zh: { translation: zh },
};

export default { resources };
