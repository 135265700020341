import { type DefaultOptionType } from 'antd/lib/cascader';
import { FormDiffValue } from '@/utils/form-diff';
import { Query } from '@/utils/cyberpass';
// TODO jy fix file-* item-types:
// - all file-id based
// - types: 'file-single' + 'file-multiple'
// - options:
//   - mode: avatar | image | carousel | list | count(=showFileCount)
//   - accepted(Mime)Types
//   - min/max
// TODO jy fix text-* item-types: apply same (type+mode) as for file-*

export type FormItemsBuilderItemType =
    | 'address'
    | 'amount'
    | 'certification-reference'
    | 'checkbox-disclaimer'
    | 'choices-editable'
    | 'country'
    | 'cascader'
    | 'date'
    | 'date-range'
    | 'divider'
    | 'title'
    | 'dropdown-multiple'
    | 'dropdown-single'
    | 'file-avatar'
    | 'file-carousel'
    | 'file-downloader'
    | 'file-image'
    | 'language'
    | 'number'
    | 'product'
    | 'workspace'
    | 'tags'
    | 'text-area'
    | 'text-email'
    | 'text-email-multiple'
    | 'text-phone'
    | 'text-provider'
    | 'text-url'
    | 'text'
    | 'toggle';

export type FormItemsBuilderItemValue = number | string | string[] | React.ReactNode | JSX.Element | any;

export enum FormItemsBuilderItemMode {
    VIEW = 'VIEW',
    EDIT = 'EDIT',
}

export type FormItemsBuilderItem = {
    type: FormItemsBuilderItemType;
    description?: string;
    label?: string;
    value?: FormItemsBuilderItemValue;
    property?: string | string[];
    options?: {
        render?: (value: any, mode?: FormItemsBuilderItemMode | undefined) => FormItemsBuilderItemValue; // TODO apply this optionally everywhere!
        editable?: boolean;
        showLabel?: boolean;
        showBackground?: boolean;
        required?: boolean;
        hidden?: boolean;
        tooltip?: string;
        disabled?: boolean | ((value: any) => boolean); // renders but disables visually values matching this condition
        placeholder?: string;
        help?: React.ReactNode;
        prefix?: React.ReactNode;
        rules?: any; // TODO add proper type
        min?: number; // applicable to 'number' type
        max?: number; // applicable to 'number' type
        choices?: any[];
        type?: string | string[]; // used for 'dimensions' and 'file'(=render-mode:FileUploaderType)
        acceptedTypes?: string[][]; // TODO LATER rename to acceptedMimeType
        showFileCount?: boolean; // show #-files instead of file list in view-mode
        className?: string;
        multiple?: boolean;
        showSearch?: boolean;
        displayRender?: (labels: string[], selectedOptions: DefaultOptionType[] | undefined) => React.ReactNode;
        addNew?: {
            allow: boolean;
            workspaceId?: string;
            initialValue?: any;
        };
        query?: Query<any>;
    };
    diff?: FormDiffValue;
};

export type FormItemsBuilderItems = FormItemsBuilderItem[];

export type FormItemsBuilderItemRender = {
    view: (item: FormItemsBuilderItem) => string | string[] | JSX.Element | JSX.Element[] | null | undefined;
    edit: (item: FormItemsBuilderItem) => string | string[] | JSX.Element | JSX.Element[] | null | undefined;
};
