import { AuthContext } from '@/contexts/AuthContext';
import { useContext } from 'react';

export const useAuth = () => {
    const authContext = useContext(AuthContext);
    if (authContext === undefined) {
        throw new Error('AuthProvider is undefined');
    }
    return authContext;
};
