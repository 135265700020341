import { useMemo } from 'react';
import { useCyberpassSchemeEvaluationObjectsLookupByCertificationId } from '@/query';
import { ConfigurationType, type Certification } from '@/utils/cyberpass';
import { getEvaluationFlowStatus } from '@/utils/evaluation';
import { EvaluationFlowStatusTag } from '@/components/EvaluationFlowStatusTag';
import { useActiveConfigurationDefinition } from '@/utils/configurations';

type EvaluationFlowStatusInfoProps = {
    certification: Certification | undefined;
};

export const EvaluationFlowStatusInfo: React.FC<EvaluationFlowStatusInfoProps> = ({ certification }) => {
    const { data: assessmentObjects } = useCyberpassSchemeEvaluationObjectsLookupByCertificationId(certification?.id);
    const { data: schemeDefinition } = useActiveConfigurationDefinition(certification?.schemeId, ConfigurationType.SCHEME);
    const status = useMemo(
        () => getEvaluationFlowStatus({ certification: assessmentObjects?.certification, forms: assessmentObjects?.forms, schemeDefinition }),
        [assessmentObjects, schemeDefinition],
    );

    return <EvaluationFlowStatusTag status={status ?? undefined} certificationStatus={certification?.status} certificationResult={certification?.result} />;
};
