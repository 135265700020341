import { Image } from 'antd';
import { type Product } from '@/utils/cyberpass';
import { FileImage } from '@/components/FileImage';
import { getProductDefaultImage } from './utils';
import './styles.css';

type ProductImageProps = {
    product: Product | undefined;
    className?: string;
    showPreview?: boolean;
    isThumbnail?: boolean;
};

export const ProductImage: React.FC<ProductImageProps> = ({ product, className, isThumbnail, showPreview = false }) => {
    const productImageId = product?.props?.featuredImageFileId;

    return productImageId == null ? (
        <Image
            src={getProductDefaultImage(product?.id ?? '')}
            preview={showPreview}
            className={`${className} ${(isThumbnail ?? false) ? 'product-image-thumbnail' : ''}`}
        />
    ) : (
        <FileImage fileId={productImageId} showPreview={showPreview} className={`${className} ${(isThumbnail ?? false) ? 'product-image-thumbnail' : ''}`} />
    );
};
