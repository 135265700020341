import i18next from '@/utils/i18next';
import {
    DimensionType,
    type Product,
    ProductSectoralDimension,
    ProductFunctionalDimension,
    ProductTechnologicalDimension,
    type Workspace,
} from '@/utils/cyberpass';
import { DimensionTag } from '@/components/DimensionTag';
import { type FormItemsBuilderItems, FormItemsBuilderItemMode } from '@/components/FormItemsBuilder';
import { FileUploaderType } from '@/components/FileUploader';
import { SupportedMimeType } from '@/types/file';
import { WorkspaceInfo } from '@/components/WorkspaceInfo';

export const buildProductFormItems = (product: Product | undefined, workspace: Workspace | undefined, userId: string | undefined): FormItemsBuilderItems => {
    const showWorkspace = product != null ? (workspace?.membersUserIds.includes(userId ?? '') ?? false) : true;

    return [
        {
            label: i18next.t('COMPONENTS.PRODUCT_FORM.FILES_FEATURED_IMAGE'),
            type: 'file-image',
            property: ['props', 'featuredImageFileId'],
            value: product?.props?.featuredImageFileId,
            options: {
                type: FileUploaderType.IMAGE,
                acceptedTypes: [SupportedMimeType.ALL_IMAGE_TYPES],
            },
        },
        {
            label: i18next.t('COMPONENTS.PRODUCT_FORM.NAME'),
            type: 'text',
            property: 'name',
            value: product?.name,
            options: {
                required: true,
            },
        },
        {
            label: i18next.t('COMPONENTS.PRODUCT_FORM.PRODUCT_UNIQUE_ID'),
            type: 'text',
            property: 'uniqueCode',
            value: product?.uniqueCode,
        },
        {
            label: i18next.t('COMPONENTS.PRODUCT_FORM.SECTORAL_DIMENSION'),
            type: 'dropdown-multiple',
            property: 'sectoral',
            value: product?.sectoral,
            options: {
                type: [DimensionType.PRODUCT_SECTORAL],
                choices: Object.values(ProductSectoralDimension),
                render: (value: ProductSectoralDimension, mode: FormItemsBuilderItemMode | undefined) => (
                    <DimensionTag type={DimensionType.PRODUCT_SECTORAL} dimension={value} showName showAsTag={mode === FormItemsBuilderItemMode.VIEW} />
                ),
            },
        },
        {
            label: i18next.t('COMPONENTS.PRODUCT_FORM.FUNCTIONAL_DIMENSION'),
            type: 'dropdown-multiple',
            property: 'functional',
            value: product?.functional,
            options: {
                type: [DimensionType.PRODUCT_FUNCTIONAL],
                choices: Object.values(ProductFunctionalDimension),
                render: (value: ProductFunctionalDimension, mode: FormItemsBuilderItemMode | undefined) => (
                    <DimensionTag type={DimensionType.PRODUCT_FUNCTIONAL} dimension={value} showName showAsTag={mode === FormItemsBuilderItemMode.VIEW} />
                ),
            },
        },
        {
            label: i18next.t('COMPONENTS.PRODUCT_FORM.TECHNOLOGICAL_DIMENSION'),
            type: 'dropdown-multiple',
            property: 'technological',
            value: product?.technological,
            options: {
                type: [DimensionType.PRODUCT_TECHNOLOGICAL],
                choices: Object.values(ProductTechnologicalDimension),
                render: (value: ProductTechnologicalDimension, mode: FormItemsBuilderItemMode | undefined) => (
                    <DimensionTag type={DimensionType.PRODUCT_TECHNOLOGICAL} dimension={value} showName showAsTag={mode === FormItemsBuilderItemMode.VIEW} />
                ),
            },
        },
        {
            label: i18next.t('COMPONENTS.PRODUCT_FORM.DESCRIPTION'),
            type: 'text-area',
            property: 'description',
            value: product?.description,
            options: {
                required: true,
            },
        },
        {
            label: i18next.t('COMPONENTS.PRODUCT_FORM.FILES_PRODUCT_IMAGES'),
            type: 'file-carousel',
            property: ['props', 'regularImageFileIds'],
            value: product?.props?.regularImageFileIds,
            options: {
                type: FileUploaderType.IMAGE,
                acceptedTypes: [SupportedMimeType.ALL_IMAGE_TYPES],
            },
        },
        {
            label: i18next.t('COMPONENTS.PRODUCT_FORM.FILES_BROCHURES'),
            type: 'file-downloader',
            property: ['props', 'brochureFileIds'],
            value: product?.props?.brochureFileIds,
            options: {
                type: FileUploaderType.FILE,
                acceptedTypes: [SupportedMimeType.ALL_TYPES],
            },
        },
        {
            label: i18next.t('COMPONENTS.PRODUCT_FORM.WORKSPACE'),
            type: 'text',
            options: {
                editable: false,
                hidden: !showWorkspace,
                prefix: <WorkspaceInfo id={workspace?.id} />,
            },
        },
    ];
};
