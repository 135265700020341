import type React from 'react';
import { Avatar } from 'antd';
import { FileImage } from '@/components/FileImage';

type FileAvatarProps = {
    fileId: string | undefined;
    showPreview?: boolean;
    size?: number | 'small' | 'default' | 'large';
    className?: string;
};

export const FileAvatar: React.FC<FileAvatarProps> = ({ fileId, showPreview = false, size, className }) => {
    return (
        <Avatar //
            size={size}
            shape="circle"
            src={<FileImage fileId={fileId} size={size} className={className} showPreview={showPreview} />}
            draggable={false}
        />
    );
};
