import { type Query } from '@/utils/cyberpass';

const keys = {
    all: ['certifications'],
    lists: () => [...keys.all, 'list'],
    list: (query: Query | undefined) => [...keys.lists(), query],
    listByProduct: (productId: string | undefined) => [...keys.lists(), 'by-product', productId],
    details: () => [...keys.all, 'detail'],
    detail: (id: string | undefined) => [...keys.details(), id],
    bySerialNumber: (serialNumber: string | undefined) => [...keys.all, 'serialNumber', serialNumber],
};

export const certificationKeys = keys;
