import i18next from '@/utils/i18next';
import { Cyberpass } from '@red-alert-labs/cyberpass-client';
import config from './config';
export * from '@red-alert-labs/cyberpass-client';

export const cyberpass = new Cyberpass({
    baseUrl: config.BACKEND_BASE_URL,
    i18next,
});

export default cyberpass;
