import type React from 'react';
import { Select } from 'antd';

export type InputDropdownChoice = {
    label?: React.ReactNode | JSX.Element | string;
    value: string;
    disabled?: boolean;
};

type InputDropdownProps = {
    id?: string;
    value?: string[];
    defaultValue?: string[];
    onChange?: (value: string[]) => void;
    disabled?: boolean;
    size?: 'small' | 'middle' | 'large';
    choices: InputDropdownChoice[];
    multiple?: boolean;
    style?: React.CSSProperties;
};

export const InputDropdown: React.FC<InputDropdownProps> = ({
    id,
    value,
    defaultValue,
    onChange,
    size = 'middle',
    choices,
    multiple = false,
    style,
    disabled,
}) => {
    const handleChange = (value: string[]) => {
        onChange?.(Array.isArray(value) ? value : [value]);
    };

    return (
        <Select //
            id={id}
            disabled={disabled}
            value={value}
            defaultValue={defaultValue}
            onChange={handleChange}
            mode={multiple ? 'multiple' : undefined}
            size={size}
            options={choices.map((choice) => ({
                label: choice.label ?? choice.value,
                value: choice.value,
                disabled: choice.disabled,
            }))}
            style={style}
        />
    );
};
