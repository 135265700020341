import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { type CreateSchemeEvaluation, type SchemeEvaluationObjects, type Form } from '@/utils/cyberpass';
import cyberpass from '@/utils/cyberpass';
import { schemeKeys as keys, certificationKeys, copilotKeys, formKeys, schemeKeys } from './index.keys';
import { defaultOnError } from './helpers';

export const useCyberpassSchemeEvaluationCreate = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async (props: CreateSchemeEvaluation) => {
            return cyberpass.api.schemes.createSchemeEvaluation(props);
        },
        onSuccess: (data: SchemeEvaluationObjects) => {
            queryClient.setQueryData(keys.lookupObjectsByCertificationId(data.certification.id), data);
        },
        onError: defaultOnError,
    });
};

export const useCyberpassSchemeEvaluationObjectsLookupByCertificationId = (id: string | undefined) => {
    return useQuery<SchemeEvaluationObjects | undefined>(
        keys.lookupObjectsByCertificationId(id),
        async () => {
            if (id != null) {
                const response = await cyberpass.api.schemes.getSchemeEvaluationObjectsByCertificationId(id);
                return response;
            }
        },
        { enabled: id != null },
    );
};

export const useCyberpassSchemeEvaluationSubmit = (certificationId: string | undefined) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async () => {
            if (certificationId != null) {
                return cyberpass.api.schemes.submitSchemeEvaluation(certificationId);
            }
        },
        onSuccess: (data) => {
            if (data != null) {
                queryClient.invalidateQueries(certificationKeys.all);
                queryClient.setQueryData(keys.lookupObjectsByCertificationId(data.certification.id), data);
                for (const form of data.forms) {
                    queryClient.setQueryData(keys.lookupFormById(form.id), form);
                }
            }
        },
        onError: defaultOnError,
    });
};

export const useCyberpassSchemeAssessmentImportResponses = (certificationId: string | undefined) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async (props: { fileId: string }) => {
            if (certificationId != null) {
                return cyberpass.api.schemes.importResponses(certificationId, props);
            }
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries(certificationKeys.detail(certificationId));
            queryClient.invalidateQueries(schemeKeys.lookupObjectsByCertificationId(certificationId));
            queryClient.invalidateQueries(schemeKeys.forms());
            queryClient.invalidateQueries(formKeys.all);
        },
        onError: defaultOnError,
    });
};

export const useCyberpassSchemeEvaluationFormLookupById = (id: string | undefined) => {
    return useQuery<Form | undefined>(
        keys.lookupFormById(id),
        async () => {
            if (id != null) {
                return cyberpass.api.schemes.getSchemeForm(id);
            }
        },
        {
            enabled: id != null,
        },
    );
};

export const useCyberpassSchemeEvaluationFormUpdateById = (id: string | undefined) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async (props: Record<string, any>) => {
            if (id != null) {
                return cyberpass.api.schemes.updateSchemeFormResponses(id, props);
            }
        },
        onSuccess: (data) => {
            if (data != null) {
                queryClient.setQueryData(keys.lookupFormById(data.id), data);
                queryClient.invalidateQueries(keys.lookupObjectsByCertificationId(data.certificationId));
                queryClient.invalidateQueries(copilotKeys.detailByKey(undefined, id));
            }
        },
        onError: defaultOnError,
    });
};

export const useCyberpassSchemeEvaluationLabReportTemplateByCertificationIdCreate = () => {
    return useMutation({
        mutationFn: async (certificationId: string) => {
            if (certificationId != null) {
                return cyberpass.api.schemes.getLabReportTemplate(certificationId);
            }
        },
        onError: defaultOnError,
    });
};
