import type React from 'react';
import { useTranslation } from 'react-i18next';
import { CustomDrawer } from '@/components/CustomDrawer';
import { ProductForm } from '../ProductForm';
import { Product } from '@/utils/cyberpass';

type AddProductDrawerProps = {
    open: boolean | undefined;
    onClose: () => void;
    onSuccess?: (product: Product) => void;
    creatorWorkspaceId?: string;
    initialValues?: Partial<Product>;
};

export const AddProductDrawer: React.FC<AddProductDrawerProps> = ({ open, onClose, onSuccess, creatorWorkspaceId, initialValues }) => {
    const { t } = useTranslation();

    return (
        <CustomDrawer open={open} onClose={onClose} title={t('PAGES.PRODUCTS.DRAWER_TITLE_ADD_PRODUCT')}>
            <ProductForm
                //
                product={undefined}
                editable={true}
                creatorWorkspaceId={creatorWorkspaceId}
                onSubmit={onClose}
                onCancel={onClose}
                onSuccess={onSuccess ?? onClose}
                initialValues={initialValues}
            />
        </CustomDrawer>
    );
};
