import { Col, Row } from 'antd';
import { RiskLevel } from '../../PercentageBar';
import { AssuranceScoreCardLegendItem } from '../AssuranceScoreCardLegendItem';
import './styles.css';

const getLevelColumn = (riskLevel: RiskLevel) => {
    return (
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <AssuranceScoreCardLegendItem riskLevel={riskLevel} />
        </Col>
    );
};

export const AssuranceScoreCardLegend = () => {
    return (
        <>
            <Row className="assurance-score-card-progress-bar-row">
                {getLevelColumn(RiskLevel.MINIMALISTIC)}
                {getLevelColumn(RiskLevel.ADEQUATE)}
            </Row>
            <Row className="assurance-score-card-progress-bar-row">
                {getLevelColumn(RiskLevel.ROBUST)}
                {getLevelColumn(RiskLevel.EXCEPTIONAL)}
            </Row>
        </>
    );
};
