import moment, { type Moment } from 'moment';
import type React from 'react';
import { DatePicker } from 'antd';

type InputDateProps = {
    id?: string;
    value?: Moment | null;
    onChange?: (value: Moment | null) => void;
    size?: 'small' | 'middle' | 'large';
    disabled?: boolean;
};

export const InputDate: React.FC<InputDateProps> = ({ id, value, onChange, size = 'middle', disabled = false }) => {
    const handleChange = (value: Moment | null) => {
        onChange?.(value);
    };

    return (
        <DatePicker
            id={id}
            size={size}
            value={value != null ? moment(value) : undefined}
            onChange={handleChange}
            style={{ width: '100%' }}
            disabled={disabled}
        />
    );
};
