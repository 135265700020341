import type React from 'react';
import { Select } from 'antd';
import { languageOptions, type LanguageOptionCountries, displayLanguage } from '@/utils/i18next';

type InputLanguageProps = {
    id?: string;
    size?: 'large' | 'middle' | 'small';
    defaultValue?: string;
    value?: string;
    onChange?: (value: string) => void;
    disabled?: boolean;
    showSearch?: boolean;
    placeholder?: string;
    style?: React.CSSProperties;
};

export const InputLanguage: React.FC<InputLanguageProps> = ({
    id,
    size = 'middle',
    value,
    defaultValue,
    onChange,
    showSearch = false,
    placeholder,
    style,
    disabled,
}) => {
    return (
        <Select
            id={id}
            disabled={disabled}
            style={style}
            size={size}
            value={value}
            defaultValue={defaultValue}
            onChange={onChange}
            showSearch={showSearch}
            placeholder={placeholder}
        >
            {Object.keys(languageOptions).map((key) => (
                <Select.Option key={key} value={key}>
                    {displayLanguage(key as LanguageOptionCountries)}
                </Select.Option>
            ))}
        </Select>
    );
};
