import { Modal } from 'antd';
import { t } from 'i18next';
import config from './config';
import { QuotaProps } from '@/types/quotas';

export const notifyOnReachedQuota = (quota: QuotaProps): void => {
    Modal.confirm({
        title: t(`SHARED.QUOTAS.ON_REACHED_NOTIFICATION.${quota.type}.TITLE`),
        content: t(`SHARED.QUOTAS.ON_REACHED_NOTIFICATION.${quota.type}.CONTENT`, { max: quota.max, current: quota.current }),
        okText: t('SHARED.QUOTAS.ON_REACHED_NOTIFICATION.OK_TEXT'),
        onOk: () => {
            window.open(`mailto:${config.SALES_EMAIL}`);
        },
    });
};
