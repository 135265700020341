import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { PagePublicLayout } from '@/components/PagePublicLayout';
import { ErrorPage } from '@/pages/ErrorPage';
import { LoginPage } from '@/pages/LoginPage';
import { ResultPublicPage } from '@/pages/ResultPage';

const LazyProtectedRouter = React.lazy(() => import('./ProtectedRouter'));

export const RootRouter = () => {
    return (
        <Routes>
            <Route path="/">
                <Route element={<PagePublicLayout />}>
                    <Route path="login" element={<LoginPage />} />
                    <Route path="certification/:serialNumber" element={<ResultPublicPage />} />
                </Route>
                <Route path="*" element={<LazyProtectedRouter />} />
                <Route index element={<Navigate to={'dashboard'} replace />} />
            </Route>
            <Route path="*" element={<ErrorPage />} />
        </Routes>
    );
};
