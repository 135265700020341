import { type UIEvent } from 'react';
import type React from 'react';
import { useTranslation } from 'react-i18next';
import { Tag, Tooltip } from 'antd';
import { type DimensionType, type ProductFunctionalDimension, type ProductSectoralDimension, type ProductTechnologicalDimension } from '@/utils/cyberpass';
import { icons } from './icons';
import { colorClassNames } from './colorClassNames';

type DimensionTagProps = {
    type: DimensionType;
    dimension: ProductSectoralDimension | ProductFunctionalDimension | ProductTechnologicalDimension;
    onClose?: (event: UIEvent, dimension: string, type?: DimensionType) => void;
    prefix?: string;
    empty?: string | JSX.Element;
    showAsTag?: boolean;
    showIcon?: boolean;
    showName?: boolean;
    showPrefix?: boolean;
    style?: React.CSSProperties;
};

export const DimensionTag: React.FC<DimensionTagProps> = ({
    type,
    dimension,
    onClose,
    empty = '-',
    showAsTag = true,
    showIcon = true,
    showName = true,
    showPrefix = false,
    style,
}) => {
    const { t } = useTranslation();
    const closable = onClose != null;

    const handleTagClose = (event: UIEvent) => {
        if (closable) {
            onClose(event, dimension, type);
        }
    };

    if (dimension == null || dimension.length === 0 || type == null) {
        return <>{empty}</>;
    }

    const tagContent = (
        <>
            {showPrefix && <span style={{ paddingRight: 2 }}>{t(`COMPONENTS.DIMENSION_TAG.${type}_PREFIX`)}: </span>}
            {showIcon && (icons[type] as any)[dimension]}
            {showName && <span style={{ paddingLeft: 2 }}>{type == null ? `${dimension}` : ` ${t(`SHARED.DIMENSIONS.${type}.${dimension}`)}`}</span>}
        </>
    );

    return (
        <Tooltip title={t(`SHARED.DIMENSIONS.${type}.${dimension}`)}>
            {showAsTag ? (
                <Tag
                    className={colorClassNames[type]}
                    closable={closable}
                    onClose={closable ? handleTagClose : undefined}
                    style={{ display: 'inline-flex', alignItems: 'center', color: 'var(--gray-13)', ...style }}
                >
                    {tagContent}
                </Tag>
            ) : (
                <span className={`dimension-tag-content-${type}`}>{tagContent}</span>
            )}
        </Tooltip>
    );
};
