import { AutoComplete } from 'antd';
import type React from 'react';
import { useEffect, useState } from 'react';

type CustomInputDropdownChoice = {
    label?: React.ReactNode | JSX.Element | string;
    value: string;
    disabled?: boolean;
};

type CustomInputDropdownProps = {
    id?: string;
    value?: string;
    defaultValue?: string;
    onChange?: (value: string) => void;
    disabled?: boolean;
    size?: 'small' | 'middle' | 'large';
    choices: CustomInputDropdownChoice[];
    style?: React.CSSProperties;
};

export const CustomInputDropdown: React.FC<CustomInputDropdownProps> = ({ id, value, defaultValue, onChange, disabled, size, choices, style }) => {
    const [options, setOptions] = useState(choices ?? []);

    const handleSearch = (searchValue: string) => {
        const filteredOptions = choices.filter((option) => option?.value?.toLowerCase()?.includes(searchValue.toLowerCase()));
        setOptions(filteredOptions);
    };

    const handleSelect = (selectedValue: string) => {
        onChange?.(selectedValue);
    };

    useEffect(() => {
        setOptions(choices);
    }, [choices]);

    return (
        <AutoComplete
            id={id}
            style={{ ...style, width: '100%' }}
            options={options}
            value={value}
            defaultValue={defaultValue}
            disabled={disabled}
            size={size}
            placeholder="Select an option"
            filterOption={(inputValue, option) => option?.value?.toUpperCase()?.includes(inputValue.toUpperCase()) ?? false}
            onSearch={handleSearch}
            onSelect={handleSelect}
            onChange={onChange}
        />
    );
};
