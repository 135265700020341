import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Table, Modal } from 'antd';
import { useAuth } from '@/hooks/useAuth';
import { VulnerabilityDrawer } from '../VulnerabilityDrawer';
import { useCyberpassVulnerabilitySearch, useCyberpassVulnerabilityUpdate } from '@/query';
import { type Product, VulnerabilityStatus, type Vulnerability } from '@red-alert-labs/cyberpass-client';
import { InputDropdown } from '@/components/FormItemsBuilder/InputDropdown';
import { NoEntriesInfo } from '@/components/NoEntriesInfo';
import Red6Competition from '@/assets/images/red-6-competition.svg';
import './styles.css';

type VulnerabilityTableProps = {
    product: Product;
};
export const VulnerabilityTable: React.FC<VulnerabilityTableProps> = ({ product }) => {
    const { t } = useTranslation();
    const [selectedVulnerability, setSelectedVulnerability] = useState<Vulnerability | undefined>();
    const { currentWorkspace } = useAuth();
    const [openDrawer, setOpenDrawer] = useState<boolean>(false);
    const { data: vulnerabilities, isLoading: loading } = useCyberpassVulnerabilitySearch(
        {
            pageNumber: 1,
            pageSize: 10000,
            filters: {
                productIds: [product?.id],
            },
        },
        {
            enabled: product?.id != null,
        },
    );

    const vulnerabilityUpdateMutation = useCyberpassVulnerabilityUpdate(selectedVulnerability?.id);
    const handleVulnerabilityStatusChange = (status: string) => {
        Modal.confirm({
            title: t('COMPONENTS.VULNERABILITY_TABLE.MODAL_CHANGE_STATUS.TITLE'),
            content: t('COMPONENTS.VULNERABILITY_TABLE.MODAL_CHANGE_STATUS.CONTENT'),
            okButtonProps: { type: 'primary' },
            onOk() {
                vulnerabilityUpdateMutation.mutate({
                    status: status as VulnerabilityStatus,
                });
            },
            onCancel() {
                // nop
            },
        });
    };

    const columns: any = [
        {
            title: t('COMPONENTS.VULNERABILITY_TABLE.COLUMN_VULNERABILITY_IDENTIFIER'),
            key: 'id',
            align: 'left',
            render: (vulnerability: Vulnerability) => vulnerability.responses['vulnerability-identifier'] ?? '-',
        },
        {
            title: t('COMPONENTS.VULNERABILITY_TABLE.COLUMN_REPORTED_DATE'),
            key: 'report-date',
            align: 'left',
            render: (vulnerability: Vulnerability) => moment(vulnerability.createdAt).format('YYYY-MM-DD'),
        },
        ...(currentWorkspace != null && currentWorkspace.id === product.workspaceId
            ? [
                  {
                      title: t('COMPONENTS.VULNERABILITY_TABLE.COLUMN_STATUS'),
                      key: 'status',
                      align: 'left',
                      render: (vulnerability: Vulnerability) => (
                          <div
                              onClick={(e) => {
                                  e.stopPropagation();
                                  setSelectedVulnerability(vulnerability);
                              }}
                          >
                              <InputDropdown
                                  value={[vulnerability.status]}
                                  onChange={(value) => {
                                      handleVulnerabilityStatusChange(value[0]);
                                  }}
                                  choices={
                                      Object.values(VulnerabilityStatus).map((status) => ({
                                          label: t(`COMPONENTS.VULNERABILITY_TABLE.STATUS.${status}`),
                                          value: status,
                                      })) ?? []
                                  }
                              />
                          </div>
                      ),
                  },
              ]
            : []),
    ];

    const handleDrawerClose = () => {
        setOpenDrawer(false);
    };

    if (!loading && (vulnerabilities == null || vulnerabilities.items.length === 0)) {
        return (
            <NoEntriesInfo
                className="no-vulnerabilities-action-content-container"
                image={Red6Competition}
                fact={t('COMPONENTS.VULNERABILITY_TABLE.NO_ENTRIES.FACT')}
                message={t('COMPONENTS.VULNERABILITY_TABLE.NO_ENTRIES.MESSAGE')}
            />
        );
    }

    return (
        <>
            <Table
                size="small"
                loading={loading}
                bordered={false}
                scroll={{ y: 'calc(100vh - 258px)', x: '100%' }} // note: 100vh => table-bbox > page-content-bbox, hence giving a little extra room to the only flexible column
                columns={columns}
                dataSource={vulnerabilities?.items?.map((vulnerability) => ({ key: vulnerability.id, ...vulnerability }))}
                onRow={(vulnerability) => {
                    return {
                        onClick: (_) => {
                            setSelectedVulnerability(vulnerability);
                            setOpenDrawer(true);
                        },
                    };
                }}
                pagination={false}
                rowClassName="vulnerability-table-row"
            />
            {selectedVulnerability != null && (
                <VulnerabilityDrawer
                    key={selectedVulnerability.id}
                    open={openDrawer}
                    onClose={handleDrawerClose}
                    vulnerability={selectedVulnerability}
                    productId={selectedVulnerability.productId}
                />
            )}
        </>
    );
};
