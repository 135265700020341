import { type Query } from '@/utils/cyberpass';

const keys = {
    all: ['vulnerabilities'],
    lists: () => [...keys.all, 'list'],
    list: (query: Query | undefined) => [...keys.lists(), query],
    details: () => [...keys.all, 'detail'],
    detail: (id: string | undefined) => [...keys.details(), id],
};

export const vulnerabilityKeys = keys;
