import { type Query } from '@/utils/cyberpass';

const keys = {
    all: ['files'],
    lists: () => [...keys.all, 'list'],
    list: (query: Query) => [...keys.lists(), query],
    details: () => [...keys.all, 'detail'],
    detail: (id: string | undefined) => [...keys.details(), id],
};

export const fileKeys = keys;
