import { useTranslation } from 'react-i18next';
import { useCyberpassCertificationLookupByProductId } from '@/query';
import { type Product } from '@/utils/cyberpass';
import { CertificationsTable } from '@/components/CertificationsTable';

type ProductCertificationsTableProps = {
    product: Product | undefined;
    onClickStartEvaluation?: () => void;
    showAsSubTable?: boolean;
    isOwner?: boolean;
};

export const ProductCertificationsTable: React.FC<ProductCertificationsTableProps> = ({
    product,
    onClickStartEvaluation,
    showAsSubTable = false,
    isOwner = false,
}) => {
    const { t } = useTranslation();
    const { data: certifications, isLoading: loading } = useCyberpassCertificationLookupByProductId(product?.id);

    return loading ? (
        <i>{t('SHARED.LOADING')}...</i>
    ) : (
        <CertificationsTable
            certifications={certifications}
            showTitle
            onClickStartEvaluation={onClickStartEvaluation}
            showAsSubTable={showAsSubTable}
            isOwner={isOwner}
            showAlternateEmptyState
        />
    );
};
