import React from 'react';
import { Modal, Button, Tooltip } from 'antd';

type ActionButtonProps = {
    className?: string;
    icon?: JSX.Element;
    label?: string;
    type?: 'default' | 'primary';
    danger?: boolean;
    withConfirmation?: boolean;
    title?: string;
    confirmationText?: string | React.ReactNode;
    okText?: string;
    onClick?: () => void;
    size?: 'small' | 'middle' | 'large';
    shape?: 'circle' | 'default' | 'round';
    enabled?: boolean;
    tooltip?: string;
    hidden?: boolean;
    disabled?: boolean;
};

export const ActionButton: React.FC<ActionButtonProps> = ({
    className,
    icon,
    label,
    title,
    type = 'default',
    danger = false,
    withConfirmation = false,
    confirmationText = '?',
    okText = 'OK',
    onClick,
    size = 'middle',
    shape = 'default',
    enabled = true,
    tooltip,
    hidden = false,
    disabled = false,
}) => {
    const color = danger ? 'red' : 'black';

    const handleClick = enabled
        ? () => {
              if (withConfirmation || danger) {
                  Modal.confirm({
                      title,
                      content: confirmationText,
                      okText,
                      okButtonProps: danger ? { danger: true, type: 'primary' } : {},
                      onOk() {
                          onClick?.();
                      },
                      onCancel() {
                          // nop
                      },
                  });
              } else {
                  onClick?.();
              }
          }
        : undefined;

    return hidden ? null : (
        <Tooltip title={tooltip ?? label} color={color}>
            <Button
                type={type}
                className={`.action-button ${className ?? ''}`}
                size={size}
                shape={shape}
                danger={danger}
                disabled={disabled}
                icon={
                    icon != null
                        ? React.cloneElement(icon, {
                              color: enabled ? (color ?? 'blue') : 'lightgrey',
                          })
                        : null
                }
                onClick={handleClick}
            >
                {label}
            </Button>
        </Tooltip>
    );
};
