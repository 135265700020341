import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import { type Certification } from '@/utils/cyberpass';
import { AboutLabDrawer } from '@/components/AboutLabDrawer';
import { useCyberpassFileLookup, useCyberpassUserGetDetails, useCyberpassWorkspaceLookupById } from '@/query';
import { ReadMore } from '@/components/ReadMore';
import { UserBasicInfo } from '@/components/UserBasicInfo';
import { DownloadCertificateDrawer } from '@/components/DownloadCertificateDrawer';
import Red12Timeline from '@/assets/images/red-12-timeline.svg';
import './styles.css';

type FailEvaluationResultProps = {
    certification: Certification | undefined;
};

export const FailEvaluationResult: React.FC<FailEvaluationResultProps> = ({ certification }) => {
    const { t } = useTranslation();
    const [showAboutLabDrawer, setShowAboutLabDrawer] = React.useState(false);
    const { data: reviewerWorkspace } = useCyberpassWorkspaceLookupById(certification?.reviewerWorkspaceId);
    const { data: reviewerWorkspaceCreator } = useCyberpassUserGetDetails(reviewerWorkspace?.creatorUserId);
    const avatarFile = useCyberpassFileLookup(reviewerWorkspaceCreator?.company?.logoFileId);

    return (
        <Row gutter={32}>
            <Col style={{ width: 230 }}>
                <img src={Red12Timeline} style={{ maxWidth: 160, display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />
                <DownloadCertificateDrawer certification={certification} lab={reviewerWorkspaceCreator} />
            </Col>

            <Col style={{ flex: 1 }}>
                <div className="evaluation-result-card-failed-container">
                    <span className="evaluation-result-card-failed">{t('COMPONENTS.EVALUATION_RESULT_CARD.FAILED')}</span>
                </div>
                <span className="evaluation-result-card-about-lab-container">{t('COMPONENTS.EVALUATION_RESULT_CARD.FAILED_DESCRIPTION')}</span>
                {reviewerWorkspaceCreator != null && (
                    <>
                        <div className="evaluation-result-card-about-container">{t('COMPONENTS.EVALUATION_RESULT_CARD.ABOUT_LAB')}</div>
                        <div className="evaluation-result-card-about-row">
                            <div className="evaluation-result-card-about-image-col">
                                {avatarFile.data != null && <img className="evaluation-result-card-image" src={avatarFile.data.url} />}
                            </div>

                            <div className="evaluation-result-card-about-lab-container">
                                <ReadMore
                                    html={reviewerWorkspaceCreator?.company?.about}
                                    buttonClassName="evaluation-result-card-about-lab-container-read-more-button"
                                />
                            </div>
                        </div>
                        <div
                            className="evaluation-result-card-more-about-card-container"
                            onClick={() => {
                                setShowAboutLabDrawer(true);
                            }}
                        >
                            {t('COMPONENTS.EVALUATION_RESULT_CARD.MORE_ABOUT_LAB')}{' '}
                            {reviewerWorkspaceCreator != null ? <UserBasicInfo id={reviewerWorkspaceCreator?.id} showFullName={false} showCompanyName /> : ''}
                        </div>
                    </>
                )}
                <AboutLabDrawer
                    labUserId={reviewerWorkspaceCreator?.id}
                    onClose={() => {
                        setShowAboutLabDrawer(false);
                    }}
                    open={showAboutLabDrawer}
                />
            </Col>
        </Row>
    );
};
