import { useCyberpassUserGetDetails } from '@/query';

type UserBasicInfoProps = {
    id: string;
    showFullName?: boolean;
    showCompanyName?: boolean;
    defaultEmpty?: string;
    className?: string;
};

export const UserBasicInfo: React.FC<UserBasicInfoProps> = ({ id, showFullName = true, showCompanyName = false, defaultEmpty = '-', className }) => {
    const { data: user } = useCyberpassUserGetDetails(id);

    const line = [
        //
        showFullName ? (user?.fullName ?? defaultEmpty) : undefined,
        showCompanyName ? (user?.company?.name ?? defaultEmpty) : undefined,
    ]
        .filter((item) => item != null && item.trim().length > 0)
        .join(', ');

    return <span className={className}>{line}</span>;
};
