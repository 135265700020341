import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
    type QueryResponse,
    type Configuration,
    type ConfigurationSearchFilters,
    type Query,
    type ConfigurationProps,
    type ConfigurationLookupOptions,
} from '@/utils/cyberpass';
import cyberpass from '@/utils/cyberpass';
import { configurationKeys as keys } from './index.keys';
import { defaultOnError } from './helpers';

export const useCyberpassConfigurationCreate = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async (props: ConfigurationProps) => {
            return cyberpass.api.configurations.create(props);
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries(keys.lists());
            queryClient.invalidateQueries(keys.detailByRef(data.ref));
        },
        onError: defaultOnError,
    });
};

export const useCyberpassConfigurationLookupByRef = (
    //
    ref: string | undefined,
    options?: ConfigurationLookupOptions,
) => {
    return useQuery<Configuration | undefined>(
        keys.detailByRef(ref),
        async () => {
            if (ref != null) {
                return cyberpass.api.configurations.lookupByRef(ref, options);
            }
        },
        {
            enabled: ref != null,
        },
    );
};

export const useCyberpassConfigurationSearch = (query: Query<ConfigurationSearchFilters> | undefined, options?: { enabled?: boolean }) => {
    const queryClient = useQueryClient();
    return useQuery<QueryResponse<Configuration, ConfigurationSearchFilters> | undefined>(
        keys.list(query),
        async () => {
            if (query != null) {
                const response = await cyberpass.api.configurations.search(query);
                response?.items?.map((configuration) => queryClient.setQueryData(keys.detail(configuration.id), configuration));
                return response;
            }
        },
        {
            enabled: query != null && (options?.enabled ?? true),
        },
    );
};

export const useCyberpassConfigurationUpdate = (id: string | undefined) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async (props: Partial<Pick<ConfigurationProps, 'published' | 'definition'>>) => {
            if (id != null) {
                return cyberpass.api.configurations.update(id, props);
            }
        },
        onSuccess: (data) => {
            if (id != null && data != null) {
                queryClient.invalidateQueries(keys.detail(id));

                queryClient.setQueriesData(keys.lists(), (queryData: any) => {
                    if (queryData != null) {
                        return {
                            ...queryData,
                            items: queryData.items.map((item: Configuration) => (item.id === data.id ? data : item)),
                        };
                    }
                });
                queryClient.invalidateQueries({ queryKey: keys.lists(), refetchType: 'none' });
            }
        },
        onError: defaultOnError,
    });
};
