import type React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Row } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { ShadowCard } from '@/components/ShadowCard';
import { PercentageBar, RiskLevel, getPercentageColor } from '../PercentageBar';
import { AssuranceScoreCardLegend } from './AssuranceScoreCardLegend';
import { AssuranceScoreCardDrawer } from './AssuranceScoreCardDrawer';
import './styles.css';

const getRiskLevelByPercentage = (percentage: number) => {
    if (percentage <= 25) {
        return RiskLevel.MINIMALISTIC;
    } else if (percentage <= 50) {
        return RiskLevel.ADEQUATE;
    } else if (percentage <= 75) {
        return RiskLevel.ROBUST;
    } else {
        return RiskLevel.EXCEPTIONAL;
    }
};

type AssuranceScoreCardProps = {
    percentage: number;
};

export const AssuranceScoreCard: React.FC<AssuranceScoreCardProps> = ({ percentage }) => {
    const { t } = useTranslation();
    const [openDrawer, setOpenDrawer] = useState<boolean>(false);

    const safePercentage = Math.round(Math.max(Math.min(percentage, 100), 0));

    return (
        <>
            <ShadowCard
                containerClassName="assurance-score-card-row"
                title={t('PAGES.RESULT.ASSURANCE_SCORE')}
                extra={
                    <Button
                        className="assurance-score-card-drawer-info-button"
                        type="link"
                        onClick={() => {
                            setOpenDrawer(true);
                        }}
                    >
                        <InfoCircleOutlined />
                    </Button>
                }
            >
                <Row className="assurance-score-card-percentage-row" style={{ color: getPercentageColor(safePercentage) }}>
                    <span className="assurance-score-card-percentage">{safePercentage}%</span>
                    <span className="assurance-score-card-status">{t(`SHARED.RISK.LABEL.${getRiskLevelByPercentage(safePercentage)}`)}</span>
                </Row>
                <PercentageBar percentage={safePercentage} />
                <AssuranceScoreCardLegend />
            </ShadowCard>
            <AssuranceScoreCardDrawer
                open={openDrawer}
                onClose={() => {
                    setOpenDrawer(false);
                }}
            />
        </>
    );
};
