import type React from 'react';
import { useTranslation } from 'react-i18next';
import { type Certification, CertificationResult } from '@/utils/cyberpass';
import { ShadowCard } from '@/components/ShadowCard';
import { PassEvaluationResult } from './PassEvaluationResult';
import { FailEvaluationResult } from './FailEvaluationResult';
import './styles.css';

type EvaluationResultCardProps = {
    certification: Certification | undefined;
    hideRecertifySection?: boolean;
};

export const EvaluationResultCard: React.FC<EvaluationResultCardProps> = ({ certification, hideRecertifySection }) => {
    const { t } = useTranslation();

    return (
        <ShadowCard title={t('COMPONENTS.EVALUATION_RESULT_CARD.TITLE')}>
            {certification != null && (
                <div className="evaluation-result-card-title-container">
                    {certification?.result === CertificationResult.PASS ? (
                        <PassEvaluationResult certification={certification} hideRecertifySection={hideRecertifySection} />
                    ) : (
                        <FailEvaluationResult certification={certification} />
                    )}
                </div>
            )}
        </ShadowCard>
    );
};
