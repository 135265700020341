import {
    type Certification,
    CertificationResult,
    CertificationStatus,
    type Form,
    type SchemeDefinition,
    SchemeFormFillMode,
    FormType,
    FormStatus,
} from '@/utils/cyberpass';

// TODO filip consider moving this util as a dedicated hook (state and/or memo) and returning status + isXXX

/**
 * This is a cosmetic status
 * It is not stored and there isn't an object that holds it
 * It is calculated from a combination of other statuses
 */
export enum EvaluationFlowStatus {
    ONGOING = 'ONGOING',
    SUBMITTED = 'SUBMITTED',
    REVIEWING = 'REVIEWING',
    INCONCLUSIVE = 'INCONCLUSIVE',
    CERTIFIED = 'CERTIFIED',
    FAILED = 'FAILED',
}

type EvaluationFlowProps = {
    certification: Certification | undefined;
    forms: Form[] | undefined;
    schemeDefinition: SchemeDefinition | undefined;
};

export const getEvaluationFlowStatus = ({ certification, forms, schemeDefinition }: EvaluationFlowProps): EvaluationFlowStatus | null => {
    if (certification == null || forms == null || schemeDefinition == null) {
        return null;
    }

    if ([CertificationStatus.FINALIZED, CertificationStatus.ARCHIVED].includes(certification.status)) {
        switch (certification.result) {
            case CertificationResult.PASS:
                return EvaluationFlowStatus.CERTIFIED;
            case CertificationResult.FAIL:
                return EvaluationFlowStatus.FAILED;
            default:
                return null;
        }
    }

    // Scheme ids of reviewer forms for this level (except waiting and payment forms because they do not affect the flow status)
    const reviewerSchemeFormIds = schemeDefinition.forms
        .filter(
            (schemeFormDefinition) =>
                schemeFormDefinition.filledBy === SchemeFormFillMode.REVIEWER &&
                ![FormType.WAITING, FormType.PAYMENT, FormType.SUBMIT].includes(schemeFormDefinition.type) &&
                schemeFormDefinition.levels.includes(certification.level),
        )
        .map((schemeFormDefinition) => schemeFormDefinition.id);

    if (reviewerSchemeFormIds.length > 0) {
        if (
            forms
                .filter((form) => reviewerSchemeFormIds.includes(form.schemeFormId))
                .some((form) => [FormStatus.IN_PROGRESS, FormStatus.READY].includes(form.status))
        ) {
            return EvaluationFlowStatus.REVIEWING;
        }

        if (forms.filter((form) => reviewerSchemeFormIds.includes(form.schemeFormId)).every((form) => form.status === FormStatus.LOCKED)) {
            return EvaluationFlowStatus.INCONCLUSIVE;
        }

        const providerSchemeFormIds = schemeDefinition.forms
            .filter(
                (schemeFormDefinition) =>
                    schemeFormDefinition.filledBy === SchemeFormFillMode.PROVIDER &&
                    ![FormType.WAITING, FormType.PAYMENT, FormType.SUBMIT].includes(schemeFormDefinition.type) &&
                    schemeFormDefinition.levels.includes(certification.level),
            )
            .map((schemeFormDefinition) => schemeFormDefinition.id);

        if (forms.filter((form) => providerSchemeFormIds.includes(form.schemeFormId)).every((form) => form.status === FormStatus.LOCKED)) {
            return EvaluationFlowStatus.SUBMITTED;
        }
    }

    return EvaluationFlowStatus.ONGOING;
};
