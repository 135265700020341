import type React from 'react';
import { Layout } from 'antd';
import './styles.css';

type PageContentLayoutProps = {
    children: React.ReactNode;
    title?: string;
    actions?: React.ReactNode;
    footer?: React.ReactNode;
    header?: React.ReactNode;
    pageContentLayoutContainerClassName?: string;
    pageContentLayoutBodyContainerClassName?: string;
};

export const PageContentLayout: React.FC<PageContentLayoutProps> = ({
    children,
    title,
    actions,
    footer,
    header,
    pageContentLayoutContainerClassName,
    pageContentLayoutBodyContainerClassName,
}) => {
    return (
        <Layout.Content className={`page-content-layout-page-content ${pageContentLayoutContainerClassName ?? ''}`}>
            {(title != null || actions != null) && (
                <Layout.Header className="page-content-layout-page-title">
                    <h1 className="page-content-layout-top-bar-title">{title}</h1>
                    <div className="page-content-layout-top-bar-action-button">{actions}</div>
                </Layout.Header>
            )}
            {header}
            <div className={`page-content-layout-page-body ${pageContentLayoutBodyContainerClassName ?? ''}`}>{children}</div>
            {footer != null && <Layout.Footer className="page-content-layout-bottom-bar">{footer}</Layout.Footer>}
        </Layout.Content>
    );
};
