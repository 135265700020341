import { FileUploader, type FileUploaderType, type FileUploaderAcceptedType, type FileUploaderFile } from '@/components/FileUploader';

type InputOneFileUploaderProps = {
    id?: string;
    value?: string; // file-id
    onChange?: (value: string) => void;
    disabled?: boolean;
    type?: FileUploaderType;
    acceptedTypes?: FileUploaderAcceptedType[];
};

export const InputOneFileUploader: React.FC<InputOneFileUploaderProps> = ({ id, value, onChange, type, acceptedTypes, disabled }) => {
    const onHandleChange = (files: FileUploaderFile[]) => {
        onChange?.(files?.[0]?.id ?? null);
    };

    return (
        <FileUploader //
            id={id}
            disabled={disabled}
            maxCount={1}
            multiple={false}
            type={type}
            acceptedTypes={acceptedTypes}
            initialFileIds={value != null ? [value] : []}
            onChange={onHandleChange}
        />
    );
};
