import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row, Typography } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { useCyberpassProductLookupById } from '@/query';
import { type Certification } from '@/utils/cyberpass';
import { CertificationReference } from '@/components/CertificationReference';
import { ProductDrawer } from '@/components/ProductDrawer';
import { WorkspaceOwnerInfo } from '../WorkspaceOwnerInfo';
import { ProductImage } from '@/components/ProductImage';
import './styles.css';

type FormProductInfoSectionProps = {
    certification: Certification | undefined;
    extended?: boolean;
    hideProductDrawerArrow?: boolean;
    hideEvaluatorInfo?: boolean;
};
export const FormProductInfoSection: React.FC<FormProductInfoSectionProps> = ({
    certification,
    extended = false,
    hideProductDrawerArrow = false,
    hideEvaluatorInfo = false,
}) => {
    const { t } = useTranslation();

    const [showProductDrawer, setShowProductDrawer] = React.useState(false);
    const { data: productData } = useCyberpassProductLookupById(certification?.productId);
    const { data: product } = useCyberpassProductLookupById(certification?.productId);

    if (certification == null) {
        return null;
    }

    return (
        <>
            <Row gutter={10} className="form-product-info-section-container" wrap={false}>
                <Col>
                    <ProductImage product={product} isThumbnail className="form-product-info-section-product-image" />
                </Col>
                <Col>
                    <div className="form-product-info-section-product-name-container">
                        <Typography.Text className="form-product-info-section-product-name" ellipsis={!extended ? { tooltip: product?.name } : false}>
                            {product?.name}
                        </Typography.Text>

                        {!hideProductDrawerArrow && (
                            <Button
                                icon={<ArrowRightOutlined />}
                                className="no-style-button form-product-info-section-product-button"
                                onClick={() => {
                                    setShowProductDrawer(true);
                                }}
                            />
                        )}
                    </div>

                    <Row>
                        <Col>
                            <Typography.Text>{certification != null && <CertificationReference certification={certification} />}</Typography.Text>
                        </Col>

                        {certification?.reviewerWorkspaceId != null && !hideEvaluatorInfo && (
                            <Col className="form-product-info-section-lab">
                                <span style={{ color: 'var(--gray-5)', padding: '0 10px' }}>|</span>

                                <span>
                                    {t('COMPONENTS.CERTIFICATION_PRODUCT_INFO_CARD.EVALUATED_BY')}{' '}
                                    <WorkspaceOwnerInfo id={certification?.reviewerWorkspaceId} showFullName={false} showCompanyName />
                                </span>
                            </Col>
                        )}
                    </Row>
                </Col>
            </Row>
            {productData != null && (
                <ProductDrawer
                    product={productData}
                    open={showProductDrawer}
                    onClose={() => {
                        setShowProductDrawer(false);
                    }}
                />
            )}
        </>
    );
};
