import { Tag } from 'antd';
import { useActiveConfigurationDefinition } from '@/utils/configurations';
import { ConfigurationType } from '@/utils/cyberpass';
import './styles.css';

type CertificationLevelTagProps = {
    levelId?: string | null;
    schemeId?: string | undefined;
    style?: React.CSSProperties;
};

export const CertificationLevelTag: React.FC<CertificationLevelTagProps> = ({ levelId = null, schemeId, style = {} }) => {
    const { data: schemeDefinition } = useActiveConfigurationDefinition(schemeId, ConfigurationType.SCHEME);

    const level = schemeDefinition?.levels.find((level) => level.id === levelId);

    if (levelId == null || schemeDefinition == null || level == null) {
        return <></>;
    }

    const color = { normal: level.color, light: level.backgroundColor };

    return (
        <Tag
            style={{
                color: color.normal,
                backgroundColor: color.light,
                borderColor: color.normal,
                ...style,
            }}
            className="tag-level"
        >
            {level.name}
        </Tag>
    );
};
