import { useState, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { IntercomProvider } from 'react-use-intercom';
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ConfigProvider } from 'antd';
import { type Locale } from 'antd/lib/locale-provider';
import i18next from '@/utils/i18next';
import config from '@/utils/config';
import { ErrorPage } from './pages/ErrorPage';
import { AuthProvider } from './contexts/AuthContext';
import { WebSocketProvider } from './contexts/WebSocketContext';

// not happy how language-handling is split between here and i18next
import deDE from 'antd/es/locale/de_DE';
import enUS from 'antd/es/locale/en_US';
import frFR from 'antd/es/locale/fr_FR';
import koKR from 'antd/es/locale/ko_KR';
import zhCN from 'antd/es/locale/zh_CN';

import { RootRouter } from './routers/RootRouter';

export const App: React.FC = () => {
    const [antLocale, setAntLocale] = useState<Locale | undefined>(enUS);

    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                retry: false,
                // refetchOnWindowFocus: false,
                // suspense: true,
                // cacheTime: 0,
            },
        },
    });

    useEffect(() => {
        i18next.on('languageChanged', (language) => {
            setAntLocale(
                {
                    de: deDE,
                    en: enUS,
                    fr: frFR,
                    ko: koKR,
                    zh: zhCN,
                }[language ?? 'en'],
            );
        });
        return () => {
            i18next.off('languageChanged');
        };
    }, []);

    return (
        <ErrorBoundary FallbackComponent={ErrorPage}>
            <QueryClientProvider client={queryClient}>
                <ConfigProvider locale={antLocale}>
                    <IntercomProvider appId={config.INTERCOM_APP_ID} autoBoot>
                        <AuthProvider>
                            <WebSocketProvider>
                                <RootRouter />
                            </WebSocketProvider>
                        </AuthProvider>
                    </IntercomProvider>
                </ConfigProvider>
            </QueryClientProvider>
        </ErrorBoundary>
    );
};
