import { useAuth } from '@/hooks/useAuth';
import { type Amount } from '@/utils/cyberpass';
import i18next from '@/utils/i18next';

type SmartAmountProps = {
    amount: Amount | undefined;
    showCurrency?: boolean;
    showDigits?: boolean;
    defaultText?: string;
};

export const SmartAmount: React.FC<SmartAmountProps> = ({ amount, showCurrency = true, showDigits = true, defaultText = '-' }) => {
    const { user } = useAuth();

    const format = new Intl.NumberFormat(user?.preferences?.appLanguage ?? i18next.language, {
        style: 'currency',
        currency: amount?.currency ?? 'EUR',
        minimumFractionDigits: showDigits ? 2 : 0,
    });

    return <>{amount?.value != null ? format.format(amount.value / 100) : defaultText}</>;
};
