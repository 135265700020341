import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import cyberpass, { type Query, type QueryResponse, type Vulnerability, type VulnerabilityProps } from '@/utils/cyberpass';
import { vulnerabilityKeys as keys } from './index.keys';
import { defaultOnError } from './helpers';

// vulnerabilities

export const useCyberpassVulnerabilityCreate = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async (props: Omit<VulnerabilityProps, 'status' | 'reporterUserId'>) => {
            return cyberpass.api.vulnerabilities.create(props);
        },
        onSuccess: () => {
            queryClient.invalidateQueries(keys.lists());
        },
        onError: defaultOnError,
    });
};

export const useCyberpassVulnerabilityLookupById = (id: string | undefined, options?: { initialData?: Vulnerability }) => {
    return useQuery<Vulnerability | undefined>(
        keys.detail(id),
        async () => {
            if (id != null) {
                return cyberpass.api.vulnerabilities.lookupById(id);
            }
        },
        {
            enabled: id != null,
            initialData: options?.initialData,
        },
    );
};

export const useCyberpassVulnerabilityUpdate = (id: string | undefined) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async (props: Pick<VulnerabilityProps, 'status'>) => {
            if (id != null) {
                return cyberpass.api.vulnerabilities.update(id, props);
            }
        },
        onSuccess: (data: Vulnerability | undefined) => {
            if (id != null && data != null) {
                queryClient.invalidateQueries(keys.detail(id));

                queryClient.setQueriesData(keys.lists(), (queryData: any) => {
                    if (queryData != null) {
                        return {
                            ...queryData,
                            items: queryData.items.map((item: Vulnerability) => (item.id === data.id ? data : item)),
                        };
                    }
                });
                queryClient.invalidateQueries({ queryKey: keys.lists(), refetchType: 'none' });
            }
        },
        onError: defaultOnError,
    });
};

export const useCyberpassVulnerabilitySearch = (query: Query | undefined, options?: { enabled?: boolean }) => {
    const queryClient = useQueryClient();
    return useQuery<QueryResponse<Vulnerability> | undefined>(
        keys.list(query),
        async () => {
            if (query != null) {
                const response = await cyberpass.api.vulnerabilities.search(query);
                response?.items?.map((vulnerability) => queryClient.setQueryData(keys.detail(vulnerability.id), vulnerability));
                return response;
            }
        },
        {
            enabled: query != null && (options?.enabled ?? true),
        },
    );
};
