import type React from 'react';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Row, Col } from 'antd';
import { useCyberpassCertificationLookupBySerialNumber, useCyberpassCertificationLookupById, useCyberpassProductLookupById } from '@/query';
import { CertificationStatus } from '@/utils/cyberpass';
import { useAuth } from '@/hooks/useAuth';
import { Loader } from '@/components/Loader';
import { PageContentLayout } from '@/components/PageContentLayout';
import { PagePublicDisplayLayout } from '@/components/PagePublicDisplayLayout';
import { EvaluationResultCard } from './EvaluationResultCard';
import { AssuranceScoreCard } from './AssuranceScoreCard';
import { ResultTabsCard } from './ResultTabsCard';
import { EmptyResultPage } from './EmptyResultPage';
import { ReportVulnerabilityCard } from './ReportVulnerabilityCard';
import { InfoActionBar } from '@/components/InfoActionBar';
import { FormProductInfoSection } from '@/components/FormProductInfoSection';
import { useTranslation } from 'react-i18next';
import './styles.css';

export const ResultPage: React.FC = () => {
    const params = useParams();
    const { user, currentWorkspace } = useAuth();
    const { t } = useTranslation();
    const certificationId = params.certificationId;

    const { data: certification, isLoading } = useCyberpassCertificationLookupById(certificationId);
    const { data: productData } = useCyberpassProductLookupById(certification?.productId);

    const isProductOwner = currentWorkspace != null && productData?.workspaceId === currentWorkspace?.id;

    const isCertificationFinished = certification != null && [CertificationStatus.FINALIZED, CertificationStatus.ARCHIVED].includes(certification.status);

    return (
        <PageContentLayout
            title={t('PAGES.EVALUATION.RESULT')}
            header={<InfoActionBar className="result-page-header" title={<FormProductInfoSection certification={certification} />} />}
        >
            {isLoading ? (
                <Loader />
            ) : isCertificationFinished ? (
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <EvaluationResultCard certification={certification} hideRecertifySection={!isProductOwner} />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <AssuranceScoreCard percentage={(certification?.evaluationResultProps?.scoring?.overallAssuranceScore ?? 0) * 100} />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <ReportVulnerabilityCard productId={certification?.productId} />
                    </Col>
                    {/* <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <ResultChartsCard certification={certification} />
                    </Col> */}
                    {user != null && (
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <ResultTabsCard certification={certification} isProductOwner={isProductOwner} />
                        </Col>
                    )}
                </Row>
            ) : (
                <Row gutter={[16, 16]} justify="center" align="middle" className="empty-result-page-row">
                    <EmptyResultPage />
                </Row>
            )}
        </PageContentLayout>
    );
};

export const ResultPublicPage: React.FC = () => {
    const params = useParams();
    const navigate = useNavigate();
    const { currentWorkspace } = useAuth();
    const { data: certification, isError, isLoading: loading } = useCyberpassCertificationLookupBySerialNumber(params.serialNumber);
    const { data: productData } = useCyberpassProductLookupById(certification?.productId);

    const isProductOwner = currentWorkspace != null && productData?.workspaceId === currentWorkspace?.id;

    useEffect(() => {
        if (isError) {
            navigate('/error', { replace: true });
        }
    }, [isError]);

    return (
        <PagePublicDisplayLayout
            header={<InfoActionBar className="result-page-public-header" title={<FormProductInfoSection certification={certification} />} />}
        >
            {loading ? (
                <Loader />
            ) : (
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <EvaluationResultCard certification={certification} hideRecertifySection />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <AssuranceScoreCard percentage={(certification?.evaluationResultProps?.scoring?.overallAssuranceScore ?? 0) * 100} />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <ReportVulnerabilityCard productId={certification?.productId} />
                    </Col>
                    {/* <Col xs={24} sm={24} md={ 12} lg={ 12} xl={ 12}>
                        <ResultChartsCard certification={certification} />
                    </Col> */}
                    {isProductOwner && (
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <ResultTabsCard certification={certification} />
                        </Col>
                    )}

                    {/* <Col xs={24}>
                        <ReportingVulnerabilityCard certification={certification} />
                    </Col> */}
                </Row>
            )}
        </PagePublicDisplayLayout>
    );
};
