import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import cyberpass, {
    type Query,
    type QueryResponse,
    type WorkspaceProps,
    type Workspace,
    type WorkspaceSearchFilters,
    WorkspaceStatus,
} from '@/utils/cyberpass';
import { workspaceKeys as keys } from './index.keys';
import { defaultOnError } from './helpers';

export const useCyberpassWorkspaceCreate = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async (props: WorkspaceProps) => {
            return cyberpass.api.workspaces.create(props);
        },
        onSuccess: () => {
            queryClient.invalidateQueries(keys.lists());
        },
        onError: defaultOnError,
    });
};

export const useCyberpassWorkspaceLookupById = (
    //
    id: string | undefined,
    options?: { initialData?: Workspace },
) => {
    return useQuery<Workspace | undefined>(
        keys.detail(id),
        async () => {
            if (id != null) {
                return cyberpass.api.workspaces.lookupById(id);
            }
        },
        {
            enabled: id != null,
            initialData: options?.initialData,
        },
    );
};

export const useCyberpassWorkspaceUpdate = (id: string | undefined) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async (props: Partial<WorkspaceProps>) => {
            if (id != null) {
                return cyberpass.api.workspaces.update(id, props);
            }
        },
        onSuccess: (data) => {
            if (id != null && data != null) {
                queryClient.invalidateQueries(keys.detail(id));

                queryClient.setQueriesData(keys.lists(), (queryData: any) => {
                    if (queryData != null) {
                        return {
                            ...queryData,
                            items: queryData.items
                                .map((item: Workspace) => (item.id === data.id ? data : item))
                                .filter((item: Workspace) => item.status === WorkspaceStatus.ACTIVE),
                        };
                    }
                });
                queryClient.invalidateQueries({ queryKey: keys.lists(), refetchType: 'none' });
            }
        },
        onError: defaultOnError,
    });
};

export const useCyberpassWorkspaceSearch = (query: Query<WorkspaceSearchFilters> | undefined, options?: { enabled?: boolean }) => {
    const queryClient = useQueryClient();
    return useQuery<QueryResponse<Workspace, WorkspaceSearchFilters> | undefined>(
        keys.list(query),
        async () => {
            if (query != null) {
                const response = await cyberpass.api.workspaces.search(query);
                response?.items?.map((workspace) => queryClient.setQueryData(keys.detail(workspace.id), workspace));
                return response;
            }
        },
        {
            enabled: query != null && (options?.enabled ?? true),
        },
    );
};

export const useCyberpassWorkspaceAddMembers = (id: string | undefined) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async (props: { emails: string[] }) => {
            if (id != null) {
                return cyberpass.api.workspaces.addMembers(id, props);
            }
        },
        onSuccess: (data) => {
            if (id != null && data != null) {
                queryClient.invalidateQueries(keys.detail(id));
                queryClient.setQueriesData(keys.lists(), (queryData: any) => {
                    if (queryData != null) {
                        return {
                            ...queryData,
                            items: queryData.items.map((item: Workspace) => (item.id === data.id ? data : item)),
                        };
                    }
                });
                queryClient.invalidateQueries({ queryKey: keys.lists(), refetchType: 'none' });
            }
        },
        onError: defaultOnError,
    });
};

export const useCyberpassWorkspaceArchiveMember = (id: string | undefined) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async (userId: string) => {
            if (id != null) {
                return cyberpass.api.workspaces.archiveMember(id, userId);
            }
        },
        onSuccess: (data) => {
            if (id != null && data != null) {
                queryClient.invalidateQueries(keys.detail(id));
                queryClient.setQueriesData(keys.lists(), (queryData: any) => {
                    if (queryData != null) {
                        return {
                            ...queryData,
                            items: queryData.items.map((item: Workspace) => (item.id === data.id ? data : item)),
                        };
                    }
                });
                queryClient.invalidateQueries({ queryKey: keys.lists(), refetchType: 'none' });
            }
        },
        onError: defaultOnError,
    });
};
