import { MailOutlined, KeyOutlined } from '@ant-design/icons';
import i18next from '@/utils/i18next';
import { type VerifyPinRequest } from '@/utils/cyberpass';
import { type FormItemsBuilderItems } from '@/components/FormItemsBuilder';

export const buildLoginFormItems = (login: VerifyPinRequest, showVerificationPin = true, userHasAcceptedTerms = false): FormItemsBuilderItems => {
    return [
        {
            label: i18next.t('COMPONENTS.LOGIN_FORM.EMAIL'),
            type: 'text-email',
            property: 'email',
            value: login?.email,
            options: {
                required: true,
                editable: !showVerificationPin,
                showLabel: false,
                prefix: <MailOutlined />,
                placeholder: i18next.t('COMPONENTS.LOGIN_FORM.EMAIL'),
            },
        },
        {
            label: i18next.t('COMPONENTS.LOGIN_FORM.VERIFICATION_PIN'),
            type: 'text',
            property: 'verificationPin',
            value: login?.verificationPin,
            options: {
                required: false,
                showLabel: false,
                prefix: <KeyOutlined />,
                placeholder: i18next.t('COMPONENTS.LOGIN_FORM.VERIFICATION_PIN'),
                hidden: !showVerificationPin,
            },
        },
        {
            label: i18next.t('COMPONENTS.LOGIN_FORM.ACCEPT_TERMS'),
            type: 'checkbox-disclaimer',
            property: 'termsAccepted',
            value: login?.termsAccepted,
            options: {
                required: showVerificationPin && !userHasAcceptedTerms,
                showLabel: false,
                hidden: !showVerificationPin || userHasAcceptedTerms,
            },
        },
    ];
};
