import { useTranslation } from 'react-i18next';
import { RiskAssessmentRow } from './RiskAssessmentRow';

type ResultCapabilityTabProps = {
    capabilities: Array<{ labelI18nKey: string; score: number }>;
};

export const ResultCapabilityTab: React.FC<ResultCapabilityTabProps> = ({ capabilities }) => {
    const { t } = useTranslation();
    return (
        <>
            {capabilities.map((item) => (
                <RiskAssessmentRow
                    key={item.labelI18nKey}
                    label={t(`SHARED.CAPABILITIES.LABEL.${item.labelI18nKey}`)}
                    tooltip={t(`SHARED.CAPABILITIES.DESCRIPTION.${item.labelI18nKey}`)}
                    percentage={item.score}
                />
            ))}
        </>
    );
};
