import type React from 'react';
import { useTranslation } from 'react-i18next';
import { UserOutlined } from '@ant-design/icons';
import { type User } from '@/utils/cyberpass';
import { notifyWithIcon } from '@/utils/notifications';
import { useCyberpassUserUpdateData } from '@/query';
import { FormItemsBuilder } from '@/components/FormItemsBuilder';
import { SimpleForm, type SimpleFormPropsFactory } from '@/components/SimpleForm';
import { buildAssessorDetailsFormItems } from './form';

type AssessorDetailsFormProps = SimpleFormPropsFactory<'user', User>;

export const AssessorDetailsForm: React.FC<AssessorDetailsFormProps> = ({ user, editable, onSubmit, onSuccess, onCancel, onError }) => {
    const { t } = useTranslation();
    const updateUserData = useCyberpassUserUpdateData(user?.id);

    const items = buildAssessorDetailsFormItems(user);

    const handleSubmit = (values: User, done: () => void) => {
        onSubmit?.(values);
        updateUserData.mutate(
            {
                avatarFileId: values.avatarFileId,
                fullName: values.fullName ?? undefined,
                phoneNumber: values.phoneNumber ?? undefined,
                address: values.address ?? undefined,
            },
            {
                onSuccess: (data) => {
                    notifyWithIcon('success', t('COMPONENTS.USER_DETAILS_FORM.NOTIFY.SUBMIT_SUCCEEDED'));
                    if (data != null) {
                        onSuccess?.(data);
                    }
                },
                onError: () => {
                    notifyWithIcon('error', t('COMPONENTS.USER_DETAILS_FORM.NOTIFY.SUBMIT_FAILED'));
                    onError?.();
                },
                onSettled: () => {
                    done();
                },
            },
        );
    };

    return (
        <SimpleForm<User> //
            initialValues={user}
            editable={editable}
            submitLabel={t('COMPONENTS.USER_DETAILS_FORM.BUTTON_SUBMIT')}
            onSubmit={handleSubmit}
            onCancel={onCancel}
        >
            <FormItemsBuilder //
                items={items}
                defaultImage={<UserOutlined />}
                editable={editable}
            />
        </SimpleForm>
    );
};
