// TODO if any of the below definition are good and confirmed to be used in new implementation, let's move them elsewhere + de-deprecate them

/** @deprecated BAD CODE; DO NOT REUSE AS-IS */
export const SupportedMimeType = {
    ALL_TYPES: ['*'],
    ALL_IMAGE_TYPES: ['image/*'],
    COMMON_IMAGE_TYPES: ['image/png', 'image/jpg', 'image/jpeg'],
    COMMON_DOCUMENT_TYPES: [
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ],
    XLSX: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
};
// TODO the above enum is a little misleading in that it mixes patterns and exact matches; using those from the consumer side is dangerous and may defeat expectations

/** @deprecated BAD CODE; DO NOT REUSE AS-IS */
export enum FileType {
    PDF = 'PDF',
    IMAGE = 'IMAGE',
    DOCUMENT = 'DOCUMENT',
    VIDEO = 'VIDEO',
    UNKNOWN = 'UNKNOWN',
}

/** @deprecated BAD CODE; DO NOT REUSE AS-IS */
export enum FileValidationError {
    MAX_FILE_COUNT = 'MAX_FILE_COUNT',
    INVALID_FILE_TYPE = 'INVALID_FILE_TYPE',
}
