import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import cyberpass, {
    type Query,
    type Product,
    type ProductReportProps,
    type QueryResponse,
    type ProductCreateProps,
    type ProductUpdateProps,
    ProductStatus,
    type ProductSearchFilters,
} from '@/utils/cyberpass';
import { productKeys as keys } from './index.keys';
import { defaultOnError } from './helpers';

export const useCyberpassProductCreate = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async (props: ProductCreateProps) => {
            return cyberpass.api.products.create(props);
        },
        onSuccess: () => {
            queryClient.invalidateQueries(keys.lists());
        },
        onError: defaultOnError,
    });
};

export const useCyberpassProductLookupById = (
    //
    id: string | undefined,
    options?: { initialData?: Product },
) => {
    return useQuery<Product | undefined>(
        keys.detail(id),
        async () => {
            if (id != null) {
                return cyberpass.api.products.lookupById(id);
            }
        },
        {
            enabled: id != null,
            initialData: options?.initialData,
        },
    );
};

export const useCyberpassProductUpdate = (id: string | undefined) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async (props: ProductUpdateProps) => {
            if (id != null) {
                return cyberpass.api.products.update(id, props);
            }
        },
        onSuccess: (data) => {
            if (id != null && data != null) {
                queryClient.invalidateQueries(keys.detail(id));
                if (data.status === ProductStatus.ARCHIVED) {
                    queryClient.invalidateQueries({ queryKey: keys.lists() });
                } else {
                    queryClient.setQueriesData(keys.lists(), (queryData: any) => {
                        if (queryData != null) {
                            return {
                                ...queryData,
                                items: queryData.items.map((item: Product) => (item.id === data.id ? data : item)),
                            };
                        }
                    });
                    queryClient.invalidateQueries({ queryKey: keys.lists(), refetchType: 'none' });
                }
            }
        },
        onError: defaultOnError,
    });
};

export const useCyberpassProductSearch = (query: Query<ProductSearchFilters> | undefined, options?: { enabled?: boolean }) => {
    const queryClient = useQueryClient();
    return useQuery<QueryResponse<Product> | undefined>(
        keys.list(query),
        async () => {
            if (query != null) {
                const response = await cyberpass.api.products.search(query);
                response?.items?.map((product) => queryClient.setQueryData(keys.detail(product.id), product));
                return response;
            }
        },
        {
            enabled: query != null && (options?.enabled ?? true),
        },
    );
};

export const useCyberpassProductVulnerabilityReport = (id: string) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async (props: ProductReportProps) => {
            return cyberpass.api.products.reportVulnerability(id, props);
        },
        onSuccess: () => {
            queryClient.invalidateQueries(keys.detail(id));
        },
        onError: defaultOnError,
    });
};
