import type React from 'react';
import { InputDropdown, type InputDropdownChoice } from '@/components/FormItemsBuilder/InputDropdown';

type InputDropdownSingleProps = {
    id?: string;
    value?: string;
    defaultValue?: string;
    onChange?: (value: string) => void;
    disabled?: boolean;
    size?: 'small' | 'middle' | 'large';
    choices: InputDropdownChoice[];
    style?: React.CSSProperties;
};

export const InputDropdownSingle: React.FC<InputDropdownSingleProps> = ({ id, value, defaultValue, onChange, size = 'middle', choices, style, disabled }) => {
    const handleChange = (value: string[]) => {
        onChange?.(value[0]);
    };

    return (
        <InputDropdown
            id={id}
            disabled={disabled}
            value={value != null ? [value] : undefined}
            defaultValue={defaultValue != null ? [defaultValue] : undefined}
            onChange={handleChange}
            size={size}
            choices={choices}
            multiple={false}
            style={style}
        />
    );
};
