export enum QuotaType {
    MAX_WORKSPACES_PER_USER = 'MAX_WORKSPACES_PER_USER',
    MAX_USERS_PER_WORKSPACE = 'MAX_USERS_PER_WORKSPACE',
    MAX_CERTIFICATIONS_PER_WORKSPACE = 'MAX_CERTIFICATIONS_PER_WORKSPACE',
}

export type QuotaProps = {
    type: QuotaType;
    max: number;
    current: number;
    reached: boolean;
};
