import { Row, Col, Typography } from 'antd';
import { useCyberpassProductLookupById, useCyberpassUserGetDetails, useCyberpassWorkspaceLookupById } from '@/query';
import { ProductImage } from '@/components/ProductImage';

type ProductInfoProps = {
    id: string | undefined;
    showImage?: boolean;
    showName?: boolean;
    showVendor?: boolean;
    showDescription?: boolean;
    extraDescription?: React.ReactNode;
    defaultText?: string;
    className?: string;
    style?: React.CSSProperties;
};

export const ProductInfo: React.FC<ProductInfoProps> = ({
    id,
    showImage = false,
    showName = true,
    showVendor = false,
    showDescription = false,
    extraDescription = <></>,
    defaultText = '-',
    className,
    style,
}) => {
    const { data: product } = useCyberpassProductLookupById(id);
    const { data: productWorkspace } = useCyberpassWorkspaceLookupById(product?.workspaceId);
    const { data: owner } = useCyberpassUserGetDetails(productWorkspace?.creatorUserId);

    return (
        <Row gutter={10} wrap={false} className={className} style={style}>
            {showImage && (
                <Col style={{ display: 'flex', justifyContent: 'center' }}>
                    <ProductImage product={product} isThumbnail />
                </Col>
            )}
            <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', overflow: 'hidden' }}>
                {showName && (
                    <Typography.Text ellipsis={{ tooltip: product?.name }} style={{ fontWeight: 'bold' }}>
                        {product?.name ?? defaultText}
                    </Typography.Text>
                )}
                {showVendor && (
                    <Typography.Text style={{ width: '350px', color: 'var(--gray-7)' }} ellipsis={{ tooltip: owner?.company?.name }}>
                        {owner?.company?.name ?? defaultText}
                    </Typography.Text>
                )}
                {showDescription && (
                    <Typography.Text style={{ fontSize: 12, color: 'var(--gray-7)' }} ellipsis={{ tooltip: product?.description }}>
                        {product?.description ?? defaultText}
                    </Typography.Text>
                )}
                {extraDescription}
            </Col>
        </Row>
    );
};
