import type React from 'react';
import { useTranslation } from 'react-i18next';
import { CustomDrawer } from '@/components/CustomDrawer';
import { VulnerabilityReportForm } from '@/components/VulnerabilityReportForm';
import { type Vulnerability } from '@red-alert-labs/cyberpass-client';

type VulnerabilityDrawerProps = {
    open: boolean | undefined;
    onClose: () => void;
    productId: string;
    vulnerability?: Vulnerability;
};

export const VulnerabilityDrawer: React.FC<VulnerabilityDrawerProps> = ({ open, onClose, productId, vulnerability }) => {
    const { t } = useTranslation();
    return (
        <CustomDrawer
            title={
                vulnerability != null
                    ? vulnerability.responses['vulnerability-identifier']
                    : t('COMPONENTS.PRODUCT_DRAWER.TAB_VULNERABILITY.BUTTON_REPORT_VULNERABILITY')
            }
            onClose={onClose}
            open={open}
        >
            <VulnerabilityReportForm
                vulnerability={vulnerability}
                productId={productId}
                editable={vulnerability == null}
                onSuccess={() => {
                    onClose();
                }}
            />
        </CustomDrawer>
    );
};
