import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import config from './config';
import locales from '../locales';
import { log } from './log';
import deFlag from '../assets/images/flag-de.svg';
import enFlag from '../assets/images/flag-en.svg';
import frFlag from '../assets/images/flag-fr.svg';
import krFlag from '../assets/images/flag-kr.svg';
import zhFlag from '../assets/images/flag-zh.svg';

// //////////////////////////////////////////////////////////////////////////

export type LanguageOptionCountries = keyof (typeof locales)['resources'];

export type LanguageOptions = Record<LanguageOptionCountries, { flag: string; name: string }>;

// NOTE this list should be updated as new language files are created!
export const languageOptions: LanguageOptions = {
    de: { flag: deFlag, name: 'Deutsch' },
    en: { flag: enFlag, name: 'English' },
    fr: { flag: frFlag, name: 'Français' },
    ko: { flag: krFlag, name: '한국어' },
    zh: { flag: zhFlag, name: '中文' },
};

// //////////////////////////////////////////////////////////////////////////

export const displayLanguage = (language: LanguageOptionCountries) => {
    const languageOption = languageOptions?.[language];

    if (languageOption == null) {
        return '';
    }

    const flagContent = languageOption.flag != null ? <img src={languageOption.flag} /> : null;

    return (
        <>
            {flagContent} {languageOption.name ?? ''}
        </>
    );
};

// //////////////////////////////////////////////////////////////////////////

i18next
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: locales.resources,
        supportedLngs: Object.keys(locales.resources),
        fallbackLng: config.ENV === 'production' ? Object.keys(locales.resources) : undefined,
        detection: {
            order: ['localStorage', 'navigator'],
            caches: ['localStorage'],
        },
        debug: config.ENV !== 'production', // this show if key is missing value in lang file (en.ts)
        parseMissingKeyHandler: (key: string) => key,
        missingKeyHandler: (lngs: readonly string[], ns: string, key: string, fallbackValue: string, updateMissing: boolean, options: any) => {
            log.debug(`missing key [${lngs.join(',')}:${ns}:${key}]`);
        },
    });

export default i18next;
