import { Tag } from 'antd';
import { type CertificationStatus, type CertificationResult } from '@/utils/cyberpass';
import { type EvaluationFlowStatus } from '@/utils/evaluation';
import { colorClassNames } from './colorClassNames';

type EvaluationFlowStatusTagProps = {
    status: EvaluationFlowStatus | undefined;
    size?: 'small' | 'middle' | 'large';
    defaultText?: string;

    // TODO LATER these are temporary props; need to remove, once evaluation flow is ready
    certificationStatus: CertificationStatus | undefined;
    certificationResult: CertificationResult | undefined;
};

export const EvaluationFlowStatusTag: React.FC<EvaluationFlowStatusTagProps> = ({ status, certificationStatus, certificationResult, size, defaultText }) => {
    return status == null ? (
        defaultText
    ) : (
        // <Tooltip
        //     title={
        //         <>
        //             <CertificationStatusTag status={certificationStatus} defaultText="" />
        //             <CertificationResultTag result={certificationResult} defaultText="" />
        //         </>
        //     }
        // >
        <Tag className={colorClassNames[status]}>{status}</Tag>
        // </Tooltip>
    );
};
