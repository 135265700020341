import { useTranslation } from 'react-i18next';
import { Checkbox, Col, Row } from 'antd';
import './styles.css';

type InputDisclaimerProps = {
    // checked?: boolean;
    value?: boolean;
    onChange?: (checked: boolean) => void;
    disabled?: boolean;
    content?: string;
    showLabel?: boolean;
};

export const InputDisclaimer: React.FC<InputDisclaimerProps> = ({ value, onChange, disabled = false, content, showLabel = true }) => {
    const { t } = useTranslation();
    return (
        <Row className="input-disclaimer-container">
            <Col span={1}>
                <Checkbox
                    className="input-disclaimer-checkbox"
                    checked={value}
                    onChange={({ target }) => {
                        onChange?.(target.checked);
                    }}
                    disabled={disabled}
                />
            </Col>
            <Col span={23}>
                {showLabel && <span className="input-disclaimer-instruction">{t('COMPONENTS.DISCLAIMER_.INSTRUCTION')}:</span>}
                <div className="input-disclaimer-content" dangerouslySetInnerHTML={{ __html: content ?? '' }} />
            </Col>
        </Row>
    );
};
