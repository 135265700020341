import i18next from '@/utils/i18next';
import { WorkspaceType, type Workspace } from '@/utils/cyberpass';
import { type FormItemsBuilderItems } from '@/components/FormItemsBuilder';
import { FileUploaderType } from '@/components/FileUploader';
import { WorkspaceInfo } from '@/components/WorkspaceInfo';
import { SupportedMimeType } from '@/types/file';

export const buildWorkspaceFormItems = (workspace: Workspace | undefined): FormItemsBuilderItems => {
    const isPersonal = workspace?.type === WorkspaceType.PERSONAL;
    return [
        {
            label: i18next.t('COMPONENTS.WORKSPACE_FORM.AVATAR'),
            type: 'file-image',
            property: 'avatarFileId',
            value: workspace?.avatarFileId,
            options: {
                type: FileUploaderType.IMAGE,
                acceptedTypes: [SupportedMimeType.ALL_IMAGE_TYPES],
            },
        },
        {
            label: i18next.t('COMPONENTS.WORKSPACE_FORM.NAME'),
            type: 'text',
            property: 'name',
            value: workspace?.name,
            options: {
                required: true,
                disabled: isPersonal,
                prefix: <WorkspaceInfo id={workspace?.id} hideAvatar hideName hideRole />,
            },
        },
        {
            label: i18next.t('COMPONENTS.WORKSPACE_FORM.DESCRIPTION'),
            type: 'text-area',
            property: 'description',
            value: workspace?.description,
        },
    ];
};
