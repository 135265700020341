import type React from 'react';
import { useState } from 'react';
// import CurrencyInput from 'react-currency-input-field';

import { InputNumber, Select } from 'antd';
import { useAuth } from '@/hooks/useAuth';
import { type Amount } from '@/utils/cyberpass';
import i18next from '@/utils/i18next';

// //////////////////////////////////////////////////////////////////////////

const getDecimalSeparator = (locale: string) => {
    // https://stackoverflow.com/questions/33159354/how-do-i-find-the-decimal-separator-for-current-locale-in-javascript
    const numberWithDecimalSeparator = 1.1;
    return (
        Intl.NumberFormat(locale)
            ?.formatToParts(numberWithDecimalSeparator)
            ?.find((part) => part.type === 'decimal')?.value ?? '.'
    );
};

// //////////////////////////////////////////////////////////////////////////

type InputAmountProps = {
    value?: Amount | null;
    onChange?: (value: Amount | null) => void;
    size?: 'small' | 'middle' | 'large';
    placeholder?: string;
    disabled?: boolean;
    min?: number;
    max?: number;
};

export const InputAmount: React.FC<InputAmountProps> = ({ value, onChange, size = 'middle', placeholder, disabled = false, min, max }) => {
    const { user } = useAuth();
    const [amountValue, setAmountValue] = useState<number | undefined>(value?.value); // in cents
    const [amountCurrency, setAmountCurrency] = useState<string | undefined>(value?.currency ?? 'EUR');

    const userLanguage = user?.preferences?.appLanguage ?? i18next.language;
    const separator = getDecimalSeparator(userLanguage); // '.'
    const format = new Intl.NumberFormat(userLanguage, {
        // style: 'currency',
        // currency: amountCurrency!,
        // minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    const handleChangeValue = (value: number | null) => {
        setAmountValue(value ?? undefined);
        onChange?.({ value: value ?? undefined, currency: amountCurrency });
    };

    const handleChangeCurrency = (currency: string | null) => {
        setAmountCurrency(currency ?? undefined);
        onChange?.({ value: amountValue, currency: currency ?? undefined });
    };

    return (
        <InputNumber
            disabled={disabled}
            min={min ?? 0}
            max={max ?? undefined}
            width="100%"
            controls={false}
            formatter={(value) => (amountValue != null ? format.format((amountValue ?? 0) / 100) : '')}
            parser={(value) => {
                const [integer, decimal] = (value ?? '').split(separator);
                const _decimal = decimal ?? '';
                const newInteger = Number((integer ?? '').replace(/[\s,.]*/g, ''));
                const newDecimal = _decimal.length === 1 ? Number(_decimal) * 10 : _decimal.length === 2 ? Number(_decimal) : Number(_decimal.slice(0, 2));
                const newValue = newInteger * 100 + newDecimal;
                return newValue;
            }}
            value={amountValue}
            onChange={handleChangeValue}
            addonBefore={
                <Select
                    defaultValue={amountCurrency}
                    style={{ width: 60 }}
                    onChange={handleChangeCurrency}
                    options={[
                        { label: '$', value: 'USD' },
                        { label: '€', value: 'EUR' },
                        { label: '£', value: 'GBP' },
                        { label: '¥', value: 'CNY' },
                    ]}
                />
            }
        />
    );
};
