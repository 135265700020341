import type React from 'react';
import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'antd';
import { useAuth } from '@/hooks/useAuth';
import { LoginForm } from '@/components/LoginForm';
import CyberpassLogoVertical from '@/assets/images/cyberpass-logo-vertical.svg';
import { InputLanguage } from '@/components/FormItemsBuilder/InputLanguage';
import { PublicFooter } from '@/components/PublicFooter';
import i18next from '@/utils/i18next';
import config from '@/utils/config';
import { CertificateLookupSection } from './CertificateLookupSection';
import './styles.css';

export const LoginPage: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();
    const { authenticated } = useAuth();

    const handleClickLogo = () => {
        window.location.href = config.VITE_HOME_URL;
    };

    const handleLoginSuccess = () => {
        navigate(location.state?.from ?? '/dashboard', { replace: true });
    };

    const handleLanguageChange = (value: string) => {
        i18next.changeLanguage(value);
    };

    useEffect(() => {
        if (authenticated) {
            handleLoginSuccess();
        }
    }, [authenticated]);

    return (
        <Row className="login-page-container">
            <Col className="login-page-left-container" xl={8} lg={8} md={12} xs={24}>
                <div className="login-page-language-selector-container">
                    <InputLanguage value={i18next.resolvedLanguage} onChange={handleLanguageChange} />
                </div>

                <img onClick={handleClickLogo} src={CyberpassLogoVertical} alt="" className="login-page-logo-image" style={{ objectFit: 'contain' }} />
                <div className="login-page-mission">{t('PAGES.LOGIN.WELCOME_MESSAGE')}</div>
                <div className="login-page-title">{t('PAGES.LOGIN.TITLE')}</div>
                <div className="login-page-form-container">
                    <LoginForm //
                        login={undefined}
                        editable={true}
                        onSuccess={handleLoginSuccess}
                    />
                </div>
                <PublicFooter />
            </Col>
            <CertificateLookupSection />
        </Row>
    );
};
