import { Button, Cascader } from 'antd';
import { type CascaderRef, type DefaultOptionType } from 'antd/lib/cascader';
import { useRef } from 'react';

type InputCascaderChoice = any;

type InputCascaderProps = {
    id?: string;
    value?: string[];
    defaultValue?: string[];
    // onChange?: (value: string[]) => void;
    onChange?: any;
    disabled?: boolean;
    size?: 'small' | 'middle' | 'large';
    choices: InputCascaderChoice[];
    multiple?: boolean;
    showSearch?: boolean;
    displayRender?: (labels: string[], selectedOptions: DefaultOptionType[] | undefined) => React.ReactNode;
    style?: React.CSSProperties;
};

const concatAndFlatten = (arr: string[]) => {
    return arr.reduce((flatArr: string[], item) => {
        if (Array.isArray(item)) {
            flatArr.push(item.join(':'));
        } else {
            flatArr.push(item);
        }
        return flatArr;
    }, []);
};

const reverseConcatAndFlatten = (arr: string[], multiple: boolean) => {
    if (!multiple) {
        return arr;
    }
    return arr?.map((item: string[] | string) => {
        if (Array.isArray(item)) {
            return item;
        }

        if (item.includes(':')) {
            return item.split(':');
        } else {
            return [item];
        }
    });
};

const filter = (inputValue: string, path: any[]) => path.some((option) => option.resultLabel?.toLowerCase().includes(inputValue.toLowerCase()));

export const InputCascader: React.FC<InputCascaderProps> = ({
    id,
    value,
    defaultValue,
    onChange,
    size = 'middle',
    choices,
    multiple = false,
    showSearch = false,
    style,
    disabled,
    displayRender,
}) => {
    const ref = useRef<CascaderRef>(null);
    const handleChange = (value: any) => {
        onChange?.(concatAndFlatten(value));
        ref.current?.blur();
    };

    return (
        <Cascader
            id={id}
            ref={ref}
            style={style}
            size={size}
            value={value != null ? reverseConcatAndFlatten(value, multiple) : undefined}
            defaultValue={defaultValue}
            onChange={handleChange}
            options={choices}
            multiple={multiple}
            showSearch={showSearch ? { filter } : false}
            displayRender={displayRender}
            disabled={disabled}
        />
    );
};
