import type React from 'react';
import { Spin } from 'antd';
import './styles.css';

type LoaderProps = {
    hidden?: boolean;
};

export const Loader: React.FC<LoaderProps> = ({ hidden = false }) => {
    return hidden ? null : (
        <div className="loader-container">
            <Spin size="large" />
        </div>
    );
};
