import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Upload, type UploadFile, Tooltip } from 'antd';
import uniqBy from 'lodash.uniqby';
import { CloudUploadOutlined } from '@ant-design/icons';
import { type RcFile } from 'antd/es/upload';
import { validateFileBeforeUpload } from '@/utils/file';
import { notifyWithIcon } from '@/utils/notifications';
import { type FilePropsProps } from '@/utils/cyberpass';
import { useCyberpassFileSearch, useCyberpassFileUpload } from '@/query';
import { FileValidationError, SupportedMimeType } from '@/types/file';
import './styles.css';

// TODO LATER need to rename acceptedType to acceptedMimeType to make things crystal clear
// TODO LATER this is more of a "render-mode" (?) ; we need to rename to avoid confusion with acceptedType */
export enum FileUploaderType {
    IMAGE = 'image',
    FILE = 'file',
}

export type FileUploaderAcceptedType = (typeof SupportedMimeType)[keyof typeof SupportedMimeType];

export type FileUploaderFile = {
    id: string;
    name: string;
    url: string;
};

type FileUploaderProps = {
    id?: string;
    initialFileIds?: string[];
    maxCount?: number;
    multiple?: boolean;
    /** accept an array of values of all keys of SupportedMimeType */
    acceptedTypes?: FileUploaderAcceptedType[];
    type?: FileUploaderType;
    onChange?: (files: FileUploaderFile[]) => void;
    disabled?: boolean;
    fileProps?: FilePropsProps;
};

/**
 * @description
 * This component is a wrapper for antd's Upload component.
 * It handles the upload of files to cyberpass and the removal of files.
 * It also handles the display of the files.
 */
export const FileUploader: React.FC<FileUploaderProps> = ({
    id,
    initialFileIds,
    maxCount = 8,
    multiple,
    acceptedTypes,
    type = FileUploaderType.FILE,
    onChange,
    disabled = false,
    fileProps,
}) => {
    const { t } = useTranslation();

    const isQueryEnabled = initialFileIds != null && initialFileIds.length > 0;
    const { data: filesData, isLoading } = useCyberpassFileSearch({ filters: { ids: initialFileIds ?? [], asOwner: 'false' } }, { enabled: isQueryEnabled });
    const uploadFile = useCyberpassFileUpload();
    const [fileList, setFileList] = useState<UploadFile[] | null>(null);

    useEffect(() => {
        if (fileList == null || fileList.length === 0) {
            if (!isLoading || !isQueryEnabled) {
                setFileList(
                    filesData?.items
                        ?.filter((file) => file != null)
                        .map((file) => ({
                            uid: file.id,
                            name: file.props?.originalFileName ?? '',
                            url: file.url,
                            status: 'done',
                            response: file,
                        })) ?? [],
                );
            }
        }
    }, [filesData]);

    const _acceptedTypes =
        acceptedTypes != null
            ? acceptedTypes.map((type) => type.join(',')).join(',')
            : type === FileUploaderType.IMAGE
              ? SupportedMimeType.ALL_IMAGE_TYPES.join(',')
              : SupportedMimeType.ALL_TYPES.join(',');

    // If the onChange listener is provided, call it
    const handleFileChange = (updatedFileList: UploadFile[] | null) => {
        const convertedFiles = updatedFileList?.map((file) => ({ id: file.response.id, name: file.response.name, url: file.response.url })) ?? [];
        onChange?.(convertedFiles);
    };

    /**
     * @description
     * This function is called after the user adds a file, but before it is uploaded
     * It checks if the file count exceeds the given limit (maxCount)
     */
    const _beforeUpload = (file: RcFile, pendingFileList: RcFile[]) => {
        const validationResult = validateFileBeforeUpload({
            file,
            fileList: pendingFileList,
            totalFilesCount: fileList?.length,
            maxFileCount: maxCount,
            supportedMimeTypes: _acceptedTypes.split(','),
        });
        if (validationResult.isValid) {
            return true;
        }
        switch (validationResult.error) {
            case FileValidationError.MAX_FILE_COUNT:
                notifyWithIcon(
                    'error',
                    t('COMPONENTS.FILE_UPLOADER.ERROR_REACH_LIMIT_UPLOAD', {
                        NUMBER_FILE_WANT_TO_UPLOAD: pendingFileList.length,
                        NUMBER_FILE_CAN_UPLOAD: maxCount - (fileList?.length ?? 0),
                    }),
                );
                break;
            case FileValidationError.INVALID_FILE_TYPE:
                notifyWithIcon('error', t('COMPONENTS.FILE_UPLOADER.ERROR_FORBIDDEN_TYPE'));
                break;
        }
        return Upload.LIST_IGNORE;
    };

    /**
     * @description
     * This function is called on every change to the file list (uploading, uploaded, removed, etc.)
     * It job is to upsert a file to the fileList state
     */
    const _onChange = ({ file, fileList: uploadFileList }: { file: UploadFile; fileList: UploadFile[] }) => {
        // If file is removed, let "onRemove" handle it
        if (file.status === 'removed') {
            return;
        }

        setFileList(uploadFileList);

        // If all files are finalized, we call the onChange callback with the successfully uploaded files
        if (uploadFileList.every((file) => file.status != null && ['done', 'error'].includes(file.status))) {
            const filteredFileList = uniqBy(
                uploadFileList.filter((file) => file.status === 'done' && file.response != null),
                'response.id',
            )?.map((file) => ({ ...file, url: file.response?.url ?? '' }));

            setFileList(filteredFileList);
            handleFileChange(filteredFileList);
        }

        if (file.status === 'done') {
            notifyWithIcon('success', t('COMPONENTS.FILE_UPLOADER.SUCCESSFUL_UPLOADED'));
        }
        if (file.status === 'error') {
            notifyWithIcon('error', t('COMPONENTS.FILE_UPLOADER.UPLOAD_FAILED'));
        }
    };

    const _onRemove = (file: UploadFile) => {
        Modal.confirm({
            title: t('COMPONENTS.FILE_UPLOADER.REMOVE_FILE_TITLE'),
            content: t('COMPONENTS.FILE_UPLOADER.REMOVE_FILE_CONFIRMATION'),
            okText: t('SHARED.REMOVE'),
            onOk() {
                const filteredFileList = fileList?.filter((previousFile) => previousFile.uid !== file.uid);
                setFileList(filteredFileList ?? null);
                if (filteredFileList?.every((file) => file.status === 'done') ?? false) {
                    handleFileChange(filteredFileList ?? null);
                }
            },
            onCancel() {
                // nop
            },
        });
    };

    const showUploadArea = fileList != null && fileList.length < maxCount;
    // If we are using the <Upload> component, we don't need to use special CSS to hide the upload area and can just keep the default class
    const uploadClassName = showUploadArea ? 'upload-area' : 'upload-area-hide';

    return (
        <>
            <Upload.Dragger
                id={id}
                disabled={disabled}
                className={`upload-area-container ${uploadClassName}`}
                listType={type === FileUploaderType.IMAGE ? 'picture-card' : 'picture'}
                maxCount={maxCount}
                fileList={fileList ?? []}
                multiple={multiple ?? maxCount > 1}
                beforeUpload={_beforeUpload}
                customRequest={(options) => {
                    uploadFile.mutate({ fileProps, options });
                }}
                onChange={_onChange}
                onRemove={_onRemove}
                accept={_acceptedTypes}
                style={{ width: '100px', height: '100px' }}
            >
                <Tooltip title={t('COMPONENTS.FILE_UPLOADER.DRAGGER_TEXT')} open={disabled ? false : undefined}>
                    <CloudUploadOutlined style={{ color: disabled ? 'var(--gray-5)' : 'var(--blue-5)', fontSize: 48 }} />
                </Tooltip>
            </Upload.Dragger>
        </>
    );
};
