import { useCyberpassUserGetDetails } from '@/query';
import { FileImage } from '@/components/FileImage';
import './styles.css';

type UserCompanyInfoProps = {
    id: string | undefined;
    showCompanyLogo?: boolean;
    showCompanyName?: boolean;
    defaultEmpty?: string;
};

/** shows minimal information about a company; ideal to be embedded into a table row */
export const UserCompanyInfo: React.FC<UserCompanyInfoProps> = ({ id, showCompanyLogo = true, showCompanyName = true, defaultEmpty = '-' }) => {
    const { data: user } = useCyberpassUserGetDetails(id);

    const logoFileId = user?.company?.logoFileId ?? user?.avatarFileId;
    const name = user?.company?.name ?? user?.fullName ?? defaultEmpty;
    return (
        <div className="company-info-container">
            {showCompanyLogo && <FileImage className="company-info-logo" fileId={logoFileId ?? undefined} />}
            {showCompanyName && <span className="company-info-name">{name}</span>}
        </div>
    );
};
