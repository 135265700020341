import React from 'react';
import { ConfigurationType, type Certification } from '@/utils/cyberpass';
import { useActiveConfigurationDefinition } from '@/utils/configurations';
import { CertificationLevelTag } from '@/components/CertificationLevelTag';
import './styles.css';

type CertificationReferenceProps = {
    certification: Certification | undefined;
};

export const CertificationReference: React.FC<CertificationReferenceProps> = React.memo(
    ({ certification }) => {
        const { data: schemeDefinition } = useActiveConfigurationDefinition(certification?.schemeId, ConfigurationType.SCHEME);

        if (certification == null || schemeDefinition == null) {
            return <></>;
        }

        return (
            <span className="certification-reference-container">
                <span>{schemeDefinition?.name ?? '-'}</span>
                {certification?.level != null && (
                    <>
                        <span style={{ color: 'var(--gray-5)', padding: '0 10px' }}>|</span>
                        <CertificationLevelTag levelId={certification.level} schemeId={certification.schemeId} />
                    </>
                )}
            </span>
        );
    },
    (a, b) => a.certification?.id === b.certification?.id,
);
