import { useQuery, useQueryClient } from '@tanstack/react-query';
import cyberpass, { type Query, type Certification, type QueryResponse, type CertificationSearchFilters } from '@/utils/cyberpass';
import { certificationKeys as keys } from './index.keys';

export const useCyberpassCertificationSearch = (query: Query<CertificationSearchFilters> | undefined, options?: { enabled?: boolean }) => {
    const queryClient = useQueryClient();
    return useQuery<QueryResponse<Certification> | undefined>(
        keys.list(query),
        async () => {
            if (query != null) {
                const response = await cyberpass.api.certifications.search(query);
                response?.items?.map((certification) => queryClient.setQueryData(keys.detail(certification.id), certification));
                return response;
            }
        },
        {
            enabled: query != null && (options?.enabled ?? true),
        },
    );
};

export const useCyberpassCertificationLookupById = (id: string | undefined) => {
    return useQuery<Certification | undefined>(
        keys.detail(id),
        async () => {
            if (id != null) {
                return cyberpass.api.certifications.lookupById(id);
            }
        },
        {
            enabled: id != null,
        },
    );
};

export const useCyberpassCertificationLookupBySerialNumber = (serialNumber: string | undefined) => {
    return useQuery<Certification | undefined>(
        keys.bySerialNumber(serialNumber),
        async () => {
            if (serialNumber != null) {
                return cyberpass.api.certifications.lookupBySerialNumber(serialNumber);
            }
        },
        {
            enabled: serialNumber != null,
        },
    );
};

export const useCyberpassCertificationLookupByProductId = (id: string | undefined) => {
    return useQuery<Certification[] | undefined>(
        keys.listByProduct(id),
        async () => {
            if (id != null) {
                return cyberpass.api.certifications.lookupByProductId(id);
            }
        },
        {
            enabled: id != null,
        },
    );
};
