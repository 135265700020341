import { useEffect, useState } from 'react';
import { Form, type FormInstance } from 'antd';

export const useIsFormSubmittable = (form: FormInstance) => {
    const [submittable, setSubmittable] = useState<boolean>(false);

    const values = Form.useWatch([], form);

    useEffect(() => {
        if (!form.isFieldsTouched()) {
            setSubmittable(false);
            return;
        }
        const validateForm = async () => {
            try {
                await form.validateFields({ validateOnly: true });
                setSubmittable(true);
            } catch (error) {
                setSubmittable(false);
            }
        };

        validateForm();
    }, [form, values]);

    return { submittable, setSubmittable };
};
