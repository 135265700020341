import type React from 'react';
import { InputDropdown, type InputDropdownChoice } from '../InputDropdown';

type InputDropdownMultipleProps = {
    id?: string;
    value?: string[];
    defaultValue?: string[];
    onChange?: (value: string[]) => void;
    disabled?: boolean;
    size?: 'small' | 'middle' | 'large';
    choices: InputDropdownChoice[];
    style?: React.CSSProperties;
};

export const InputDropdownMultiple: React.FC<InputDropdownMultipleProps> = ({
    id,
    value,
    defaultValue,
    onChange,
    size = 'middle',
    choices,
    style,
    disabled,
}) => {
    return (
        <InputDropdown
            id={id}
            value={value}
            defaultValue={defaultValue}
            onChange={onChange}
            size={size}
            choices={choices}
            multiple={true}
            style={style}
            disabled={disabled}
        />
    );
};
