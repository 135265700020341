import { useCyberpassWorkspaceLookupById } from '@/query/workspaces';
import { UserBasicInfo } from '@/components/UserBasicInfo';

type WorkspaceOwnerInfoProps = {
    id: string;
    showFullName?: boolean;
    showCompanyName?: boolean;
    defaultEmpty?: string;
    className?: string;
};

export const WorkspaceOwnerInfo: React.FC<WorkspaceOwnerInfoProps> = ({ id, showFullName = true, showCompanyName = false, defaultEmpty = '-', className }) => {
    const { data: workspace } = useCyberpassWorkspaceLookupById(id);

    if (workspace == null) {
        return null;
    }
    return (
        <UserBasicInfo
            id={workspace.creatorUserId}
            showFullName={showFullName}
            showCompanyName={showCompanyName}
            defaultEmpty={defaultEmpty}
            className={className}
        />
    );
};
