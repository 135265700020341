import { useTranslation } from 'react-i18next';
import { Col, Row, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { PercentageBar, getPercentageColor, getRiskLevelByPercentage } from '../PercentageBar';

type RiskAssessmentRowProps = {
    label: string;
    tooltip: string;
    percentage: number;
};

export const RiskAssessmentRow = ({ label, tooltip, percentage }: RiskAssessmentRowProps) => {
    const { t } = useTranslation();
    const safePercentage = Math.round(Math.max(Math.min(percentage, 100), 0));
    return (
        <Row className="risk-assessment-row">
            <Col span={5}>{label}</Col>
            <Col span={1}>
                <Tooltip title={tooltip} placement="bottom">
                    <InfoCircleOutlined />
                </Tooltip>
            </Col>
            <Col span={6} style={{ color: getPercentageColor(safePercentage) }}>
                <span style={{ fontWeight: 700, marginRight: 12 }}>{safePercentage}%</span> {t(`SHARED.RISK.LABEL.${getRiskLevelByPercentage(safePercentage)}`)}
            </Col>
            <Col span={12} className="risk-assessment-row-percentage-bar">
                <PercentageBar percentage={safePercentage} showNumbers={false} thinBars={true} roundedEdges={true} style={{ width: '100%' }} />
            </Col>
        </Row>
    );
};
