import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { useActiveConfigurationDefinition } from '@/utils/configurations';
import { useCyberpassUserGetDetails, useCyberpassFileLookup, useCyberpassWorkspaceLookupById } from '@/query';
import { SmartDate } from '@/components/SmartDate';
import { CertificationLevelTag } from '@/components/CertificationLevelTag';
import { ReadMore } from '@/components/ReadMore';
import { AboutLabDrawer } from '@/components/AboutLabDrawer';
import { UserBasicInfo } from '@/components/UserBasicInfo';
import { DownloadCertificateDrawer } from '@/components/DownloadCertificateDrawer';
import { copyToClipboard } from '@/utils/copyToClipboard';
import { ConfigurationType, type Certification } from '@/utils/cyberpass';
import { FileImage } from '@/components/FileImage';
import { RecertifySection } from '../RecertifySection';
import { CertificationInfoCard } from '@/components/CertificationInfoCard';
import { CertificationReference } from '@/components/CertificationReference';

type PassEvaluationResultProps = {
    certification: Certification | undefined;
    hideRecertifySection?: boolean;
};

export const PassEvaluationResult: React.FC<PassEvaluationResultProps> = ({ certification, hideRecertifySection = false }) => {
    const { t } = useTranslation();
    const [showAboutLabDrawer, setShowAboutLabDrawer] = React.useState(false);
    const { data: reviewerWorkspace } = useCyberpassWorkspaceLookupById(certification?.reviewerWorkspaceId);
    const { data: reviewerWorkspaceCreator } = useCyberpassUserGetDetails(reviewerWorkspace?.creatorUserId);
    const avatarFile = useCyberpassFileLookup(reviewerWorkspaceCreator?.company?.logoFileId);
    const { data: file } = useCyberpassFileLookup(certification?.labelFileId);

    const { data: schemeDefinition } = useActiveConfigurationDefinition(certification?.schemeId, ConfigurationType.SCHEME);

    const level = schemeDefinition?.levels.find((level) => level.id === certification?.level);

    return schemeDefinition == null ? null : (
        <>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <div className="evaluation-result-card-pass-description-container">{t('COMPONENTS.EVALUATION_RESULT_CARD.DESCRIPTION')}</div>
                </Col>
                <Col className="evaluation-result-card-pass-certification-badge-col">
                    <FileImage fileId={file?.id} />
                    <DownloadCertificateDrawer certification={certification} lab={reviewerWorkspaceCreator} />
                </Col>
                <Col style={{ flex: 1 }}>
                    <div className="evaluation-result-card-pass-info-container">
                        {certification?.serialNumber != null && (
                            <div>
                                <span className="evaluation-result-card-pass-description-label">
                                    {t('COMPONENTS.EVALUATION_RESULT_CARD.CERTIFICATE_SERIAL_NUMBER')}:{' '}
                                </span>

                                <button
                                    className="no-style-button evaluation-result-card-pass-serial-number"
                                    onClick={() => {
                                        copyToClipboard(certification?.serialNumber ?? '');
                                    }}
                                >
                                    {certification?.serialNumber}
                                    <CopyOutlined className="evaluation-result-card-pass-serial-number-icon" />
                                </button>
                            </div>
                        )}
                        {certification?.expiresAt != null && (
                            <div>
                                <span className="evaluation-result-card-pass-description-label">{t('COMPONENTS.EVALUATION_RESULT_CARD.EXPIRES_AT')}: </span>
                                <SmartDate
                                    date={certification?.expiresAt}
                                    tooltip={t('COMPONENTS.EVALUATION_RESULT_CARD.EXPIRATION_DATE')}
                                    showIcon
                                    showExpirationColors
                                    className="evaluation-result-card-pass-expires-text"
                                />
                            </div>
                        )}
                        {certification != null && (
                            <div>
                                <span className="evaluation-result-card-pass-description-label">{t('COMPONENTS.EVALUATION_RESULT_CARD.TARGET_SCHEME')}: </span>
                                <CertificationReference certification={certification} />
                            </div>
                        )}
                    </div>
                    {certification?.level != null && (
                        <span className="evaluation-result-card-pass-certification-level-container">
                            {/* <CertificationLevelTag levelId={certification?.level} schemeId={certification.schemeId} style={{ marginRight: '5px' }} /> */}
                            <Trans i18nKey={level != null ? level.description : ''} />
                        </span>
                    )}
                    {reviewerWorkspaceCreator != null && (
                        <>
                            <div className="evaluation-result-card-about-container">{t('COMPONENTS.EVALUATION_RESULT_CARD.ABOUT_LAB')}</div>
                            <div className="evaluation-result-card-about-row">
                                <div className="evaluation-result-card-about-image-col">
                                    {avatarFile.data != null && <img className="evaluation-result-card-image" src={avatarFile.data.url} />}
                                </div>

                                <div className="evaluation-result-card-about-lab-container">
                                    <ReadMore
                                        html={reviewerWorkspaceCreator?.company?.about}
                                        buttonClassName="evaluation-result-card-about-lab-container-read-more-button"
                                    />
                                </div>
                            </div>
                            <div
                                className="evaluation-result-card-more-about-card-container"
                                onClick={() => {
                                    setShowAboutLabDrawer(true);
                                }}
                            >
                                {t('COMPONENTS.EVALUATION_RESULT_CARD.MORE_ABOUT_LAB')}{' '}
                                {reviewerWorkspaceCreator != null ? (
                                    <UserBasicInfo id={reviewerWorkspaceCreator?.id} showFullName={false} showCompanyName />
                                ) : (
                                    ''
                                )}
                            </div>
                        </>
                    )}
                    {!hideRecertifySection && <RecertifySection certification={certification} style={{ marginTop: 16 }} />}
                </Col>
            </Row>
            <AboutLabDrawer
                labUserId={reviewerWorkspaceCreator?.id}
                onClose={() => {
                    setShowAboutLabDrawer(false);
                }}
                open={showAboutLabDrawer}
            />
        </>
    );
};
