import { type Query } from '@/utils/cyberpass';

const keys = {
    all: ['copilot'],
    meta: () => [...keys.all, 'meta'],
    lists: () => [...keys.all, 'list'],
    list: (query: Query | undefined) => [...keys.lists(), query],
    details: () => [...keys.all, 'detail'],
    detail: (id: string | undefined) => [...keys.details(), id],
    detailByKey: (key: string | undefined, formId?: string | undefined) => [
        ...keys.details(),
        {
            key,
            formId,
        },
    ],
};

export const copilotKeys = keys;
