import { FileUploader, type FileUploaderType, type FileUploaderAcceptedType, type FileUploaderFile } from '@/components/FileUploader';

type InputMultipleFileUploaderProps = {
    id?: string;
    value?: string[]; // file-ids
    onChange?: (values: string[]) => void;
    disabled?: boolean;
    type?: FileUploaderType;
    acceptedTypes?: FileUploaderAcceptedType[];
};

export const InputMultipleFileUploader: React.FC<InputMultipleFileUploaderProps> = ({ id, value, onChange, type, acceptedTypes, disabled }) => {
    const onHandleChange = (files: FileUploaderFile[]) => {
        onChange?.((files ?? []).map((file) => file.id));
    };

    return (
        <FileUploader //
            id={id}
            disabled={disabled}
            maxCount={10}
            multiple={true}
            type={type}
            acceptedTypes={acceptedTypes}
            initialFileIds={value}
            onChange={onHandleChange}
            fileProps={{}}
        />
    );
};
