import React from 'react';
import { FormItemLayout } from './FormItemLayout';
import { type FormItemsBuilderItem } from './types';
import { meta as kit } from './meta';
import { FormFieldDiffDrawer } from '../FormFieldDiffDrawer';
import './styles.css';

// //////////////////////////////////////////////////////////////////////////

export * from './types';

// //////////////////////////////////////////////////////////////////////////
const isFormItemsBuilderItem = (item: any): item is FormItemsBuilderItem => {
    return (item as FormItemsBuilderItem).type !== undefined;
};

type FormItemsBuilderProps = {
    items: Array<FormItemsBuilderItem | FormItemsBuilderItem[] | React.ReactNode>;
    editable?: boolean;
    defaultText?: string | JSX.Element;
    defaultImage?: JSX.Element;
    appLanguage?: string;
    showHelp?: boolean;
};

/**
 * @returns a list of <Form.Item> generated from items
 */
export const FormItemsBuilder: React.FC<FormItemsBuilderProps> = ({ items, editable = true, defaultText = '-', showHelp = true }) => {
    const meta = kit({ defaultText });

    const render = editable //
        ? (item: FormItemsBuilderItem, index: number) => {
              return meta[item.type].edit(item);
          }
        : (item: FormItemsBuilderItem, index: number) => <div className="form-items-builder-non-editable-value">{meta[item.type].view(item)}</div>;

    return (
        <>
            {items.map((item, index) => {
                if (Array.isArray(item)) {
                    return FormItemsBuilder({ items: item, editable, defaultText });
                }
                if (React.isValidElement(item)) {
                    return <React.Fragment key={`form-item-layout-${index}}`}>{item}</React.Fragment>;
                }
                if (isFormItemsBuilderItem(item)) {
                    return (
                        <FormItemLayout
                            key={`form-item-layout-${index}`}
                            description={item.description}
                            label={item.label}
                            value={render(item, index)}
                            containerClassName={`${item.diff != null ? 'form-items-builder-diff-field' : ''}`}
                            className={`simple-form-value-${item.type}`}
                            editable={editable}
                            showLabel={
                                (item.options?.showLabel ?? true) && item.type !== 'divider' && item.type !== 'checkbox-disclaimer' && item.type !== 'title'
                            }
                            hidden={item?.options?.hidden ?? false}
                            required={item?.options?.required ?? false}
                            labelSuffix={item.diff != null ? <FormFieldDiffDrawer item={item} /> : null}
                            help={showHelp ? (item?.options?.help ?? undefined) : undefined}
                            showBackground={item?.options?.showBackground ?? true}
                        />
                    );
                }
                return null;
            })}
        </>
    );
};
