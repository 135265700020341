import { Outlet } from 'react-router-dom';
import { Layout } from 'antd';

import './styles.css';

export const PagePublicLayout = () => {
    return (
        <Layout className="page-public-layout">
            <Outlet />
        </Layout>
    );
};
