import { useTranslation } from 'react-i18next';
import { Table, Button } from 'antd';
import { useCyberpassConfigurationSearch, useCyberpassFormSearch } from '@/query';
import { EvaluationFlowStatus, getEvaluationFlowStatus } from '@/utils/evaluation';
import { type Certification, type ConfigurationDefinition, ConfigurationType } from '@/utils/cyberpass';
import { NoEntriesInfo } from '@/components/NoEntriesInfo';
import { SmartDate } from '@/components/SmartDate';
import { FormProductInfoSection } from '@/components/FormProductInfoSection';
import { CertificationLabInfo } from './CertificationLabInfo';
import { EvaluationFlowStatusInfo } from './EvaluationFlowStatusInfo';
import { Actions } from './Actions';
import './styles.css';

type ProductCertificationsTableProps = {
    certifications: Certification[] | undefined;
    onClickStartEvaluation?: () => void;
    showAsSubTable?: boolean;
    loading?: boolean;
    isOwner?: boolean;
    showTitle?: boolean;
    showAlternateEmptyState?: boolean;
    showPagination?: boolean;
    pageSize?: number;
};

export const CertificationsTable: React.FC<ProductCertificationsTableProps> = ({
    certifications,
    onClickStartEvaluation,
    showAsSubTable = false,
    isOwner = false,
    loading = false,
    showTitle = false,
    showPagination = false,
    pageSize = 10,
    showAlternateEmptyState = false,
}) => {
    const { t } = useTranslation();
    const { data: schemes } = useCyberpassConfigurationSearch({ filters: { types: [ConfigurationType.SCHEME], onlyActive: true }, pageSize: 10000 });
    const { data: forms } = useCyberpassFormSearch(
        { filters: { certificationIds: certifications?.map((c) => c.id) }, pageSize: 10000 },
        { enabled: certifications != null },
    );

    const schemeDefinitions = schemes?.items.map((scheme) => scheme.definition as ConfigurationDefinition<ConfigurationType.SCHEME>) ?? [];
    const columns: any = [
        {
            title: t('COMPONENTS.CERTIFICATIONS_TABLE.COLUMN_EVALUATION'),
            key: 'evaluation',
            align: 'left',
            minWidth: 500,
            render: (certification: Certification) => (
                <FormProductInfoSection certification={certification} extended={true} hideEvaluatorInfo hideProductDrawerArrow />
            ),
        },
        {
            title: t('COMPONENTS.CERTIFICATIONS_TABLE.COLUMN_LAB'),
            key: 'lab',
            align: 'center',
            width: 250,
            render: (certification: Certification) => {
                return <CertificationLabInfo certification={certification} />;
            },
        },
        {
            title: t('COMPONENTS.CERTIFICATIONS_TABLE.COLUMN_STATUS'),
            key: 'status',
            align: 'center',
            width: 150,
            filters: Object.values(EvaluationFlowStatus).map((status) => ({
                text: t(`SHARED.EVALUATION_FLOW_STATUS.${status}`),
                value: status,
            })),
            onFilter: (value: string, record: Certification) => {
                // TODO: this is a workaround, we should have a better way to get the evaluation flow status
                const certificationForms = forms?.items.filter((form) => form.certificationId === record.id);
                const schemeDefinition = schemeDefinitions.find((scheme) => scheme.id === record.schemeId);
                const evaluationFlowStatus = getEvaluationFlowStatus({
                    certification: record,
                    forms: certificationForms ?? [],
                    schemeDefinition,
                });
                return value === evaluationFlowStatus;
            },
            render: (certification: Certification) => <EvaluationFlowStatusInfo certification={certification} />,
        },
        // {
        //     title: t('COMPONENTS.CERTIFICATIONS_TABLE.COLUMN_DATE'),
        //     key: 'certifiedAt',
        //     align: 'center',
        //     width: 150,
        //     render: (certification: Certification) => <SmartDate date={certification?.certifiedAt} />,
        // },
        {
            title: t('COMPONENTS.CERTIFICATIONS_TABLE.COLUMN_EXPIRES'),
            key: 'expiresAt',
            align: 'center',
            width: 150,
            render: (certification: Certification) => <SmartDate date={certification?.expiresAt} showExpirationColors />,
        },
        {
            title: t('COMPONENTS.CERTIFICATIONS_TABLE.COLUMN_ACTIONS'),
            key: 'actions',
            align: 'center',
            width: 129,
            fixed: 'right',
            render: (certification: Certification) => <Actions certification={certification} showWatchAction />,
        },
    ];

    return (certifications ?? []).length > 0 || !showAlternateEmptyState ? (
        <Table
            title={
                showTitle
                    ? (rows) => {
                          if (certifications == null) {
                              return null;
                          }
                          return (
                              <div className="product-certification-table-header">
                                  {rows.length < certifications.length
                                      ? t('COMPONENTS.CERTIFICATIONS_TABLE.HEADER_TITLE', {
                                            visibleCount: rows.length,
                                            count: certifications?.length,
                                        })
                                      : t('COMPONENTS.CERTIFICATIONS_TABLE.HEADER_TITLE_ALL', {
                                            count: certifications?.length,
                                        })}
                              </div>
                          );
                      }
                    : undefined
            }
            size="small"
            loading={loading}
            bordered={false}
            className="product-certification-table"
            scroll={{ y: 'calc(100vh - 258px)', x: 'max-content' }} // note: 100vh => table-bbox > page-content-bbox, hence giving a little extra room to the only flexible column
            columns={columns}
            dataSource={certifications?.map((certification) => ({ key: certification.id, ...certification }))}
            rowClassName="product-certifications-table-row"
            locale={{ emptyText: t('COMPONENTS.CERTIFICATIONS_TABLE.NO_ENTRIES.FACT') }}
            pagination={
                showPagination
                    ? {
                          pageSize,
                          showSizeChanger: false,
                          position: ['bottomLeft'],
                      }
                    : false
            }
            // TODO add same logic as in <Actions />
            // onRow={(certification, rowIndex) => {
            //     return {
            //         onClick: (_) => {
            //             setSelectedProduct(product);
            //             setOpenDrawer(true);
            //         },
            //     };
            // }}
        />
    ) : (
        <NoEntriesInfo
            fact={t('COMPONENTS.CERTIFICATIONS_TABLE.NO_ENTRIES.FACT')}
            message={t('COMPONENTS.CERTIFICATIONS_TABLE.NO_ENTRIES.MESSAGE')}
            action={
                <Button type="link" onClick={onClickStartEvaluation}>
                    {t('COMPONENTS.CERTIFICATIONS_TABLE.NO_ENTRIES.ACTION')}
                </Button>
            }
            showShort={showAsSubTable || !isOwner}
        />
    );
};
