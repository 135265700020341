import type React from 'react';
import { useTranslation } from 'react-i18next';
import { type Product } from '@/utils/cyberpass';
import { notifyWithIcon } from '@/utils/notifications';
import { useCyberpassProductCreate, useCyberpassProductLookupById, useCyberpassProductUpdate } from '@/query';
import { FormItemsBuilder } from '@/components/FormItemsBuilder';
import { SimpleForm, type SimpleFormPropsFactory } from '@/components/SimpleForm';
import { useCyberpassWorkspaceLookupById } from '@/query';
import { useAuth } from '@/hooks/useAuth';
import { buildProductFormItems } from './form';

type ProductFormProps = SimpleFormPropsFactory<'product', Product> & {
    creatorWorkspaceId?: string;
    initialValues?: Partial<Product>;
};

export const ProductForm: React.FC<ProductFormProps> = ({ product, editable, onSubmit, onSuccess, onCancel, onError, creatorWorkspaceId, initialValues }) => {
    const { t } = useTranslation();
    const { data: productData } = useCyberpassProductLookupById(product?.id, { initialData: product }); // rq sensitive!
    const create = useCyberpassProductCreate();
    const update = useCyberpassProductUpdate(product?.id);

    const { currentWorkspace, user } = useAuth();
    const { data: creatorWorkspace } = useCyberpassWorkspaceLookupById(creatorWorkspaceId);
    const { data: productWorkspace } = useCyberpassWorkspaceLookupById(product?.workspaceId);

    const items = buildProductFormItems(productData, product != null ? productWorkspace : (creatorWorkspace ?? currentWorkspace), user?.id);

    const handleSubmit = (values: Product, done: () => void) => {
        onSubmit?.(values);
        const props = {
            workspaceId: creatorWorkspace?.id ?? undefined,
            name: values.name ?? undefined,
            description: values.description ?? undefined,
            uniqueCode: values.uniqueCode ?? undefined,
            sectoral: values.sectoral ?? undefined,
            functional: values.functional ?? undefined,
            technological: values.technological ?? undefined,
            props: {
                featuredImageFileId: values.props?.featuredImageFileId,
                regularImageFileIds: values.props?.regularImageFileIds ?? undefined,
                brochureFileIds: values.props?.brochureFileIds ?? undefined,
            },
        };

        const options = {
            onSuccess: (data: Product | undefined) => {
                notifyWithIcon('success', t('COMPONENTS.PRODUCT_FORM.NOTIFY.SUBMIT_SUCCEEDED'));
                if (data != null) {
                    onSuccess?.(data);
                }
            },
            onError: () => {
                notifyWithIcon('error', t('COMPONENTS.PRODUCT_FORM.NOTIFY.SUBMIT_FAILED'));
                onError?.();
            },
            onSettled: () => {
                done();
            },
        };
        if (product == null) {
            create.mutate(props, options);
        } else {
            update.mutate(props, options);
        }
    };

    return (
        <SimpleForm<Product> //
            initialValues={initialValues ?? productData}
            editable={editable}
            onSubmit={handleSubmit}
            onCancel={onCancel}
            submitLabel={t('COMPONENTS.PRODUCT_FORM.SUBMIT_LABEL')}
        >
            <FormItemsBuilder //
                items={items}
                editable={editable}
            />
        </SimpleForm>
    );
};
