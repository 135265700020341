import type React from 'react';
import { Switch } from 'antd';

type InputSwitchProps = {
    id?: string;
    value?: boolean | null;
    onChange?: (value: boolean | null) => void;
    size?: 'small' | 'middle' | 'large';
    disabled?: boolean;
};

export const InputSwitch: React.FC<InputSwitchProps> = ({ id, value = false, onChange, size = 'middle', disabled = false }) => {
    return (
        <Switch
            id={id}
            size={size === 'small' ? 'small' : 'default'}
            checked={value ?? false}
            onChange={(value: boolean | null) => {
                onChange?.(value);
            }}
            disabled={disabled}
        />
    );
};
