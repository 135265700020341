import moment from 'moment';
import { type ReactNode, createContext, useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useIntercom } from 'react-use-intercom';
import i18next from '@/utils/i18next';
import cyberpass, { type Workspace, type User } from '@/utils/cyberpass';
import { useCyberpassUserGetDetails, useCyberpassUserLogout, useCyberpassUserUpdateData } from '@/query';
import { WORKSPACE_HEADER_KEY } from '@/utils/workspaces';

type NewType = {
    user: User | undefined | null;
    currentWorkspace: Workspace | undefined;
    onWorkspaceChange: (workspace: Workspace | undefined) => void;
    refetch: () => void;
    logout: () => void;
    authenticated: boolean;
    loading: boolean;

    /** temporary properties which will be replaced once permission system is in place */
    extras: {
        isLab: boolean;
        userCapabilities: string[];
        workspaceCapabilities: string[];
    };
};

type AuthContextProps = NewType;

export const AuthContext = createContext<AuthContextProps | undefined>(undefined);

type AuthProviderProps = {
    children: ReactNode;
};

// Note: make sure that this Provider/Context only handles User Authentication context and user directly related data
// no navigation, no nothing else ...
export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [authenticated, setAuthenticated] = useState<boolean>(false);
    const [userId, setUserId] = useState<string>();
    const [currentWorkspace, setCurrentWorkspace] = useState<Workspace>();
    // setGlobalHeaders
    const { data: userDetails, refetch } = useCyberpassUserGetDetails(userId ?? undefined);
    const logout = useCyberpassUserLogout();
    const updateUserData = useCyberpassUserUpdateData(userId);
    const { update: updateIntercom } = useIntercom();

    const queryClient = useQueryClient();

    const handleStateChange = (state: any) => {
        // log.debug('AuthProvider::applyState: new cyberpass state', state);
        if (state.loading !== loading) {
            // log.debug(`AuthProvider::applyState: [loading] changed: ${loading} => ${state.loading}`);
            setLoading(state.loading);
        }
        if (state.authenticated !== authenticated) {
            // log.debug(`AuthProvider::applyState: [authenticated] changed: ${authenticated} => ${state.authenticated}`);
            setAuthenticated(state.authenticated);
        }
        if (state.auth.userId !== userId) {
            // log.debug(`AuthProvider::applyState: [auth] changed: ${userId} => ${state.auth.userId}`);
            setUserId(state.auth.userId);
        }
    };

    const handleLogout = async () => {
        logout.mutate(undefined, {
            onSettled: () => {
                cyberpass.disconnect();
                // on disconnect, the core will update the authentication state to null and the user will be logged out
            },
        });
    };

    useEffect(() => {
        // log.debug('AuthProvider::useEffect []');
        cyberpass.api.setOnStateChange(handleStateChange); // will trigger handleStateChange
    }, []);

    useEffect(() => {
        // log.debug('AuthProvider::useEffect [userDetails]', userDetails);
        if (userDetails == null) {
            return;
        }
        // update i18n
        i18next.changeLanguage(userDetails?.preferences?.appLanguage);
        if (userDetails?.preferences?.appLanguage == null && i18next.resolvedLanguage != null) {
            updateUserData.mutate({
                preferences: {
                    ...userDetails?.preferences,
                    appLanguage: i18next.resolvedLanguage,
                },
            });
        }
        // update intercom
        updateIntercom({
            email: userDetails.email,
            userId: userDetails.id,
            createdAt: moment(userDetails.createdAt).unix(),
            name: userDetails.fullName ?? undefined,
            phone: userDetails.phoneNumber ?? undefined,
            languageOverride: i18next.resolvedLanguage,
            userHash: userDetails.props?.intercomUserHash,
        });
    }, [userDetails]);

    const onWorkspaceChange = (workspace: Workspace | undefined) => {
        if (workspace == null) {
            return;
        }
        cyberpass.api.setGlobalHeaders({
            [WORKSPACE_HEADER_KEY]: workspace.id,
        });

        queryClient.invalidateQueries();

        setCurrentWorkspace(workspace);
    };

    return (
        <AuthContext.Provider
            value={{
                user: userDetails,
                currentWorkspace,
                onWorkspaceChange,
                refetch,
                logout: handleLogout,
                authenticated,
                loading,
                extras: {
                    isLab: currentWorkspace?.capabilities?.some((capability) => capability.startsWith('lab:')) ?? false,
                    userCapabilities: userDetails?.capabilities ?? [],
                    workspaceCapabilities: currentWorkspace?.capabilities ?? [],
                },
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
