import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import cyberpass, { type Query, type UpdateUserProps, type VerifyPinRequest, type ViewUserResponse } from '@/utils/cyberpass';
import { userKeys as keys } from './index.keys';
import { defaultOnError } from './helpers';

export const useCyberpassUserBind = () => {
    return useMutation({
        mutationFn: async (email: string) => {
            return cyberpass.api.users.bind(email);
        },
        onError: defaultOnError,
    });
};

export const useCyberpassUserVerifyPin = () => {
    return useMutation(
        async (props: VerifyPinRequest) => {
            return cyberpass.api.users.verifyPin(props);
        },
        {
            onError: defaultOnError,
        },
    );
};

export const useCyberpassUserGetDetails = (id: string | undefined, options?: any) => {
    return useQuery<ViewUserResponse | undefined>(
        keys.detail(id),
        async () => {
            if (id != null) {
                return cyberpass.api.users.lookupById(id);
            }
        },
        {
            enabled: id != null,
            ...options,
        },
    );
};

export const useCyberpassUserUpdateData = (id: string | undefined) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async (props: UpdateUserProps) => {
            if (id != null) {
                return cyberpass.api.users.updateById(id, props);
            }
        },
        onSuccess: () => {
            if (id != null) {
                queryClient.invalidateQueries(keys.detail(id));
                queryClient.invalidateQueries(keys.lists());
            }
        },
        onError: defaultOnError,
    });
};

export const useCyberpassUserAutocomplete = () => {
    return useMutation({
        mutationFn: async (text: string) => {
            return cyberpass.api.users.autocomplete(text);
        },
        onError: defaultOnError,
    });
};

export const useCyberpassUserGetLabs = () => {
    const queryClient = useQueryClient();
    return useQuery<ViewUserResponse[] | undefined>(keys.listByLabs(), async () => {
        const response = await cyberpass.api.users.getLabs();
        response?.map((user) => queryClient.setQueryData(keys.detail(user.id), user));
        return response;
    });
};

export const useCyberpassUserLogout = () => {
    return useMutation({
        mutationFn: async () => {
            cyberpass.api.users.logout();
        },
        onError: defaultOnError,
    });
};

export const useCyberpassUserSearch = (query: Query | undefined, options?: { enabled?: boolean }) => {
    const queryClient = useQueryClient();
    return useQuery(
        keys.list(query),
        async () => {
            if (query != null) {
                const response = await cyberpass.api.users.search(query);
                response?.items?.map((user) => queryClient.setQueryData(keys.detail(user.id), user));
                return response;
            }
        },
        {
            enabled: query != null && (options?.enabled ?? true),
        },
    );
};
