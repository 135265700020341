import { useTranslation } from 'react-i18next';
import { Collapse, Empty, List, Typography } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import { useCyberpassFileSearch } from '@/query';
import { type File } from '@/utils/cyberpass';
import { triggerFileDownload, formatByteFileSize } from '@/utils/file';
import './styles.css';

type FileDownloaderProps = {
    fileIds: string[];
    showHeader?: boolean;
    hideIfNoFiles?: boolean;
    title?: string;
    applyFilter?: (file: File) => boolean;
    showFileCount?: boolean;
    showEmptySection?: boolean;
    fileClassName?: string;
};

export const FileDownloader: React.FC<FileDownloaderProps> = ({
    fileIds,
    showHeader = true,
    hideIfNoFiles = false,
    title,
    applyFilter,
    showFileCount = false,
    showEmptySection = false,
    fileClassName = '',
}) => {
    const { t } = useTranslation();
    const { data: fileData, isLoading } = useCyberpassFileSearch(
        { filters: { ids: fileIds ?? [], asOwner: 'false' } },
        { enabled: fileIds != null && fileIds.length > 0 },
    );

    const files = fileData?.items ?? [];

    if (files.length === 0 && !showEmptySection) {
        return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('SHARED.EMPTY_DESCRIPTION')} />;
    }

    if (isLoading) {
        return <></>;
    }

    const filteredFiles = (applyFilter == null ? files : files.filter((file) => applyFilter(file))) ?? [];

    if (hideIfNoFiles && filteredFiles.length === 0) {
        return <></>;
    }

    const fileList = showFileCount ? (
        t('SHARED.FILE', { count: filteredFiles?.length ?? 0 })
    ) : (
        <List
            dataSource={filteredFiles}
            className="file-uploader-list"
            renderItem={(file) => (
                <List.Item key={file?.id} className={`file-uploader-list-item ${fileClassName}`}>
                    <a
                        href="#"
                        onClick={() => {
                            triggerFileDownload(file);
                        }}
                    >
                        {file?.props.originalFileName}
                    </a>{' '}
                    ({formatByteFileSize(file?.props.originalFileSize ?? 0)})
                </List.Item>
            )}
        />
    );

    return showHeader ? (
        <Collapse
            className="collapse-dense"
            expandIcon={({ isActive }) => <CaretRightOutlined className="file-uploader-icon" rotate={(isActive ?? false) ? 90 : 0} />}
            defaultActiveKey={filteredFiles.length > 0 ? ['download-files'] : undefined}
            ghost
        >
            <Collapse.Panel
                key="download-files"
                header={
                    <Typography.Title className="file-uploader-title" level={4}>
                        {title ?? ''}
                        <span className="file-uploader-file-count-container">
                            {'('}
                            <span className="file-uploader-file-count">{filteredFiles.length}</span>
                            {filteredFiles.length === 1 ? t('COMPONENTS.FILE_DOWNLOADER.FILE') : t('COMPONENTS.FILE_DOWNLOADER.FILES')}
                            {')'}
                        </span>
                    </Typography.Title>
                }
            >
                {fileList}
            </Collapse.Panel>
        </Collapse>
    ) : (
        fileList
    );
};
