import type React from 'react';
import { useState, useEffect, useMemo } from 'react';
import { Select } from 'antd';
import debounce from 'lodash.debounce';
import { notifyWithIcon } from '@/utils/notifications';
import { useCyberpassUserAutocomplete } from '@/query';
type InputProvidersProps = {
    id?: string;
    value?: string[];
    onChange?: (value: string[]) => void;
    disabled?: boolean;
    placeholder?: React.ReactNode;
};

export const InputProviders: React.FC<InputProvidersProps> = ({ id, value, onChange, placeholder, disabled }) => {
    const [options, setOptions] = useState<Array<{ value: string }>>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const userAutocomplete = useCyberpassUserAutocomplete();

    const handleSearchDebounced = useMemo(
        () =>
            debounce(async (query: string) => {
                if (query?.length < 2) {
                    setOptions([]);
                    return;
                }
                setLoading(true);
                userAutocomplete.mutate(query, {
                    onSuccess: (data) => {
                        if (data != null) {
                            const options = data.items.map((item: string) => {
                                return { value: item };
                            });
                            setOptions(options);
                        }
                    },
                    onError: (error) => {
                        if (error instanceof Error) {
                            notifyWithIcon('error', `${error.message}`);
                        }
                    },
                    onSettled: () => {
                        setLoading(false);
                    },
                });
            }, 500),
        [],
    );

    const handleChange = (value: string[]) => {
        onChange?.(value);
    };

    useEffect(() => {
        return () => {
            handleSearchDebounced.cancel();
        };
    }, []);

    return (
        <Select //
            id={id}
            disabled={disabled}
            value={value}
            mode="multiple"
            placeholder={placeholder}
            onChange={handleChange}
            onSearch={handleSearchDebounced}
            loading={loading}
            options={options}
            showSearch
        />
    );
};
