// TODO review deprecated typing

import i18next from '@/utils/i18next';
import { type User } from '@/utils/cyberpass';
import { type FormItemsBuilderItems } from '@/components/FormItemsBuilder';

export const buildAssessorDetailsFormItems = (lab: User | undefined): FormItemsBuilderItems => {
    return [
        {
            label: i18next.t('COMPONENTS.USER_DETAILS_FORM.FULL_NAME'),
            type: 'text',
            property: 'fullName',
            value: lab?.fullName,
            options: {
                required: true,
            },
        },
        {
            label: i18next.t('COMPONENTS.USER_DETAILS_FORM.EMAIL'),
            type: 'text-email',
            property: 'email',
            value: lab?.email,
            options: {
                editable: false,
                required: true,
            },
        },
        {
            label: i18next.t('COMPONENTS.USER_DETAILS_FORM.PHONE'),
            type: 'text-phone',
            property: 'phoneNumber',
            value: lab?.phoneNumber,
        },
        {
            label: i18next.t('COMPONENTS.USER_DETAILS_FORM.ADDRESS'),
            type: 'address',
            property: 'address',
            value: lab?.address,
        },
    ];
};
