import type React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { ShadowCard } from '@/components/ShadowCard';
import { VulnerabilityDrawer } from '@/components/ProductDrawer/VulnerabilityDrawer';
import './styles.css';

type ReportVulnerabilityCardProps = {
    productId: string | undefined;
};

export const ReportVulnerabilityCard: React.FC<ReportVulnerabilityCardProps> = ({ productId }) => {
    const { t } = useTranslation();
    const [openDrawer, setOpenDrawer] = useState<boolean>(false);

    const handleOnClickLink = () => {
        setOpenDrawer(true);
    };

    const handleOnCloseDrawer = () => {
        // TODO
        setOpenDrawer(false);
    };

    return productId == null ? null : (
        <>
            <ShadowCard title={t('PAGES.RESULT.VULNERABILITY_REPORT_CARD.TITLE')} containerClassName="report-vulnerability-card-container">
                <div className="report-vulnerability-card-message">{t('PAGES.RESULT.VULNERABILITY_REPORT_CARD.MESSAGE')}</div>
                <Button className="report-vulnerability-card-action" type="link" onClick={handleOnClickLink}>
                    {t('PAGES.RESULT.VULNERABILITY_REPORT_CARD.ACTION')}
                </Button>
            </ShadowCard>
            <VulnerabilityDrawer productId={productId} open={openDrawer} onClose={handleOnCloseDrawer} />
        </>
    );
};
