import type React from 'react';
import { useTranslation } from 'react-i18next';
import { HddOutlined } from '@ant-design/icons';
import { type Workspace } from '@/utils/cyberpass';
import { FormItemsBuilder } from '@/components/FormItemsBuilder';
import { SimpleForm, type SimpleFormPropsFactory } from '@/components/SimpleForm';
import { buildWorkspaceFormItems } from './form';
import { useCyberpassWorkspaceCreate, useCyberpassWorkspaceLookupById, useCyberpassWorkspaceUpdate } from '@/query';
import { notifyWithIcon } from '@/utils/notifications';
import { useQuota } from '@/hooks/useQuota';
import { QuotaType } from '@/types/quotas';
import { useAuth } from '@/hooks/useAuth';
import { notifyOnReachedQuota } from '@/utils/quotas';

type WorkspaceFormProps = SimpleFormPropsFactory<'workspace', Workspace>;

export const WorkspaceForm: React.FC<WorkspaceFormProps> = ({ workspace, editable, onSubmit, onSuccess, onCancel, onError }) => {
    const { t } = useTranslation();
    const { data: workspaceData } = useCyberpassWorkspaceLookupById(workspace?.id, { initialData: workspace }); // rq sensitive!

    const items = buildWorkspaceFormItems(workspaceData);
    const { user } = useAuth();

    const create = useCyberpassWorkspaceCreate();
    const update = useCyberpassWorkspaceUpdate(workspace?.id);

    const quota = useQuota({ type: QuotaType.MAX_WORKSPACES_PER_USER, userId: user?.id });

    const handleSubmit = (values: Workspace, done: () => void) => {
        onSubmit?.(values);

        const options = {
            onSuccess: (data: Workspace | undefined) => {
                notifyWithIcon('success', t('COMPONENTS.WORKSPACE_FORM.NOTIFY.SUBMIT_SUCCEEDED'));
                if (data != null) {
                    onSuccess?.(data);
                }
            },
            onError: () => {
                notifyWithIcon('error', t('COMPONENTS.WORKSPACE_FORM.NOTIFY.SUBMIT_FAILED'));
                onError?.();
            },
            onSettled: () => {
                done();
            },
        };

        if (workspace == null) {
            if (quota != null && quota.reached) {
                notifyOnReachedQuota(quota);
                done();
                return;
            }
            create.mutate(values, options);
        } else {
            update.mutate(values, options);
        }
    };

    return (
        <SimpleForm<Workspace> //
            initialValues={workspaceData}
            editable={editable}
            submitLabel={t('SHARED.SAVE')}
            onSubmit={handleSubmit}
            onCancel={onCancel}
        >
            <FormItemsBuilder //
                items={items}
                defaultImage={<HddOutlined />}
                editable={editable}
            />
        </SimpleForm>
    );
};
