import type React from 'react';
import { Card } from 'antd';
import './styles.css';

type ShadowCardProps = {
    title?: string | React.ReactNode;
    extra?: React.ReactNode;
    containerClassName?: string;
    className?: string;
    onClick?: () => void;
    hoverable?: boolean;
    disabled?: boolean;
    selected?: boolean;
    children: React.ReactNode;
    style?: React.CSSProperties;
};

export const ShadowCard: React.FC<ShadowCardProps> = ({
    title,
    extra,
    containerClassName,
    className,
    onClick,
    hoverable,
    children,
    disabled,
    selected,
    style,
}) => {
    const cardClasses = `shadow-card-container${(disabled ?? false) ? ' disabled' : ''}${selected === true ? ' selected' : ''} ${containerClassName ?? ''}`;

    return (
        <Card
            className={cardClasses}
            title={title}
            dir="vertical"
            onClick={(disabled ?? false) ? undefined : onClick}
            hoverable={!(disabled ?? false) && hoverable}
            extra={extra}
            style={style}
        >
            <div className={className ?? ''}>{children}</div>
        </Card>
    );
};
