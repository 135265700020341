import type React from 'react';
import { useTranslation } from 'react-i18next';
import { type RiskLevel, getPercentageColor } from '../../PercentageBar';
import './styles.css';

type AssuranceScoreCardLegendItemProps = {
    riskLevel: RiskLevel;
};
export const AssuranceScoreCardLegendItem: React.FC<AssuranceScoreCardLegendItemProps> = ({ riskLevel }) => {
    const { t } = useTranslation();
    const color = getPercentageColor(riskLevel);
    return (
        <div className="assurance-score-card-column">
            <div>
                <span className="assurance-score-card-range">{t(`SHARED.RISK.RANGE.${riskLevel}`)}</span>
                <div className="assurance-score-card-bar" style={{ borderColor: color, backgroundColor: color }} />
            </div>
            <span className="assurance-score-card-label">{t(`SHARED.RISK.LABEL.${riskLevel}`)}</span>
        </div>
    );
};
