import React from 'react';
import { Modal, Tooltip } from 'antd';

type ActionTableButtonType = {
    icon: JSX.Element;
    tooltip: string;
    color?: string;
    danger?: boolean;
    hidden?: boolean;
    onClick?: () => void;
    title?: string;
    confirmationText?: React.ReactNode;
    okText?: string;
};

export const ActionTableButton: React.FC<ActionTableButtonType> = ({
    icon,
    tooltip,
    color = 'var(--blue-6)',
    danger = false,
    hidden = false,
    onClick,
    confirmationText,
    title,
    okText = 'OK',
}) => {
    return hidden ? null : (
        <Tooltip title={tooltip} color={color}>
            {React.cloneElement(icon, {
                style: { color, margin: '0 4px' },
                onClick: (event: any) => {
                    event.stopPropagation();
                    if (confirmationText != null) {
                        Modal.confirm({
                            title,
                            okText,
                            okButtonProps: danger ? { danger: true, type: 'primary' } : {},
                            content: confirmationText,
                            onOk() {
                                onClick?.();
                            },
                            onCancel() {
                                // nop
                            },
                        });
                    } else {
                        onClick?.();
                    }
                },
            })}
        </Tooltip>
    );
};
