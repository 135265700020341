export default {
    PORT: import.meta.env.PORT,
    NODE_ENV: import.meta.env.NODE_ENV,
    FORCE_HTTPS: import.meta.env.FORCE_HTTPS,

    ENV: import.meta.env.VITE_ENV,
    BACKEND_BASE_URL: import.meta.env.VITE_BACKEND_BASE_URL,
    FRONTEND_BASE_URL: import.meta.env.VITE_FRONTEND_BASE_URL,
    VITE_HOME_URL: import.meta.env.VITE_HOME_URL,

    SALES_EMAIL: import.meta.env.VITE_SALES_EMAIL ?? 'hello@redalertlabs.com',

    CHEAT_FILL_QUESTIONNAIRE: import.meta.env.VITE_CHEAT_FILL_QUESTIONNAIRE ?? '',
    INTERCOM_APP_ID: import.meta.env.VITE_INTERCOM_APP_ID ?? '',

    AI_UC1_COOLDOWN_PERIOD_S: import.meta.env.VITE_AI_UC1_COOLDOWN_PERIOD_S ?? '',

    BACKEND_WEB_SOCKET_URL: import.meta.env.VITE_BACKEND_WEB_SOCKET_URL ?? '',
};
