import { useTranslation } from 'react-i18next';
import { Button, Result } from 'antd';
import { useNavigate } from 'react-router';
import { useErrorBoundary } from 'react-error-boundary';
import RedErrorViewImage from '@/assets/images/red-10-ask.svg';
import './styles.css';

type ErrorPageProps = {
    error?: Error;
};

export const ErrorPage: React.FC<ErrorPageProps> = () => {
    const { resetBoundary } = useErrorBoundary();
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <Result
            className="error-page-container"
            icon={<img src={RedErrorViewImage} className="error-page-image" alt="" />}
            title={<span className="error-page-title">{t('PAGES.ERROR.HEADER')}</span>}
            subTitle={
                <>
                    <div className="error-page-message" dangerouslySetInnerHTML={{ __html: t('PAGES.ERROR.SUBTEXT') }} />
                    <Button
                        type="default"
                        size="large"
                        className="error-page-button"
                        onClick={() => {
                            navigate('/', { replace: true });
                            resetBoundary();
                        }}
                    >
                        {t('PAGES.ERROR.REDIRECT_BUTTON')}
                    </Button>
                </>
            }
        />
    );
};
