import { Col, Row, Input, Divider, InputNumber, Select, Tag, Switch } from 'antd';
import moment from 'moment';
import { EnvironmentOutlined, GlobalOutlined, MailOutlined, PhoneOutlined, SwapRightOutlined } from '@ant-design/icons';
import i18next, { displayLanguage } from '@/utils/i18next';
import { FileAvatar } from '@/components/FileAvatar';
import { FileCarousel } from '@/components/FileCarousel';
import { FileDownloader } from '@/components/FileDownloader';
import { FileImage } from '@/components/FileImage';
import { ProductInfo } from '@/components/ProductInfo';
import { ReadMore } from '@/components/ReadMore';
import { SmartAmount } from '@/components/SmartAmount';
import { SmartDate } from '@/components/SmartDate';
import { CertificationReference } from '@/components/CertificationReference';
import { FormItem } from './FormItem';
import { InputAmount } from './InputAmount';
import { InputCountry } from './InputCountry';
import { InputDate } from './InputDate';
import { InputDropdownMultiple } from './InputDropdownMultiple';
import { InputDropdownSingle } from './InputDropdownSingle';
import { InputLanguage } from './InputLanguage';
import { InputMultipleFileUploader } from './InputMultipleFileUploader';
import { InputOneFileUploader } from './InputOneFileUploader';
import { InputPhoneNumber } from './InputPhoneNumber';
import { InputProduct } from './InputProduct';
import { InputProviders } from './InputProviders';
import { InputSwitch } from './InputSwitch';
import { InputDisclaimer } from './InputDisclaimer';
import { type FormItemsBuilderItem, type FormItemsBuilderItemType, type FormItemsBuilderItemRender, FormItemsBuilderItemMode } from './types';
import { InputDateRange } from './InputDateRange';
import { CustomInputDropdown } from './CustomInputDropdown';
import { InputCascader } from './InputCascader';
import { FormTitleField } from './FormTitleField';
import { WorkspaceInfo } from '../WorkspaceInfo';
import { InputWorkspace } from './InputWorkspace';

export const meta = ({ defaultText }: { defaultText?: string | JSX.Element }) => {
    const kit: Record<FormItemsBuilderItemType, FormItemsBuilderItemRender> = {
        address: {
            view: (item: FormItemsBuilderItem) => {
                const address = item.value;
                const addressLine = [address?.line1, address?.line2, address?.zip, address?.city, /* state */ address?.country]
                    .filter((e) => e != null && e !== '')
                    .join(', ');
                return (
                    <>
                        <EnvironmentOutlined style={{ paddingRight: 7 }} />
                        {addressLine == null || addressLine === '' ? (
                            defaultText
                        ) : (
                            <a href={`https://www.google.com/maps/search/${addressLine}`} target="_blank" rel="noopener noreferrer">
                                <ReadMore html={addressLine} />
                            </a>
                        )}
                    </>
                );
            },
            edit: (item: FormItemsBuilderItem) => {
                return (
                    <Row>
                        <Col span={24}>
                            <FormItem
                                name={[item.property, 'line1'].flat()}
                                tooltip={item?.options?.tooltip}
                                rules={
                                    item?.options?.rules ?? [
                                        {
                                            required: item?.options?.required ?? false,
                                            message: i18next.t('COMPONENTS.FORM_ITEMS_BUILDER.RULE_FAILED_REQUIRED'),
                                        },
                                        { type: 'string', min: 2, message: i18next.t('COMPONENTS.FORM_ITEMS_BUILDER.RULE_FAILED_MIN', { count: 2 }) },
                                        { type: 'string', max: 200, message: i18next.t('COMPONENTS.FORM_ITEMS_BUILDER.RULE_FAILED_MAX', { count: 200 }) },
                                        ...(item?.options?.rules ?? []),
                                    ]
                                }
                            >
                                <Input
                                    id={item.property?.toString()}
                                    type="text"
                                    placeholder={i18next.t('COMPONENTS.FORM_ITEMS_BUILDER.ADDRESS.LINE_1')}
                                    allowClear
                                    disabled={!(item.options?.editable ?? true) || (item.options?.disabled as boolean)}
                                />
                            </FormItem>
                        </Col>
                        <Col span={24}>
                            <FormItem
                                name={[item.property, 'line2'].flat()}
                                tooltip={item?.options?.tooltip}
                                rules={
                                    item?.options?.rules ?? [
                                        { type: 'string', min: 2, message: i18next.t('COMPONENTS.FORM_ITEMS_BUILDER.RULE_FAILED_MIN', { count: 2 }) },
                                        { type: 'string', max: 200, message: i18next.t('COMPONENTS.FORM_ITEMS_BUILDER.RULE_FAILED_MAX', { count: 200 }) },
                                        ...(item?.options?.rules ?? []),
                                    ]
                                }
                            >
                                <Input
                                    type="text"
                                    placeholder={i18next.t('COMPONENTS.FORM_ITEMS_BUILDER.ADDRESS.LINE_2')}
                                    allowClear
                                    disabled={!(item.options?.editable ?? true) || (item.options?.disabled as boolean)}
                                />
                            </FormItem>
                        </Col>
                        <Col span={10}>
                            <FormItem
                                name={[item.property, 'zip'].flat()}
                                tooltip={item?.options?.tooltip}
                                rules={
                                    item?.options?.rules ?? [
                                        {
                                            required: item?.options?.required ?? false,
                                            message: i18next.t('COMPONENTS.FORM_ITEMS_BUILDER.RULE_FAILED_REQUIRED'),
                                        },
                                        { type: 'string', min: 2, message: i18next.t('COMPONENTS.FORM_ITEMS_BUILDER.RULE_FAILED_MIN', { count: 2 }) },
                                        { type: 'string', max: 200, message: i18next.t('COMPONENTS.FORM_ITEMS_BUILDER.RULE_FAILED_MAX', { count: 200 }) },
                                        ...(item?.options?.rules ?? []),
                                    ]
                                }
                            >
                                <Input
                                    type="text"
                                    placeholder={i18next.t('COMPONENTS.FORM_ITEMS_BUILDER.ADDRESS.POSTAL_CODE')}
                                    allowClear
                                    disabled={!(item.options?.editable ?? true) || (item.options?.disabled as boolean)}
                                />
                            </FormItem>
                        </Col>
                        <Col span={14}>
                            <FormItem
                                name={[item.property, 'city'].flat()}
                                tooltip={item?.options?.tooltip}
                                rules={
                                    item?.options?.rules ?? [
                                        {
                                            required: item?.options?.required ?? false,
                                            message: i18next.t('COMPONENTS.FORM_ITEMS_BUILDER.RULE_FAILED_REQUIRED'),
                                        },
                                        { type: 'string', min: 2, message: i18next.t('COMPONENTS.FORM_ITEMS_BUILDER.RULE_FAILED_MIN', { count: 2 }) },
                                        { type: 'string', max: 200, message: i18next.t('COMPONENTS.FORM_ITEMS_BUILDER.RULE_FAILED_MAX', { count: 200 }) },
                                        ...(item?.options?.rules ?? []),
                                    ]
                                }
                            >
                                <Input
                                    type="text"
                                    placeholder={i18next.t('COMPONENTS.FORM_ITEMS_BUILDER.ADDRESS.CITY')}
                                    allowClear
                                    disabled={!(item.options?.editable ?? true) || (item.options?.disabled as boolean)}
                                />
                            </FormItem>
                        </Col>
                        {/* state */}
                        <Col span={24}>
                            <FormItem
                                name={[item.property, 'country'].flat()}
                                tooltip={item?.options?.tooltip}
                                rules={
                                    //
                                    item?.options?.rules ?? [{ required: item?.options?.required ?? false }]
                                }
                            >
                                <InputCountry
                                    placeholder={i18next.t('COMPONENTS.FORM_ITEMS_BUILDER.ADDRESS.COUNTRY')}
                                    disabled={!(item.options?.editable ?? true) || (item.options?.disabled as boolean)}
                                />
                            </FormItem>
                        </Col>
                    </Row>
                );
            },
        },

        amount: {
            view: (item: FormItemsBuilderItem) => {
                return <SmartAmount amount={item?.value ?? 0} showCurrency showDigits />;
            },
            edit: (item: FormItemsBuilderItem) => {
                return (
                    <FormItem
                        name={item.property}
                        tooltip={item?.options?.tooltip}
                        rules={
                            item?.options?.rules ?? [
                                {
                                    required: item?.options?.required ?? false,
                                    message: i18next.t('COMPONENTS.FORM_ITEMS_BUILDER.RULE_FAILED_REQUIRED'),
                                },
                            ]
                        }
                    >
                        <InputAmount disabled={!(item.options?.editable ?? true) || (item.options?.disabled as boolean)} />
                    </FormItem>
                );
            },
        },

        'certification-reference': {
            view: (item: FormItemsBuilderItem) => {
                return <CertificationReference certification={item.value} />;
            },
            edit: (item: FormItemsBuilderItem) => {
                return <></>; // nop!
            },
        },

        'checkbox-disclaimer': {
            view: (item: FormItemsBuilderItem) => {
                return <InputDisclaimer value={item.value} content={item.label} showLabel={false} />;
            },
            edit: (item: FormItemsBuilderItem) => {
                return (
                    <FormItem
                        name={item.property}
                        tooltip={item?.options?.tooltip}
                        rules={[
                            ...(item?.options?.rules ?? []),
                            ...(item?.options?.required != null && item.options.required
                                ? [
                                      {
                                          validator: (_: any, value: boolean) => {
                                              return value != null && value ? Promise.resolve() : Promise.reject(new Error());
                                          },
                                          message: i18next.t('COMPONENTS.FORM_ITEMS_BUILDER.RULE_FAILED_REQUIRED'),
                                      },
                                  ]
                                : []),
                        ]}
                    >
                        <InputDisclaimer
                            content={item.label}
                            showLabel={false}
                            disabled={!(item.options?.editable ?? true) || (item.options?.disabled as boolean)}
                        />
                    </FormItem>
                );
            },
        },
        cascader: {
            view: (item: FormItemsBuilderItem) => {
                const render = item.options?.render ?? ((tag, mode) => <Tag>{tag}</Tag>);
                const values = (item.value as string[]) ?? [];
                return values.length === 0 ? '-' : values.map((tag) => render(tag, FormItemsBuilderItemMode.VIEW));
            },
            edit: (item: FormItemsBuilderItem) => {
                const choices = (item?.options?.choices ?? []).map((choice) => ({
                    ...choice,
                    disabled: (typeof item.options?.disabled === 'function' ? item.options?.disabled?.(choice) : item.options?.disabled) ?? false,
                }));
                return (
                    <FormItem
                        name={item.property}
                        tooltip={item?.options?.tooltip}
                        rules={
                            item?.options?.rules ?? [
                                {
                                    required: item?.options?.required ?? false,
                                    message: i18next.t('COMPONENTS.FORM_ITEMS_BUILDER.RULE_FAILED_REQUIRED'),
                                },
                            ]
                        }
                    >
                        <InputCascader
                            id={item?.property?.toString()}
                            choices={choices}
                            multiple={item?.options?.multiple}
                            showSearch={item?.options?.showSearch}
                            displayRender={item?.options?.displayRender}
                            disabled={!(item.options?.editable ?? true) || (item.options?.disabled as boolean)}
                        />
                    </FormItem>
                );
            },
        },
        country: {
            view: (item: FormItemsBuilderItem) => {
                return item.value;
            },
            edit: (item: FormItemsBuilderItem) => {
                return (
                    <FormItem
                        name={item.property}
                        tooltip={item?.options?.tooltip}
                        rules={
                            item?.options?.rules ?? [
                                {
                                    required: item?.options?.required ?? false,
                                    message: i18next.t('COMPONENTS.FORM_ITEMS_BUILDER.RULE_FAILED_REQUIRED'),
                                },
                            ]
                        }
                    >
                        <InputCountry id={item.property?.toString()} disabled={!(item.options?.editable ?? true) || (item.options?.disabled as boolean)} />
                    </FormItem>
                );
            },
        },

        date: {
            view: (item: FormItemsBuilderItem) => {
                return item.value == null ? defaultText : <SmartDate date={item.value} />;
            },
            edit: (item: FormItemsBuilderItem) => {
                return (
                    <FormItem
                        name={item.property}
                        tooltip={item?.options?.tooltip}
                        rules={
                            item?.options?.rules ?? [
                                {
                                    required: item?.options?.required ?? false,
                                    message: i18next.t('COMPONENTS.FORM_ITEMS_BUILDER.RULE_FAILED_REQUIRED'),
                                },
                            ]
                        }
                    >
                        <InputDate id={item.property?.toString()} disabled={!(item.options?.editable ?? true) || (item.options?.disabled as boolean)} />
                    </FormItem>
                );
            },
        },
        'date-range': {
            view: (item: FormItemsBuilderItem) => {
                const range = item.value;

                // Check if the range is valid and consists of two dates
                const isValidRange = range != null && Array.isArray(range) && range.length === 2 && range[0] != null && range[1] != null;

                return (
                    <span>
                        {isValidRange ? (
                            <>
                                <SmartDate date={moment(range[0])} /> <SwapRightOutlined /> <SmartDate date={moment(range[1])} />
                            </>
                        ) : (
                            defaultText
                        )}
                    </span>
                );
            },
            edit: (item: FormItemsBuilderItem) => {
                const range = item.value != null && Array.isArray(item.value) ? item.value.map((date) => moment(date)) : [null, null];

                return (
                    <FormItem
                        name={item.property}
                        tooltip={item?.options?.tooltip}
                        rules={
                            item?.options?.rules ?? [
                                {
                                    required: item?.options?.required ?? false,
                                    message: i18next.t('COMPONENTS.FORM_ITEMS_BUILDER.RULE_FAILED_REQUIRED'),
                                },
                            ]
                        }
                    >
                        <InputDateRange
                            id={item.property?.toString()}
                            value={[range[0] ?? null, range[1] ?? null]}
                            disabled={!(item.options?.editable ?? true) || (item.options?.disabled as boolean)}
                        />
                    </FormItem>
                );
            },
        },

        title: {
            view: (item: FormItemsBuilderItem) => {
                return <FormTitleField label={item.label} />;
            },
            edit: (item: FormItemsBuilderItem) => {
                return <FormTitleField label={item.label} />;
            },
        },
        divider: {
            view: (item: FormItemsBuilderItem) => {
                return (
                    <Divider orientation="left" plain>
                        {item.label}
                    </Divider>
                );
            },
            edit: (item: FormItemsBuilderItem) => {
                return (
                    <Divider orientation="left" plain>
                        {item.label}
                    </Divider>
                );
            },
        },

        'dropdown-multiple': {
            view: (item: FormItemsBuilderItem) => {
                if (item.value == null || item.value === '') {
                    return defaultText;
                }
                const values = (item.value as string[]) ?? [];
                return item.options?.render != null ? values.map((value) => item.options?.render?.(value, FormItemsBuilderItemMode.VIEW)) : values.join(', ');
            },
            edit: (item: FormItemsBuilderItem) => {
                const render = item.options?.render ?? ((value, mode) => value);
                const choices = (item?.options?.choices ?? []).map((value) => ({
                    label: render(value, FormItemsBuilderItemMode.EDIT),
                    value,
                    disabled: (typeof item.options?.disabled === 'function' ? item.options?.disabled?.(value) : item.options?.disabled) ?? false,
                }));
                return (
                    <FormItem
                        name={item.property}
                        tooltip={item?.options?.tooltip}
                        rules={
                            item?.options?.rules ?? [
                                {
                                    required: item?.options?.required ?? false,
                                    message: i18next.t('COMPONENTS.FORM_ITEMS_BUILDER.RULE_FAILED_REQUIRED'),
                                },
                            ]
                        }
                    >
                        <InputDropdownMultiple
                            id={item?.property?.toString()}
                            choices={choices}
                            disabled={!(item.options?.editable ?? true) || (item.options?.disabled as boolean)}
                        />
                    </FormItem>
                );
            },
        },

        'dropdown-single': {
            view: (item: FormItemsBuilderItem) => {
                const render = item.options?.render ?? ((value, mode) => value);
                return item.value == null || item.value === '' //
                    ? defaultText
                    : render(item.value, FormItemsBuilderItemMode.VIEW);
            },
            edit: (item: FormItemsBuilderItem) => {
                const render = item.options?.render ?? ((value, mode) => value);
                const choices = (item?.options?.choices ?? []).map((value) => ({
                    label: render(value, FormItemsBuilderItemMode.EDIT),
                    value,
                    disabled: (typeof item.options?.disabled === 'function' ? item.options?.disabled?.(value) : item.options?.disabled) ?? false,
                }));
                return (
                    <FormItem
                        name={item.property}
                        tooltip={item?.options?.tooltip}
                        rules={
                            item?.options?.rules ?? [
                                {
                                    required: item?.options?.required ?? false,
                                    message: i18next.t('COMPONENTS.FORM_ITEMS_BUILDER.RULE_FAILED_REQUIRED'),
                                },
                            ]
                        }
                    >
                        <InputDropdownSingle
                            id={item?.property?.toString()}
                            choices={choices}
                            disabled={!(item.options?.editable ?? true) || (item.options?.disabled as boolean)}
                        />
                    </FormItem>
                );
            },
        },
        'choices-editable': {
            view: (item: FormItemsBuilderItem) => {
                const render = item.options?.render ?? ((value, mode) => value);
                return item.value == null || item.value === ''
                    ? defaultText
                    : Array.isArray(item.value)
                      ? item.value.map((val) => render(val, FormItemsBuilderItemMode.VIEW)).join(', ')
                      : render(item.value, FormItemsBuilderItemMode.VIEW);
            },

            edit: (item: FormItemsBuilderItem) => {
                const render = item.options?.render ?? ((value, mode) => value);
                const choices = (item?.options?.choices ?? []).map((value) => ({
                    label: render(value, FormItemsBuilderItemMode.EDIT),
                    value,
                    disabled: (typeof item.options?.disabled === 'function' ? item.options?.disabled?.(value) : item.options?.disabled) ?? false,
                }));
                return (
                    <FormItem
                        name={item.property}
                        tooltip={item?.options?.tooltip}
                        rules={
                            item?.options?.rules ?? [
                                {
                                    required: item?.options?.required ?? false,
                                    message: i18next.t('COMPONENTS.FORM_ITEMS_BUILDER.RULE_FAILED_REQUIRED'),
                                },
                            ]
                        }
                    >
                        <CustomInputDropdown
                            id={item?.property?.toString()}
                            choices={choices}
                            disabled={!(item.options?.editable ?? true) || (item.options?.disabled as boolean)}
                        />
                    </FormItem>
                );
            },
        },

        'file-avatar': {
            view: (item: FormItemsBuilderItem) => {
                return <FileAvatar className="simple-form-special-file-avatar" fileId={item.value as string} size={64} showPreview />;
            },
            edit: (item: FormItemsBuilderItem) => {
                return (
                    <FormItem
                        name={item.property}
                        tooltip={item?.options?.tooltip}
                        rules={
                            item?.options?.rules ?? [
                                {
                                    required: item?.options?.required ?? false,
                                    message: i18next.t('COMPONENTS.FORM_ITEMS_BUILDER.RULE_FAILED_REQUIRED'),
                                },
                            ]
                        }
                    >
                        <InputOneFileUploader
                            id={item.property?.toString()}
                            type={item.options?.type as any}
                            acceptedTypes={item.options?.acceptedTypes}
                            disabled={!(item.options?.editable ?? true) || (item.options?.disabled as boolean)}
                        />
                    </FormItem>
                );
            },
        },

        'file-carousel': {
            view: (item: FormItemsBuilderItem) => {
                const values = (item.value as string[]) ?? [];
                return (
                    <FileCarousel //
                        fileIds={[...new Set(values)]}
                        className="simple-form-value-file-carousel"
                        showFileCount={item?.options?.showFileCount}
                        showPreview
                    />
                );
            },
            edit: (item: FormItemsBuilderItem) => {
                return (
                    <FormItem
                        name={item.property}
                        tooltip={item?.options?.tooltip}
                        rules={
                            item?.options?.rules ?? [
                                {
                                    required: item?.options?.required ?? false,
                                    message: i18next.t('COMPONENTS.FORM_ITEMS_BUILDER.RULE_FAILED_REQUIRED'),
                                },
                            ]
                        }
                    >
                        <InputMultipleFileUploader
                            id={item.property?.toString()}
                            type={item.options?.type as any}
                            acceptedTypes={item.options?.acceptedTypes}
                            disabled={!(item.options?.editable ?? true) || (item.options?.disabled as boolean)}
                        />
                    </FormItem>
                );
            },
        },

        'file-downloader': {
            view: (item: FormItemsBuilderItem) => {
                const values = (item.value as string[]) ?? [];
                return <FileDownloader fileIds={[...new Set(values)]} showHeader={false} showFileCount={item?.options?.showFileCount} />;
            },
            edit: (item: FormItemsBuilderItem) => {
                return (
                    <FormItem
                        name={item.property}
                        tooltip={item?.options?.tooltip}
                        rules={
                            item?.options?.rules ?? [
                                {
                                    required: item?.options?.required ?? false,
                                    message: i18next.t('COMPONENTS.FORM_ITEMS_BUILDER.RULE_FAILED_REQUIRED'),
                                },
                            ]
                        }
                    >
                        <InputMultipleFileUploader
                            id={item.property?.toString()}
                            type={item.options?.type as any}
                            acceptedTypes={item.options?.acceptedTypes}
                            disabled={!(item.options?.editable ?? true) || (item.options?.disabled as boolean)}
                        />
                    </FormItem>
                );
            },
        },

        'file-image': {
            view: (item: FormItemsBuilderItem) => {
                return <FileImage className="simple-form-special-file-image" fileId={item.value as string} size={100} showPreview />;
            },
            edit: (item: FormItemsBuilderItem) => {
                return (
                    <FormItem
                        name={item.property}
                        tooltip={item?.options?.tooltip}
                        rules={
                            item?.options?.rules ?? [
                                {
                                    required: item?.options?.required ?? false,
                                    message: i18next.t('COMPONENTS.FORM_ITEMS_BUILDER.RULE_FAILED_REQUIRED'),
                                },
                            ]
                        }
                    >
                        <InputOneFileUploader
                            id={item.property?.toString()}
                            type={item.options?.type as any}
                            acceptedTypes={item.options?.acceptedTypes}
                            disabled={!(item.options?.editable ?? true) || (item.options?.disabled as boolean)}
                        />
                    </FormItem>
                );
            },
        },

        language: {
            view: (item: FormItemsBuilderItem) => {
                return displayLanguage(item.value);
            },
            edit: (item: FormItemsBuilderItem) => {
                return (
                    <FormItem
                        name={item.property}
                        tooltip={item?.options?.tooltip}
                        rules={
                            item?.options?.rules ?? [
                                {
                                    required: item?.options?.required ?? false,
                                    message: i18next.t('COMPONENTS.FORM_ITEMS_BUILDER.RULE_FAILED_REQUIRED'),
                                },
                            ]
                        }
                    >
                        <InputLanguage
                            //
                            id={item.property?.toString()}
                            disabled={!(item.options?.editable ?? true) || (item.options?.disabled as boolean)}
                        />
                    </FormItem>
                );
            },
        },

        number: {
            view: (item: FormItemsBuilderItem) => {
                return item.value ?? 0;
            },
            edit: (item: FormItemsBuilderItem) => {
                return (
                    <FormItem
                        name={item.property}
                        tooltip={item?.options?.tooltip}
                        rules={
                            item?.options?.rules ?? [
                                {
                                    required: item?.options?.required ?? false,
                                    message: i18next.t('COMPONENTS.FORM_ITEMS_BUILDER.RULE_FAILED_REQUIRED'),
                                },
                            ]
                        }
                    >
                        <InputNumber
                            id={item.property?.toString()}
                            min={(item as any)?.options?.min ?? 0}
                            max={(item as any)?.options?.max ?? undefined}
                            width="100%"
                            disabled={!(item.options?.editable ?? true) || (item.options?.disabled as boolean)}
                        />
                    </FormItem>
                );
            },
        },

        product: {
            view: (item: FormItemsBuilderItem) => {
                return <ProductInfo id={item.value as string} showImage showName showDescription />;
            },
            edit: (item: FormItemsBuilderItem) => {
                return (
                    <FormItem
                        name={item.property}
                        tooltip={item?.options?.tooltip}
                        rules={
                            item?.options?.rules ?? [
                                {
                                    required: item?.options?.required ?? false,
                                    message: i18next.t('COMPONENTS.FORM_ITEMS_BUILDER.RULE_FAILED_REQUIRED'),
                                },
                            ]
                        }
                    >
                        <InputProduct
                            //
                            id={item.property?.toString()}
                            disabled={!(item.options?.editable ?? true) || (item.options?.disabled as boolean)}
                            addNew={item?.options?.addNew}
                            query={item?.options?.query}
                        />
                    </FormItem>
                );
            },
        },

        workspace: {
            view: (item: FormItemsBuilderItem) => {
                return <WorkspaceInfo id={item.value as string} />;
            },
            edit: (item: FormItemsBuilderItem) => {
                return (
                    <FormItem
                        name={item.property}
                        tooltip={item?.options?.tooltip}
                        rules={
                            item?.options?.rules ?? [
                                {
                                    required: item?.options?.required ?? false,
                                    message: i18next.t('COMPONENTS.FORM_ITEMS_BUILDER.RULE_FAILED_REQUIRED'),
                                },
                            ]
                        }
                    >
                        <InputWorkspace
                            //
                            id={item.property?.toString()}
                            disabled={!(item.options?.editable ?? true) || (item.options?.disabled as boolean)}
                            addNew={item?.options?.addNew}
                        />
                    </FormItem>
                );
            },
        },

        tags: {
            view: (item: FormItemsBuilderItem) => {
                const render = item.options?.render ?? ((tag, mode) => <Tag>{tag}</Tag>);
                const values = (item.value as string[]) ?? [];
                return values.length === 0 ? '-' : values.map((tag) => render(tag, FormItemsBuilderItemMode.VIEW));
            },
            edit: (item: FormItemsBuilderItem) => {
                const render = item.options?.render ?? ((tag, mode) => <Tag>{tag}</Tag>);
                const choices = (item?.options?.choices ?? []).map((value) => ({
                    label: render(value, FormItemsBuilderItemMode.EDIT),
                    value,
                    disabled: (typeof item.options?.disabled === 'function' ? item.options?.disabled?.(value) : item.options?.disabled) ?? false,
                }));
                return (
                    <FormItem
                        name={item.property}
                        tooltip={item?.options?.tooltip}
                        rules={
                            item?.options?.rules ?? [
                                {
                                    required: item?.options?.required ?? false,
                                    message: i18next.t('COMPONENTS.FORM_ITEMS_BUILDER.RULE_FAILED_REQUIRED'),
                                },
                            ]
                        }
                    >
                        <Select
                            id={item.property?.toString()}
                            mode="tags"
                            options={choices}
                            tokenSeparators={[',', ' ']}
                            disabled={!(item.options?.editable ?? true) || (item.options?.disabled as boolean)}
                        />
                    </FormItem>
                );
            },
        },

        'text-area': {
            view: (item: FormItemsBuilderItem) => {
                if (typeof item.value === 'string' || item.value == null) {
                    const value = (item.value ?? '-')?.trim();
                    return <ReadMore html={value.length === 0 ? '-' : value} />;
                }
                return item.value;
            },
            edit: (item: FormItemsBuilderItem) => {
                return (
                    <FormItem
                        name={item.property}
                        tooltip={item?.options?.tooltip}
                        rules={
                            item?.options?.rules ?? [
                                { required: item?.options?.required ?? false, message: i18next.t('COMPONENTS.FORM_ITEMS_BUILDER.RULE_FAILED_REQUIRED') },
                                { min: 2, message: i18next.t('COMPONENTS.FORM_ITEMS_BUILDER.RULE_FAILED_MIN', { count: 2 }) },
                                { max: 20000, message: i18next.t('COMPONENTS.FORM_ITEMS_BUILDER.RULE_FAILED_MAX', { count: 20000 }) },
                                ...(item?.options?.rules ?? []),
                            ]
                        }
                    >
                        <Input.TextArea //
                            id={item.property?.toString()}
                            placeholder={item.options?.placeholder}
                            rows={4}
                            disabled={!(item.options?.editable ?? true) || (item.options?.disabled as boolean)}
                            allowClear
                        />
                    </FormItem>
                );
            },
        },

        'text-email': {
            view: (item: FormItemsBuilderItem) => {
                return (
                    <>
                        <MailOutlined style={{ paddingRight: 7 }} />
                        {item.value == null || item.value === '' ? defaultText : <a href={`mailto:${item.value ?? ''}`}>{item.value}</a>}
                    </>
                );
            },
            edit: (item: FormItemsBuilderItem) => {
                return (
                    <FormItem
                        name={item.property}
                        tooltip={item?.options?.tooltip}
                        className={item?.options?.className}
                        rules={
                            item?.options?.rules ?? [
                                { type: 'email', message: i18next.t('COMPONENTS.FORM_ITEMS_BUILDER.RULE_INVALID_EMAIL') },
                                { required: item?.options?.required ?? false, message: i18next.t('COMPONENTS.FORM_ITEMS_BUILDER.RULE_FAILED_REQUIRED') },
                                { min: 2, message: i18next.t('COMPONENTS.FORM_ITEMS_BUILDER.RULE_FAILED_MIN', { count: 2 }) },
                                { max: 200, message: i18next.t('COMPONENTS.FORM_ITEMS_BUILDER.RULE_FAILED_MAX', { count: 200 }) },
                                ...(item?.options?.rules ?? []),
                            ]
                        }
                    >
                        <Input
                            id={item.property?.toString()}
                            type="email"
                            placeholder={item.options?.placeholder}
                            disabled={!(item.options?.editable ?? true) || (item.options?.disabled as boolean)}
                            allowClear
                            prefix={item?.options?.prefix}
                        />
                    </FormItem>
                );
            },
        },
        'text-email-multiple': {
            view: (item: FormItemsBuilderItem) => {
                const render = item.options?.render ?? ((tag, mode) => <Tag>{tag}</Tag>);
                const values = (item.value as string[]) ?? [];
                return values.length === 0 ? '-' : values.map((tag) => render(tag, FormItemsBuilderItemMode.VIEW));
            },
            edit: (item: FormItemsBuilderItem) => {
                const render = item.options?.render ?? ((tag, mode) => <Tag>{tag}</Tag>);
                const choices = (item?.options?.choices ?? []).map((value) => ({
                    label: render(value, FormItemsBuilderItemMode.EDIT),
                    value,
                    disabled: (typeof item.options?.disabled === 'function' ? item.options?.disabled?.(value) : item.options?.disabled) ?? false,
                }));
                return (
                    <FormItem
                        name={item.property}
                        tooltip={item?.options?.tooltip}
                        rules={[
                            {
                                validator: (_: any, value: string[]) => {
                                    const re =
                                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                                    const invalidEmail = value != null && value.length > 0 ? value?.find((email) => !re.test(email.toLowerCase())) : null;
                                    return invalidEmail == null ? Promise.resolve() : Promise.reject(new Error());
                                },
                                message: i18next.t('COMPONENTS.FORM_ITEMS_BUILDER.RULE_INVALID_EMAIL'),
                            },
                            { required: item?.options?.required ?? false, message: i18next.t('COMPONENTS.FORM_ITEMS_BUILDER.RULE_FAILED_REQUIRED') },
                            ...(item?.options?.rules ?? []),
                        ]}
                    >
                        <Select
                            id={item.property?.toString()}
                            mode="tags"
                            options={choices}
                            tokenSeparators={[',', ' ']}
                            disabled={!(item.options?.editable ?? true) || (item.options?.disabled as boolean)}
                        />
                    </FormItem>
                );
            },
        },

        'text-phone': {
            view: (item: FormItemsBuilderItem) => {
                const phone = item.value != null ? `+${item.value}` : typeof defaultText === 'string' ? defaultText : '';
                return (
                    <>
                        <PhoneOutlined style={{ paddingRight: 7 }} rotate={90} />
                        {item.value == null || item.value === '' ? defaultText : <a href={`tel:${phone}`}>{phone}</a>}
                    </>
                );
            },
            edit: (item: FormItemsBuilderItem) => {
                return (
                    <FormItem
                        name={item.property}
                        tooltip={item?.options?.tooltip}
                        rules={
                            item?.options?.rules ?? [
                                //
                                { type: 'string' },
                                { required: item?.options?.required ?? false, message: i18next.t('COMPONENTS.FORM_ITEMS_BUILDER.RULE_FAILED_REQUIRED') },
                                { min: 2, message: i18next.t('COMPONENTS.FORM_ITEMS_BUILDER.RULE_FAILED_MIN', { count: 2 }) },
                                { max: 200, message: i18next.t('COMPONENTS.FORM_ITEMS_BUILDER.RULE_FAILED_MAX', { count: 200 }) },
                                ...(item?.options?.rules ?? []),
                            ]
                        }
                    >
                        <InputPhoneNumber disabled={!(item.options?.editable ?? true) || (item.options?.disabled as boolean)} />
                    </FormItem>
                );
            },
        },

        'text-provider': {
            view: (item: FormItemsBuilderItem) => {
                const provider = ((item.value as string[]) ?? []).join(', ');
                return provider == null || provider === '' ? defaultText : provider;
            },
            edit: (item: FormItemsBuilderItem) => {
                return (
                    <FormItem
                        name={item.property}
                        tooltip={item?.options?.tooltip}
                        // rules={
                        //     item?.options?.rules ?? [
                        //         //
                        //         { type: 'string' },
                        //         { required: item?.options?.required ?? false, message: t('COMPONENTS.FORM_ITEMS_BUILDER.RULE_FAILED_REQUIRED') },
                        //         { min: 2, message: t('COMPONENTS.FORM_ITEMS_BUILDER.RULE_FAILED_MIN', { count: 2 }) },
                        //         { max: 200, message: t('COMPONENTS.FORM_ITEMS_BUILDER.RULE_FAILED_MAX', { count: 200 }) },
                        //         ...(item?.options?.rules ?? []),
                        //     ]
                        // }
                    >
                        <InputProviders id={item.property?.toString()} disabled={!(item.options?.editable ?? true) || (item.options?.disabled as boolean)} />
                    </FormItem>
                );
            },
        },

        'text-url': {
            view: (item: FormItemsBuilderItem) => {
                return (
                    <>
                        <GlobalOutlined style={{ paddingRight: 7 }} />
                        {item.value == null || item.value === '' ? (
                            defaultText
                        ) : (
                            <a href={item.value} target="_blank" rel="noopener noreferrer">
                                {item.value}
                            </a>
                        )}
                    </>
                );
            },
            edit: (item: FormItemsBuilderItem) => {
                return (
                    <FormItem
                        name={item.property}
                        tooltip={item?.options?.tooltip}
                        rules={
                            item?.options?.rules ?? [
                                //
                                { type: 'url' },
                                { required: item?.options?.required ?? false, message: i18next.t('COMPONENTS.FORM_ITEMS_BUILDER.RULE_FAILED_REQUIRED') },
                                { min: 2, message: i18next.t('COMPONENTS.FORM_ITEMS_BUILDER.RULE_FAILED_MIN', { count: 2 }) },
                                { max: 300, message: i18next.t('COMPONENTS.FORM_ITEMS_BUILDER.RULE_FAILED_MAX', { count: 300 }) },
                                {
                                    validator: (_: any, value: string) => {
                                        const link = value?.trim();
                                        return link == null || link.length === 0 || link.startsWith('http://') || link.startsWith('https://')
                                            ? Promise.resolve()
                                            : Promise.reject(i18next.t('COMPONENTS.FORM_ITEMS_BUILDER.RULE_FAILED_HTTP_REQUIRED'));
                                    },
                                },
                                ...(item?.options?.rules ?? []),
                            ]
                        }
                    >
                        <Input
                            id={item.property?.toString()}
                            type="text"
                            placeholder={item.options?.placeholder}
                            disabled={!(item.options?.editable ?? true) || (item.options?.disabled as boolean)}
                            allowClear
                            prefix={item?.options?.prefix}
                        />
                    </FormItem>
                );
            },
        },

        text: {
            view: (item: FormItemsBuilderItem) => {
                return (item.value != null && item.value !== '') || item.options?.prefix != null ? (
                    <>
                        {item?.options?.prefix} {item.value}
                    </>
                ) : (
                    defaultText
                );
            },
            edit: (item: FormItemsBuilderItem) => {
                return (
                    <FormItem
                        name={item.property}
                        tooltip={item?.options?.tooltip}
                        rules={
                            item?.options?.rules ?? [
                                //
                                { type: 'string' },
                                { required: item?.options?.required ?? false, message: i18next.t('COMPONENTS.FORM_ITEMS_BUILDER.RULE_FAILED_REQUIRED') },
                                { min: 2, message: i18next.t('COMPONENTS.FORM_ITEMS_BUILDER.RULE_FAILED_MIN', { count: 2 }) },
                                { max: 20000, message: i18next.t('COMPONENTS.FORM_ITEMS_BUILDER.RULE_FAILED_MAX', { count: 20000 }) },
                                ...(item?.options?.rules ?? []),
                            ]
                        }
                    >
                        <Input
                            id={item.property?.toString()}
                            type="text"
                            placeholder={item.options?.placeholder}
                            disabled={!(item.options?.editable ?? true) || (item.options?.disabled as boolean)}
                            allowClear
                            prefix={item?.options?.prefix}
                        />
                    </FormItem>
                );
            },
        },

        toggle: {
            view: (item: FormItemsBuilderItem) => {
                return <Switch disabled checked={item.value} />;
            },
            edit: (item: FormItemsBuilderItem) => {
                return (
                    <FormItem
                        name={item.property}
                        tooltip={item?.options?.tooltip}
                        rules={
                            item?.options?.rules ?? [
                                {
                                    required: item?.options?.required ?? false,
                                    message: i18next.t('COMPONENTS.FORM_ITEMS_BUILDER.RULE_FAILED_REQUIRED'),
                                },
                            ]
                        }
                    >
                        <InputSwitch
                            //
                            id={item.property?.toString()}
                            disabled={!(item.options?.editable ?? true) || (item.options?.disabled as boolean)}
                        />
                    </FormItem>
                );
            },
        },
    };
    return kit;
};
