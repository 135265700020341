import { useTranslation } from 'react-i18next';
import { type Certification } from '@/utils/cyberpass';
import { useCyberpassWorkspaceLookupById } from '@/query';
import { UserCompanyInfo } from '@/components/UserCompanyInfo';
import CyberpassLogoCpDark from '@/assets/images/cyberpass-logo-cp-dark.svg';

type CertificationLabInfoProps = {
    certification: Certification;
};

/** shows minimal information about a lab (if exists for certification); ideal to be embedded into a table row */
export const CertificationLabInfo: React.FC<CertificationLabInfoProps> = ({ certification }) => {
    const { t } = useTranslation();
    const { data: reviewerWorkspace } = useCyberpassWorkspaceLookupById(certification?.reviewerWorkspaceId);

    if (certification?.reviewerWorkspaceId == null) {
        return (
            <>
                <img src={CyberpassLogoCpDark} /> {t('SHARED.CYBERPASS')}
            </>
        );
    }
    return <UserCompanyInfo id={reviewerWorkspace?.creatorUserId} />;
};
