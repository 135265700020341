import i18next from '@/utils/i18next';
import { type User } from '@/utils/cyberpass';
import { type FormItemsBuilderItems } from '@/components/FormItemsBuilder';
import { FileUploaderType } from '@/components/FileUploader';
import { SupportedMimeType } from '@/types/file';

// TODO this is a combination of UserCompanyDetailsForm:form.tsx and UserCompanyPresentationForm:form.tsx
export const buildUserCompanyPresentationFormItems = (user: User | undefined): FormItemsBuilderItems => {
    return [
        {
            label: i18next.t('COMPONENTS.COMPANY_DETAILS_FORM.LOGO'),
            type: 'file-image',
            property: ['company', 'logoFileId'],
            value: user?.company?.logoFileId,
            options: {
                type: FileUploaderType.IMAGE,
                acceptedTypes: [SupportedMimeType.ALL_IMAGE_TYPES],
            },
        },
        {
            label: i18next.t('COMPONENTS.COMPANY_DETAILS_FORM.NAME'),
            type: 'text',
            property: ['company', 'name'],
            value: user?.company?.name,
            options: {
                required: true,
            },
        },
        {
            label: i18next.t('COMPONENTS.COMPANY_DETAILS_FORM.REGISTRATION_ID'),
            type: 'text',
            property: ['company', 'registrationIdNumber'],
            value: user?.company?.registrationIdNumber,
        },
        {
            label: i18next.t('COMPONENTS.COMPANY_DETAILS_FORM.ADDRESS'),
            type: 'address',
            property: ['company', 'address'],
            value: user?.company?.address,
        },
        {
            type: 'divider',
        },
        {
            label: i18next.t('COMPONENTS.COMPANY_DETAILS_FORM.WEBSITE'),
            type: 'text-url',
            property: ['company', 'url'],
            value: user?.company?.url,
        },
        {
            label: i18next.t('COMPONENTS.COMPANY_DETAILS_FORM.EMAIL'),
            type: 'text-email',
            property: ['company', 'email'],
            value: user?.company?.email,
        },
        {
            label: i18next.t('COMPONENTS.COMPANY_DETAILS_FORM.PHONE'),
            type: 'text-phone',
            property: ['company', 'phoneNumber'],
            value: user?.company?.phoneNumber,
        },
        {
            type: 'divider',
        },
        {
            label: i18next.t('COMPONENTS.COMPANY_DETAILS_FORM.ABOUT'),
            type: 'text-area',
            property: ['company', 'about'],
            value: user?.company?.about,
        },
        {
            type: 'divider',
        },
        {
            label: i18next.t('COMPONENTS.COMPANY_DOCUMENTS_FORM.BANNERS'),
            type: 'file-carousel',
            property: ['company', 'bannerFileIds'],
            value: user?.company?.bannerFileIds,
            options: {
                type: FileUploaderType.IMAGE,
                acceptedTypes: [SupportedMimeType.ALL_IMAGE_TYPES],
            },
        },
        {
            label: i18next.t('COMPONENTS.COMPANY_DOCUMENTS_FORM.BROCHURES'),
            type: 'file-downloader',
            property: ['company', 'bannerFileIds'],
            value: user?.company?.bannerFileIds,
            options: {
                type: FileUploaderType.FILE,
                acceptedTypes: [SupportedMimeType.ALL_TYPES],
            },
        },
    ];
};
