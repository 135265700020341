import { EvaluationFlowStatus } from '@/utils/evaluation';

export const colorClassNames: Record<EvaluationFlowStatus, string> = {
    [EvaluationFlowStatus.CERTIFIED]: 'tag-green',
    [EvaluationFlowStatus.SUBMITTED]: 'tag-cyan',
    [EvaluationFlowStatus.REVIEWING]: 'tag-volcano',
    [EvaluationFlowStatus.INCONCLUSIVE]: 'tag-red',
    [EvaluationFlowStatus.ONGOING]: 'tag-orange',
    [EvaluationFlowStatus.FAILED]: 'tag-red',
};
