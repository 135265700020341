import { useCyberpassConfigurationLookupByRef } from '@/query';
import { type ConfigurationDefinition, type ConfigurationType } from './cyberpass';

// TODO move to /hooks
export const useActiveConfigurationDefinition = <T extends ConfigurationType>(ref: string | undefined, type?: T) => {
    const { data: configuration, isLoading } = useCyberpassConfigurationLookupByRef(ref, { onlyActive: true });

    if (isLoading) {
        return { data: undefined, isLoading: true };
    }

    if (type != null && configuration?.type !== type) {
        return { data: undefined, isLoading: false };
    }

    return { data: configuration?.definition as ConfigurationDefinition<T> | undefined, isLoading: false };
};
