import type React from 'react';
import { useTranslation } from 'react-i18next';
import { HddOutlined } from '@ant-design/icons';
import { type User } from '@/utils/cyberpass';
import { FormItemsBuilder } from '@/components/FormItemsBuilder';
import { SimpleForm, type SimpleFormPropsFactory } from '@/components/SimpleForm';
import { buildUserCompanyPresentationFormItems } from './form';

type UserCompanyPresentationFormProps = SimpleFormPropsFactory<'user', User>;

export const UserCompanyPresentationForm: React.FC<UserCompanyPresentationFormProps> = ({ user, editable, onSubmit, onSuccess, onCancel, onError }) => {
    const { t } = useTranslation();
    const items = buildUserCompanyPresentationFormItems(user);

    const handleSubmit = (values: User, done: () => void) => {
        // log.error('this form does not support `editable=true` yet...');
        done();
        // throw new Error('unsupported');
    };

    return (
        <SimpleForm<User> //
            initialValues={user}
            editable={editable}
            submitLabel={t('COMPONENTS.COMPANY_DOCUMENTS_FORM.BUTTON_SUBMIT')}
            onSubmit={handleSubmit}
            onCancel={onCancel}
        >
            <FormItemsBuilder //
                items={items}
                defaultImage={<HddOutlined />}
                editable={editable}
            />
        </SimpleForm>
    );
};
