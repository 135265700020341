import type React from 'react';
import Red1Intro from '@/assets/images/red-1-intro.svg';
import './styles.css';

type NoEntriesInfoProps = {
    fact: string;
    image?: string;
    message: string;
    action?: JSX.Element;
    showShort?: boolean;
    className?: string;
};

export const NoEntriesInfo: React.FC<NoEntriesInfoProps> = ({ fact, image, message, action, showShort = false, className = '' }) => {
    return showShort ? (
        <i className={`${className} no-entries-action-content`}>{fact}</i>
    ) : (
        <div className={`${className} no-entries-action-content-container`}>
            <img src={image ?? Red1Intro} className="no-entries-action-content-image" />
            <div className="no-entries-action-content-message">{message}</div>
            {action != null && <div className="no-entries-action-content-action">{action}</div>}
        </div>
    );
};
