import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import './styles.css';

type ReadMoreProps = {
    html?: string;
    className?: string;
    buttonClassName?: string;
};

export const ReadMore = ({ html, className, buttonClassName }: ReadMoreProps) => {
    // the correct wording seems to be "line clamping";
    // we might find dedicated libraries for that; see https://css-tricks.com/line-clampin/

    const { t } = useTranslation();
    const textRef = useRef(null);
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const [isTextClamped, setIsTextClamped] = useState<boolean>(false);

    const toggleText = (event: any) => {
        event.preventDefault();
        setIsExpanded((previous) => !previous);
    };

    const updateClamping = () => {
        if (html == null) {
            return;
        }
        const scrollHeight = (textRef.current as any)?.scrollHeight ?? 0;
        const clientHeight = (textRef.current as any)?.clientHeight ?? 0;

        setIsTextClamped(scrollHeight > clientHeight);

        const lineHeight = textRef.current != null ? parseInt(window.getComputedStyle(textRef.current).lineHeight) : 0;
        const lineCount = Math.ceil(clientHeight / lineHeight);

        if (lineCount > 4) {
            setIsExpanded(true);
        } else {
            setIsExpanded(false);
        }
    };

    useEffect(() => {
        if (textRef.current == null) return;
        const resizeObserver = new ResizeObserver(() => {
            updateClamping();
        });
        resizeObserver.observe(textRef.current);
        // clean up
        return () => {
            resizeObserver.disconnect();
        };
    }, []);

    useEffect(() => {
        updateClamping();
    }, [html]);

    return (
        <>
            <div className={`read-more ${className ?? ''}`}>
                <p ref={textRef} className={`${isExpanded ? 'read-more-expanded' : 'read-more-collapsed'}`} dangerouslySetInnerHTML={{ __html: html ?? '-' }} />
            </div>
            {isTextClamped || isExpanded ? (
                <div className={`read-more-button ${buttonClassName ?? ''}`} onClick={toggleText}>
                    {isExpanded ? t('COMPONENTS.READ_MORE.READ_LESS') : t('COMPONENTS.READ_MORE.READ_MORE')}
                </div>
            ) : null}
        </>
    );
};
