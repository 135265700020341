import type React from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Col, Row, Button } from 'antd';
import { type Certification } from '@/utils/cyberpass';
import { TrophyOutlined } from '@ant-design/icons';
import Rocket from '@/assets/images/rocket.svg';
import './styles.css';

type RecertifySectionProps = {
    certification: Certification | undefined;
    style?: React.CSSProperties;
};

export const RecertifySection: React.FC<RecertifySectionProps> = ({ certification, style }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    return (
        <Row align="middle" wrap={false} className="result-page-recertify-section-row" style={style}>
            <Col className="result-page-recertify-section-image-col">
                <div>
                    <img src={Rocket} alt="" style={{ width: 72, height: 78 }} />
                </div>
            </Col>
            <Col className="result-page-recertify-section-row">
                <Row>
                    <Col>
                        <span dangerouslySetInnerHTML={{ __html: t('PAGES.RESULT.RECERTIFY_SECTION.DESCRIPTION') }} />
                    </Col>
                    <Col span={24} className="result-page-recertify-section-button-col">
                        <Button
                            icon={<TrophyOutlined />}
                            size="small"
                            onClick={() => {
                                navigate('/certifications/new', {
                                    state: {
                                        productId: certification?.productId,
                                        schemeId: certification?.schemeId,
                                        levelId: certification?.level,
                                        parentCertificationId: certification?.id,
                                    },
                                    replace: true,
                                });
                            }}
                        >
                            {t('PAGES.RESULT.RECERTIFY_SECTION.BUTTON')}
                        </Button>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};
