// TODO filip fix eslint disable
// TODO: Should the number rows be hardcoded or moved to a const (0, 25, 50, 75, 100)

import type React from 'react';
import { Col, Row } from 'antd';
import './style.css';

export enum RiskLevel {
    MINIMALISTIC = 'MINIMALISTIC',
    ADEQUATE = 'ADEQUATE',
    ROBUST = 'ROBUST',
    EXCEPTIONAL = 'EXCEPTIONAL',
}

export const getPercentageColor = (risk: number | RiskLevel) => {
    if (risk === RiskLevel.MINIMALISTIC || (!Number.isNaN(risk) && typeof risk === 'number' && risk < 26)) {
        return 'var(--red-7)';
    }
    if (risk === RiskLevel.ADEQUATE || (!Number.isNaN(risk) && typeof risk === 'number' && risk < 51)) {
        return 'var(--orange-6)';
    }
    if (risk === RiskLevel.ROBUST || (!Number.isNaN(risk) && typeof risk === 'number' && risk < 76)) {
        return 'var(--gold-5)';
    }
    return 'var(--green-7)';
};

export const getRiskLevelByPercentage = (percentage: number) => {
    if (percentage < 26) {
        return RiskLevel.MINIMALISTIC;
    }
    if (percentage < 51) {
        return RiskLevel.ADEQUATE;
    }
    if (percentage < 76) {
        return RiskLevel.ROBUST;
    }
    return RiskLevel.EXCEPTIONAL;
};

/**
 * Decreases the range of numbers slightly to move them away from the edges
 */
const getChevronPosition = (number: number) => {
    if (number === 0) {
        return '0%';
    }
    if (number < 26) {
        const interval = 23 / 25;
        return `${Math.floor(number * interval) + 1}%`; // Map the number to the range 1 to 23
    }
    if (number < 51) {
        const interval = 23 / 25;
        return `${Math.floor((number - 25) * interval) + 26}%`; // Map the number to the range 26 to 48
    }
    if (number < 76) {
        const interval = 23 / 25;
        return `${Math.floor((number - 50) * interval) + 51}%`; // Map the number to the range 51 to 73
    }
    if (number <= 100) {
        const interval = 23 / 25; // Increased to 25 due to including the max value (100)
        return `${Math.floor((number - 75) * interval) + 76}%`; // Map the number to the range 76 to 99
    }
    return '100%';
};

type PercentageBarProps = {
    percentage?: number;
    showNumbers?: boolean;
    thinBars?: boolean;
    roundedEdges?: boolean;
    style?: React.CSSProperties;
};

export const PercentageBar: React.FC<PercentageBarProps> = ({ percentage = 0, style, showNumbers = true, thinBars = false, roundedEdges = false }) => {
    const safePercentage = Math.round(Math.max(Math.min(percentage, 100), 0));
    const barStyle = {
        '--percentage-color': getPercentageColor(safePercentage),
        '--bar-height': thinBars ? '4px' : '18px',
        '--border-radius': roundedEdges ? '3px' : '0px',
        ...style,
    } as React.CSSProperties;

    return (
        <div style={{ ...barStyle }}>
            <Row>
                <div className="percentage-bar-chevron" style={{ '--chevron-position': getChevronPosition(safePercentage) } as React.CSSProperties}>
                    &#9660;
                </div>
            </Row>
            <Row className="percentage-bar-row">
                <Col className="percentage-bar-col" style={{ backgroundColor: getPercentageColor(RiskLevel.MINIMALISTIC) }} />
                <Col className="percentage-bar-col" style={{ backgroundColor: getPercentageColor(RiskLevel.ADEQUATE) }} />
                <Col className="percentage-bar-col" style={{ backgroundColor: getPercentageColor(RiskLevel.ROBUST) }} />
                <Col className="percentage-bar-col" style={{ backgroundColor: getPercentageColor(RiskLevel.EXCEPTIONAL) }} />
            </Row>
            {showNumbers && (
                <Row className="percentage-bar-numbers-row">
                    <div>0</div>
                    <div>25</div>
                    <div>50</div>
                    <div>75</div>
                    <div>100</div>
                </Row>
            )}
        </div>
    );
};
