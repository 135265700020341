import { useTranslation } from 'react-i18next';
import { Button, Select } from 'antd';
import { useCyberpassWorkspaceSearch } from '@/query';
import { QuerySortDirection, Workspace } from '@/utils/cyberpass';
import { WorkspaceInfo } from '@/components/WorkspaceInfo';
import { AddWorkspaceDrawer } from '@/components/AddWorkspaceDrawer';
import { useState } from 'react';
import './styles.css';

type InputWorkspaceProps = {
    id?: string;
    value?: string; // workspaceId
    onChange?: (value: string) => void;
    disabled?: boolean;
    size?: 'small' | 'middle' | 'large';
    addNew?: {
        allow: boolean;
        workspaceId?: string;
        initialValue?: any;
    };
};

export const InputWorkspace: React.FC<InputWorkspaceProps> = ({
    //
    id,
    value,
    onChange,
    disabled,
    size = 'middle',
    addNew,
}) => {
    const { t } = useTranslation();
    const [openAddWorkspaceDrawer, setOpenAddWorkspaceDrawer] = useState<boolean>(false);

    const { data: workspaces, isLoading: loading } = useCyberpassWorkspaceSearch({
        filters: {
            whereMember: true,
        },
        sortBy: [
            {
                field: 'createdAt',
                direction: QuerySortDirection.DESC,
            },
        ],
        pageSize: 1000,
    });

    const handleChange = (workspaceId: string) => {
        onChange?.(workspaceId);
    };

    return (
        <div className="input-workspace-container">
            <Select
                id={id}
                showSearch
                size={size}
                showArrow={false}
                disabled={disabled}
                placeholder={t('COMPONENTS.INPUT_WORKSPACE.INPUT_PLACEHOLDER')}
                loading={loading}
                onChange={handleChange}
                autoClearSearchValue
                allowClear
                value={null}
                options={(workspaces?.items ?? []).map((workspace: Workspace) => ({
                    label: <WorkspaceInfo id={workspace.id} />,
                    value: workspace.id,
                    searchValue: workspace.name,
                }))}
                dropdownRender={(menu) => {
                    return (
                        <div>
                            {addNew?.allow && (
                                <Button className="input-workspace-add-button" type="link" onClick={() => setOpenAddWorkspaceDrawer(true)}>
                                    {t('COMPONENTS.INPUT_WORKSPACE.ADD_NEW_WORKSPACE_LABEL')}
                                </Button>
                            )}
                            {menu}
                        </div>
                    );
                }}
                optionFilterProp="children"
                filterOption={(input, option) => (option?.searchValue ?? '').toLowerCase().includes(input.toLowerCase())}
            />

            {value != null && <WorkspaceInfo className="input-workspace-selected-container" id={value} />}

            <AddWorkspaceDrawer
                open={openAddWorkspaceDrawer}
                onClose={() => {
                    setOpenAddWorkspaceDrawer(false);
                }}
                onSuccess={(workspace: Workspace) => {
                    setOpenAddWorkspaceDrawer(false);
                    handleChange(workspace.id);
                }}
            />
        </div>
    );
};
