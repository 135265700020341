import logo from '@/assets/images/red-12-timeline.svg';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import './styles.css';

export const EmptyResultPage: React.FC = () => {
    const { t } = useTranslation();
    return (
        <div className="empty-result-page">
            <div className="empty-result-page-content">
                <div className="empty-result-page-content">
                    <img src={logo} alt="" />
                    <br />
                    <div>
                        <Typography.Text>{t('PAGES.EMPTY_RESULT_PAGE.TITLE')}</Typography.Text>
                        <br />
                        <Typography.Text>{t('PAGES.EMPTY_RESULT_PAGE.SUBTITLE')}</Typography.Text>
                    </div>
                </div>
            </div>
        </div>
    );
};
