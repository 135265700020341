import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Col } from 'antd';
import CyberpassSignImage from '@/assets/images/cyberpass-sign-image.svg';
import CertificateInstructionImage from '@/assets/images/certificate-instruction.svg';
import { CertificateSearchForm } from '@/components/CertificateSearchForm';

import './styles.css';

export const CertificateLookupSection = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <Col className="login-page-certificate-lookup-container" xl={16} lg={16} md={12} xs={24}>
            <div className="login-page-certificate-lookup-inner-container">
                <img src={CyberpassSignImage} alt="" style={{ maxHeight: 200, alignSelf: 'center', maxWidth: '100%' }} />
                <CertificateSearchForm
                    onSubmit={(certification) => {
                        navigate(`/certification/${certification.serialNumber}`);
                    }}
                    buttonLabel={t('PAGES.LOGIN.CERTIFICATE_LOOKUP_SECTION.BUTTON_SUBMIT')}
                    placeholder={t('PAGES.LOGIN.CERTIFICATE_LOOKUP_SECTION.INPUT_PLACEHOLDER')}
                />
                <h2>{t('PAGES.LOGIN.CERTIFICATE_LOOKUP_SECTION.TITLE')}</h2>
                <p>{t('PAGES.LOGIN.CERTIFICATE_LOOKUP_SECTION.DESCRIPTION')}</p>
                <img src={CertificateInstructionImage} alt="" style={{ maxHeight: 150, alignSelf: 'flex-start', maxWidth: '100%' }} />
            </div>
        </Col>
    );
};
