import { useTranslation } from 'react-i18next';
import France2030Logo from '@/assets/images/france-2030-logo.svg';
import './styles.css';

export const PublicFooter = () => {
    const { t } = useTranslation();
    return (
        <div className="public-footer">
            <div className="public-footer-logo-container">
                <img src={France2030Logo} className="public-footer-logo-image" />
            </div>
            <div className="public-footer-text-container">
                © 2017-{new Date().getFullYear()}, {t('COMPONENTS.FOOTER.RIGHTS_RESERVED')}
                <br />
                {t('COMPONENTS.FOOTER.BODY')}
            </div>
        </div>
    );
};
