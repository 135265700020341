import './app.css'; // This MUST be loaded first because of order and priority of imported css in whole app
import React from 'react';
import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { App } from './app';

Sentry.init({
    environment: import.meta.env.VITE_SERVER_ENV,
    dsn: 'https://87c19856ac9928a82f6aa9b717688499@o4508307444924416.ingest.de.sentry.io/4508307477561424',
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
        'localhost',
        /^https:\/\/api\.develop\.cyber-pass\.eu/,
        /^https:\/\/api\.staging\.cyber-pass\.eu/,
        /^https:\/\/api\.demo\.cyber-pass\.eu/,
        /^https:\/\/api\.cyber-pass\.eu/,
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </React.StrictMode>,
);
