import type React from 'react';
import { useTranslation } from 'react-i18next';
import { CustomDrawer } from '@/components/CustomDrawer';
import { WorkspaceForm } from '../WorkspaceForm';
import { Workspace } from '@/utils/cyberpass';

type AddWorkspaceDrawerProps = {
    open: boolean | undefined;
    onClose: () => void;
    onSuccess?: (workspace: Workspace) => void;
};

export const AddWorkspaceDrawer: React.FC<AddWorkspaceDrawerProps> = ({ open, onClose, onSuccess }) => {
    const { t } = useTranslation();

    return (
        <CustomDrawer title={t('COMPONENTS.ADD_WORKSPACE_DRAWER.TITLE')} onClose={onClose} open={open}>
            <WorkspaceForm
                //
                editable={true}
                workspace={undefined}
                onSuccess={onSuccess ?? onClose}
                onCancel={onClose}
            />
        </CustomDrawer>
    );
};
