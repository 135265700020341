import { useTranslation } from 'react-i18next';
import { Button, Dropdown, type MenuProps, Space } from 'antd';
import { DownOutlined, UploadOutlined, FileExcelOutlined, FilePdfOutlined } from '@ant-design/icons';
import { saveBlob } from '@/utils/file';
import { notifyWithIcon } from '@/utils/notifications';

export enum ExportType {
    CSV = 'CSV',
    PDF = 'PDF',
    ZIP = 'ZIP',
    XLSX = 'XLSX',
}

export type ExportItem = {
    key: string;
    label: string;
    fileName?: string;
    exportType?: ExportType;
    blobGetter: () => Promise<Blob> | null;
};

type ExportTypeIconMapping = {
    [K in keyof typeof ExportType]: React.ReactNode;
};

type ExportSelectorProps = {
    exportItems: ExportItem[];
    size?: 'small' | 'middle' | 'large';
};

export const ExportSelector: React.FC<ExportSelectorProps> = ({ exportItems, size = 'middle' }) => {
    const { t } = useTranslation();

    const exportTypeIconMapping: ExportTypeIconMapping = {
        [ExportType.CSV]: <FileExcelOutlined />,
        [ExportType.PDF]: <FilePdfOutlined />,
        [ExportType.ZIP]: <UploadOutlined />,
        [ExportType.XLSX]: <FileExcelOutlined />,
    };

    if (exportItems == null || exportItems.length === 0) {
        return null;
    }

    const onExport = async (item: ExportItem) => {
        try {
            if (item?.blobGetter == null) {
                return;
            }
            const blob = await item.blobGetter();
            if (blob == null) {
                return;
            }
            saveBlob(blob, item.fileName ?? (item.exportType != null ? `export-${item.exportType}` : 'export'));
            notifyWithIcon('success', t('SHARED.EXPORT_SUCCESSFUL'));
        } catch (error) {
            if (error instanceof Error) {
                notifyWithIcon('error', `${error.message}`);
            }
        }
    };

    const onClickDropdown = async (input: any) => {
        const { key } = input;
        if (key == null) {
            return;
        }
        const item = exportItems.find((item) => item.key === key);
        if (item == null) {
            return;
        }
        onExport(item);
    };

    if (exportItems.length === 0) {
        return null;
    }

    if (exportItems.length === 1) {
        const item = exportItems[0];
        return (
            <Button
                size={size}
                onClick={() => {
                    onExport(item);
                }}
            >
                <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
                    {item.exportType != null && exportTypeIconMapping[item.exportType]}
                    {item.label}
                </Space>
            </Button>
        );
    }

    return (
        <Dropdown
            menu={{
                items: exportItems.map((item) => ({
                    label: item.label,
                    key: item.key,
                })) as MenuProps['items'],
                onClick: onClickDropdown,
            }}
        >
            <Button size={size}>
                <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <UploadOutlined />
                    {t('SHARED.EXPORT')}
                    <DownOutlined />
                </Space>
            </Button>
        </Dropdown>
    );
};
