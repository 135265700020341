import { DimensionType, ProductFunctionalDimension, ProductSectoralDimension, ProductTechnologicalDimension } from '@/utils/cyberpass';
import {
    MdOutlineHomeWork,
    MdPrecisionManufacturing,
    MdOutlineHealthAndSafety,
    MdOutlineBolt,
    MdOutlineElectricCar,
    MdOutlineSmartToy,
    MdMemory,
    MdOutlineGppGood,
    MdAttachMoney,
    MdWorkspaces,
    MdOutlineSettingsInputAntenna,
    MdShoppingBasket,
    MdCode,
    MdOutlineSchool,
    MdWbShade,
    MdSpeed,
    MdOutlineResetTv,
    MdSmartScreen,
    MdOutlineDevicesOther,
    MdOutlineSettingsApplications,
    MdOutlineTableView,
    MdFingerprint,
    MdOutlineFence,
    MdPolicy,
    MdOutlineStorage,
    MdCreditCard,
    MdVpnKey,
    MdElectricScooter,
    MdOutlineDvr,
    MdOutlineImportantDevices,
    MdOutlinePsychology,
    MdRadio,
    MdCloudCircle,
    MdOutlineSettingsSuggest,
    MdOutlineDeveloperBoard,
    MdLanguage,
    MdMenuBook,
    MdOutlineFilterVintage,
    MdSatellite,
    MdOutlineViewInAr,
    MdWifiProtectedSetup,
    MdCardMembership,
    MdLock,
    MdOutlineTipsAndUpdates,
    MdOutlineMemory,
    MdMiscellaneousServices,
    MdSettings,
    MdGeneratingTokens,
} from 'react-icons/md';

export const icons = {
    [DimensionType.PRODUCT_SECTORAL]: {
        [ProductSectoralDimension.DEFENSE]: <MdOutlineGppGood />,
        [ProductSectoralDimension.ENERGY]: <MdOutlineBolt />,
        [ProductSectoralDimension.FINANCIAL]: <MdAttachMoney />,
        [ProductSectoralDimension.INDUSTRIAL]: <MdPrecisionManufacturing />,
        [ProductSectoralDimension.PUBLIC_ADMINISTRATION]: <MdOutlineHomeWork />,
        [ProductSectoralDimension.HEALTH]: <MdOutlineHealthAndSafety />,
        [ProductSectoralDimension.SPACE]: <MdWorkspaces />,
        [ProductSectoralDimension.TELECOM]: <MdOutlineSettingsInputAntenna />,
        [ProductSectoralDimension.TRANSPORTATION]: <MdMemory />,
        [ProductSectoralDimension.CONSUMER]: <MdShoppingBasket />,
        [ProductSectoralDimension.GENERAL_IT]: <MdCode />,
        [ProductSectoralDimension.RESEARCH_EDUCATION]: <MdOutlineSchool />,
        [ProductSectoralDimension.OTHER_GENERAL_INDUSTRIES]: <MdWbShade />,
    },
    [DimensionType.PRODUCT_FUNCTIONAL]: {
        [ProductFunctionalDimension.CONTROL_ACCESS_DEVICES_SYSTEM]: <MdOutlineTableView />,
        [ProductFunctionalDimension.BIOMETRIC_SYSTEMS_DEVICES]: <MdFingerprint />,
        [ProductFunctionalDimension.BOUNDARY_PROTECTION_DEVICES_SYSTEMS]: <MdOutlineFence />,
        [ProductFunctionalDimension.PROTECT_DATA_DEVICES_SYSTEMS]: <MdPolicy />,
        [ProductFunctionalDimension.BUILD_MAINTAIN_DATABASES]: <MdOutlineStorage />,
        [ProductFunctionalDimension.ICS_SMART_CARDS_DEVICES]: <MdCreditCard />,
        [ProductFunctionalDimension.KEY_MANAGEMENT_SYSTEMS]: <MdVpnKey />,
        [ProductFunctionalDimension.REINFORCE_MOBILITY]: <MdElectricScooter />,
        [ProductFunctionalDimension.MULTIFUNCTION_DEVICES]: <MdOutlineDvr />,
        [ProductFunctionalDimension.ALLOW_NETWORK_RELATED_DEVICES_SYSTEMS]: <MdOutlineImportantDevices />,
        [ProductFunctionalDimension.OPERATING_SYSTEMS]: <MdOutlineSettingsApplications />,
        [ProductFunctionalDimension.OTHER_DEVICES_SYSTEMS]: <MdOutlineDevicesOther />,
        [ProductFunctionalDimension.PRODUCT_DIGITAL_SIGNATURES]: <MdSmartScreen />,
        [ProductFunctionalDimension.TRUSTED_COMPUTING]: <MdOutlineResetTv />,
        [ProductFunctionalDimension.SMART_METERS]: <MdSpeed />,
    },
    [DimensionType.PRODUCT_TECHNOLOGICAL]: {
        [ProductTechnologicalDimension.ARTIFICIAL_INTELLIGENCE_MACHINE_LEARNING_SYSTEMS]: <MdOutlinePsychology />,
        [ProductTechnologicalDimension.RADIO_TECHNOLOGIES]: <MdRadio />,
        [ProductTechnologicalDimension.CLOUD_EDGE_VIRTUALIZATION]: <MdCloudCircle />,
        [ProductTechnologicalDimension.INDUSTRIAL_SYSTEMS]: <MdOutlineSettingsSuggest />,
        [ProductTechnologicalDimension.INTERNET_OF_THINGS]: <MdLanguage />,
        [ProductTechnologicalDimension.VEHICULAR_SYSTEMS]: <MdOutlineElectricCar />,
        [ProductTechnologicalDimension.DATABASE_TECHNOLOGIES_BUSINESS_INTELLIGENCE_BIG_DATA]: <MdOutlineDeveloperBoard />,
        [ProductTechnologicalDimension.BLOCKCHAIN_DISTRIBUTED_LEDGER_TECHNOLOGY]: <MdMenuBook />,
        [ProductTechnologicalDimension.NANOTECHNOLOGY]: <MdOutlineFilterVintage />,
        [ProductTechnologicalDimension.SATELLITE_SYSTEMS_APPLICATIONS]: <MdSatellite />,
        [ProductTechnologicalDimension.ROBOTICS]: <MdOutlineSmartToy />,
        [ProductTechnologicalDimension.QUANTUM_TECHNOLOGIES_MODULES]: <MdOutlineViewInAr />,
        [ProductTechnologicalDimension.CYBERSECURITY]: <MdWifiProtectedSetup />,
        [ProductTechnologicalDimension.SMARTCARDS_DEVICES]: <MdCardMembership />,
        [ProductTechnologicalDimension.HARDWARE_SECURITY_BOXES]: <MdLock />,
        [ProductTechnologicalDimension.OTHER_GENERAL_TECHNOLOGIES]: <MdOutlineTipsAndUpdates />,
        [ProductTechnologicalDimension.HARDWARE]: <MdOutlineMemory />,
        [ProductTechnologicalDimension.SOFTWARE]: <MdMiscellaneousServices />,
        [ProductTechnologicalDimension.INTEGRATED_CIRCUITS]: <MdSettings />,
        [ProductTechnologicalDimension.CRYPTOGRAPHIC]: <MdGeneratingTokens />,
    },
};
