import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Layout, List } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import { useAuth } from '@/hooks/useAuth';
import CyberpassLogoVertical from '@/assets/images/cyberpass-logo-vertical.svg';
import Red1Intro from '@/assets/images/red-1-intro.svg';
import { PublicFooter } from '@/components//PublicFooter';
import './styles.css';

type PagePublicDisplayLayoutProps = {
    children: React.ReactNode;
    header?: React.ReactNode;
};

export const PagePublicDisplayLayout: React.FC<PagePublicDisplayLayoutProps> = ({ children, header }) => {
    const { authenticated } = useAuth();
    const { t } = useTranslation();
    return (
        <Layout className="page-public-display-layout">
            <Layout.Header className="page-public-display-layout-header">
                <Link to="/">
                    <img src={CyberpassLogoVertical} style={{ width: 100 }} alt="" />
                </Link>
            </Layout.Header>
            {header}
            <Layout.Content className="page-public-display-layout-content">{children}</Layout.Content>
            <Layout.Footer className="page-public-display-layout-footer">
                <div className="page-public-display-layout-footer-cta-section">
                    <img src={Red1Intro} style={{ height: 280 }} alt="" />
                    <div>
                        <h2>{t('COMPONENTS.PAGE_PUBLIC_DISPLAY_LAYOUT.FOOTER.TITLE')}</h2>
                        <List
                            dataSource={t('COMPONENTS.PAGE_PUBLIC_DISPLAY_LAYOUT.FOOTER.ITEMS', { returnObjects: true }) satisfies string[]}
                            renderItem={(item) => (
                                <List.Item>
                                    <CheckCircleFilled style={{ marginRight: 5 }} /> {item}
                                </List.Item>
                            )}
                        />

                        {!authenticated && (
                            <Link to="/login" className="page-public-display-layout-footer-cta-button">
                                <Button role="link" type="primary">
                                    {t('COMPONENTS.PAGE_PUBLIC_DISPLAY_LAYOUT.FOOTER.BUTTON')}
                                </Button>
                            </Link>
                        )}
                    </div>
                </div>

                <div className="page-public-display-layout-public-footer">
                    <PublicFooter />
                </div>
            </Layout.Footer>
        </Layout>
    );
};
