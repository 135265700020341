import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AuditOutlined, PlayCircleOutlined, HeartFilled, HeartOutlined } from '@ant-design/icons';
import { ConfigurationType, type Certification } from '@/utils/cyberpass';
import { EvaluationFlowStatus, getEvaluationFlowStatus } from '@/utils/evaluation';
import { useAuth } from '@/hooks/useAuth';
import { useActiveConfigurationDefinition } from '@/utils/configurations';
import { useCyberpassProductLookupById, useCyberpassSchemeEvaluationObjectsLookupByCertificationId, useCyberpassUserUpdateData } from '@/query';
import { ActionTableButton } from '@/components/ActionTableButton';

type ActionsProps = {
    certification: Certification | undefined;
    showWatchAction?: boolean;
};

export const Actions: React.FC<ActionsProps> = ({ certification, showWatchAction = true }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { user, currentWorkspace, refetch: userRefetch } = useAuth();
    const updateUserData = useCyberpassUserUpdateData(user?.id);
    const { data: product } = useCyberpassProductLookupById(certification?.productId);
    const { data: assessmentObjects } = useCyberpassSchemeEvaluationObjectsLookupByCertificationId(certification?.id);
    const { data: schemeDefinition } = useActiveConfigurationDefinition(certification?.schemeId, ConfigurationType.SCHEME);
    const status = useMemo(
        () => getEvaluationFlowStatus({ certification: assessmentObjects?.certification, forms: assessmentObjects?.forms, schemeDefinition }),
        [assessmentObjects, schemeDefinition],
    );

    //

    const isWatched = (userCertificationWatchList: string[], certificationId: string | undefined) => {
        return certificationId == null ? false : (userCertificationWatchList ?? []).includes(certificationId);
    };

    const watched = isWatched(user?.preferences?.watchLists?.certifications ?? [], certification?.id);

    const watchIcon = watched ? <HeartFilled /> : <HeartOutlined />;

    const show = { continueEvaluation: false, certificationResult: false };
    switch (status) {
        case EvaluationFlowStatus.ONGOING:
        case EvaluationFlowStatus.INCONCLUSIVE:
            // only if product-owner
            show.continueEvaluation = currentWorkspace?.id === product?.workspaceId;
            break;

        case EvaluationFlowStatus.SUBMITTED:
        case EvaluationFlowStatus.REVIEWING:
            // only if certification-reviewing-lab
            show.continueEvaluation = currentWorkspace?.id === certification?.reviewerWorkspaceId;
            break;

        case EvaluationFlowStatus.CERTIFIED:
        case EvaluationFlowStatus.FAILED:
            show.certificationResult = true;
            break;
    }

    //

    const handleToggleWatchList = (certificationId: string | undefined, on: boolean) => {
        if (certificationId == null) {
            return;
        }
        const newWatchListsCertifications = on
            ? [...new Set([...(user?.preferences?.watchLists?.certifications ?? []), certificationId])]
            : (user?.preferences?.watchLists?.certifications ?? []).filter((id) => id !== certificationId);
        updateUserData.mutate(
            {
                preferences: {
                    ...user?.preferences,
                    watchLists: {
                        ...user?.preferences?.watchLists,
                        certifications: newWatchListsCertifications,
                    },
                },
            },
            {
                onSuccess: () => {
                    userRefetch();
                },
            },
        );
    };

    //

    return (
        <>
            {showWatchAction && (
                <ActionTableButton
                    icon={watchIcon}
                    tooltip={t('COMPONENTS.CERTIFICATIONS_TABLE.ACTION_TOOLTIP_WATCHLIST')}
                    color={'var(--blue-6)'}
                    onClick={() => {
                        handleToggleWatchList(certification?.id, !watched);
                    }}
                />
            )}
            <ActionTableButton
                icon={<PlayCircleOutlined />}
                tooltip={t('COMPONENTS.CERTIFICATIONS_TABLE.CONTINUE_EVALUATION')}
                hidden={!show.continueEvaluation}
                onClick={() => {
                    navigate(`/certifications/${certification?.id}`, { replace: true });
                }}
            />
            <ActionTableButton
                icon={<AuditOutlined />}
                tooltip={t('COMPONENTS.CERTIFICATIONS_TABLE.VIEW_CERTIFICATE')}
                hidden={!show.certificationResult}
                onClick={() => {
                    navigate(`/certifications/${certification?.id}/result`, { replace: true });
                }}
            />
        </>
    );
};
