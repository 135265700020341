import type React from 'react';
import { useTranslation } from 'react-i18next';
import { CustomDrawer } from '@/components/CustomDrawer';
import { UserCompanyPresentationForm } from '@/components/UserCompanyPresentationForm';
import { useCyberpassUserGetDetails } from '@/query';

type AboutLabDrawerProps = {
    open: boolean | undefined;
    onClose: () => void;
    labUserId: string | undefined;
};

export const AboutLabDrawer: React.FC<AboutLabDrawerProps> = ({ open, onClose, labUserId }) => {
    const { t } = useTranslation();
    const { data: user } = useCyberpassUserGetDetails(labUserId);

    return (
        <CustomDrawer title={t('COMPONENTS.ABOUT_LAB_DRAWER.TITLE')} onClose={onClose} open={open}>
            <UserCompanyPresentationForm user={user ?? undefined} editable={false} />
        </CustomDrawer>
    );
};
