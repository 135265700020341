import { ProductStatus } from '@/utils/cyberpass';
import {
    useCyberpassCertificationSearch,
    useCyberpassProductSearch,
    useCyberpassUserGetDetails,
    useCyberpassWorkspaceLookupById,
    useCyberpassWorkspaceSearch,
} from '@/query';
import { QuotaType } from '@/types/quotas';

type UseQuotaProps =
    | {
          type: QuotaType.MAX_WORKSPACES_PER_USER;
          userId: string | undefined;
      }
    | {
          type: QuotaType.MAX_USERS_PER_WORKSPACE | QuotaType.MAX_CERTIFICATIONS_PER_WORKSPACE;
          workspaceId: string | undefined;
      };

export const useQuota = (input: UseQuotaProps) => {
    let userId: string | undefined;
    let workspaceId: string | undefined;

    switch (input.type) {
        case QuotaType.MAX_WORKSPACES_PER_USER:
            userId = input.userId;
            break;
        case QuotaType.MAX_USERS_PER_WORKSPACE:
        case QuotaType.MAX_CERTIFICATIONS_PER_WORKSPACE:
            workspaceId = input.workspaceId;
            break;
    }

    const { data: user } = useCyberpassUserGetDetails(userId);
    const { data: userOwnedWorkspaces } = useCyberpassWorkspaceSearch(
        {
            filters: {
                whereOwner: true,
            },
            pageNumber: 1,
            pageSize: 10000,
        },
        { enabled: user != null },
    );

    const { data: workspace } = useCyberpassWorkspaceLookupById(workspaceId);
    const { data: workspaceProducts } = useCyberpassProductSearch(
        {
            filters: {
                workspaceId: workspace?.id,
                statuses: Object.values(ProductStatus),
            },
            pageNumber: 1,
            pageSize: 10000,
        },
        { enabled: workspace != null },
    );
    const { data: workspaceCertifications } = useCyberpassCertificationSearch(
        {
            filters: {
                productIds: (workspaceProducts?.items ?? []).map((product) => product.id),
                productStatuses: Object.values(ProductStatus),
            },
            pageNumber: 1,
            pageSize: 10000,
        },
        { enabled: workspaceProducts != null && workspaceProducts.items.length > 0 && workspace != null },
    );

    switch (input.type) {
        case QuotaType.MAX_WORKSPACES_PER_USER: {
            if (user == null || userOwnedWorkspaces == null) {
                return null;
            }
            const current = userOwnedWorkspaces.items.length;
            const max = user.props.maxWorkspacesPerUser;
            return {
                current,
                max,
                reached: current >= max,
                type: input.type,
            };
        }
        case QuotaType.MAX_USERS_PER_WORKSPACE: {
            if (workspace == null) {
                return null;
            }
            const current = workspace.membersUserIds?.length;
            const max = workspace.props.maxUsersPerWorkspace;
            return {
                current,
                max,
                reached: current >= max,
                type: input.type,
            };
        }
        case QuotaType.MAX_CERTIFICATIONS_PER_WORKSPACE: {
            if (workspace == null) {
                return null;
            }
            const current = workspaceCertifications?.items.length ?? 0;
            const max = workspace.props.maxCertificationsPerWorkspace;
            return {
                current,
                max,
                reached: current >= max,
                type: input.type,
            };
        }
    }
};
