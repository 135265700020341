import type React from 'react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Tabs, Space } from 'antd';
import { EditOutlined, DeleteOutlined, SafetyCertificateOutlined } from '@ant-design/icons';
import { type Product, ProductStatus } from '@/utils/cyberpass';
import { useCyberpassProductUpdate, useCyberpassUserGetDetails, useCyberpassWorkspaceLookupById } from '@/query';
import { useAuth } from '@/hooks/useAuth';
import { ActionButton } from '@/components/ActionButton';
import { ProductCertificationsTable } from '@/components/ProductCertificationsTable';
import { UserCompanyPresentationForm } from '@/components/UserCompanyPresentationForm';
import { CustomDrawer } from '@/components/CustomDrawer';
import { ProductForm } from '@/components/ProductForm';
import { InfoActionBar } from '@/components/InfoActionBar';
import { VulnerabilityTab } from './VulnerabilityTab';
import './styles.css';

type ProductDrawerProps = {
    open: boolean;
    onClose: () => void;
    product: Product;
};

export const ProductDrawer: React.FC<ProductDrawerProps> = ({ open, onClose, product }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { currentWorkspace, authenticated } = useAuth();
    const [activeTabKey, setActiveTabKey] = useState<string>('tab-product');
    const [editable, setEditable] = useState<boolean>(false);
    const { data: productWorkspace } = useCyberpassWorkspaceLookupById(product?.workspaceId);
    const { data: owner } = useCyberpassUserGetDetails(productWorkspace?.creatorUserId);
    const updateProduct = useCyberpassProductUpdate(product.id);

    //

    const handleEdit = () => {
        setActiveTabKey('tab-product');
        setEditable(true);
    };

    const handleDelete = () => {
        updateProduct?.mutate(
            { status: ProductStatus.ARCHIVED },
            {
                onSuccess: () => {
                    onClose?.();
                },
            },
        );
    };

    const handleEvaluate = () => {
        navigate('/certifications/new', { state: { productId: product?.id }, replace: true });
    };

    const handleCloseDrawer = () => {
        onClose();
        setEditable(false);
    };

    //

    useEffect(() => {
        if (open != null && !open) {
            setActiveTabKey('tab-product');
        }
    }, [open]);

    //

    return (
        <CustomDrawer
            open={open}
            onClose={handleCloseDrawer}
            title={product?.name}
            extra={
                authenticated && currentWorkspace?.id === product?.workspaceId ? (
                    <InfoActionBar
                        extra={
                            <Space style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
                                <ActionButton //
                                    tooltip={t('COMPONENTS.PRODUCT_DRAWER.ACTIONS.BUTTON_EDIT')}
                                    icon={<EditOutlined />}
                                    onClick={handleEdit}
                                    hidden={editable}
                                />
                                <ActionButton
                                    title={t('COMPONENTS.PRODUCT_DRAWER.ACTIONS.BUTTON_DELETE')}
                                    tooltip={t('COMPONENTS.PRODUCT_DRAWER.ACTIONS.BUTTON_DELETE')}
                                    confirmationText={t('COMPONENTS.PRODUCT_DRAWER.ACTIONS.BUTTON_DELETE_CONFIRMATION')}
                                    okText={t('SHARED.DELETE')}
                                    icon={<DeleteOutlined />}
                                    onClick={handleDelete}
                                    danger
                                />
                                <ActionButton
                                    tooltip={t('COMPONENTS.PRODUCT_DRAWER.ACTIONS.BUTTON_EVALUATE')}
                                    icon={<SafetyCertificateOutlined />}
                                    label={t('COMPONENTS.PRODUCT_DRAWER.ACTIONS.BUTTON_EVALUATE_LABEL')}
                                    onClick={handleEvaluate}
                                    type="primary"
                                />
                            </Space>
                        }
                    />
                ) : undefined
            }
        >
            <Tabs
                activeKey={activeTabKey}
                onChange={(key) => {
                    setActiveTabKey(key);
                }}
                className={activeTabKey === 'tab-vulnerability' ? 'product-drawer-tabs' : ''}
                items={[
                    {
                        label: t('COMPONENTS.PRODUCT_DRAWER.TAB_PRODUCT.TITLE'),
                        key: 'tab-product',
                        children: (
                            <ProductForm //
                                product={product}
                                editable={editable}
                                onSubmit={() => {
                                    setEditable(false);
                                }}
                                onSuccess={() => {
                                    setEditable(false);
                                }}
                                onCancel={() => {
                                    setEditable(false);
                                }}
                            />
                        ),
                    },
                    {
                        label: t('COMPONENTS.PRODUCT_DRAWER.TAB_PROVIDER.TITLE'),
                        key: 'tab-provider',
                        children: <UserCompanyPresentationForm user={owner ?? undefined} editable={false} />,
                    },
                    ...(authenticated
                        ? [
                              {
                                  label: t('COMPONENTS.PRODUCT_DRAWER.TAB_CERTIFICATIONS.TITLE'),
                                  key: 'tab-evaluations',
                                  children: <ProductCertificationsTable product={product} onClickStartEvaluation={handleEvaluate} />,
                              },
                          ]
                        : []),
                    {
                        label: t('COMPONENTS.PRODUCT_DRAWER.TAB_VULNERABILITY.TITLE'),
                        key: 'tab-vulnerability',
                        children: <VulnerabilityTab product={product} />,
                    },
                ]}
            />
        </CustomDrawer>
    );
};
