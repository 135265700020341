// WebSocketContext.js
import type React from 'react';
import { createContext, type ReactNode, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '@/hooks/useAuth';
import { notifyWithIcon } from '@/utils/notifications';
import config from '@/utils/config';
import cyberpass from '@/utils/cyberpass';

type WebSocketContextProps = {
    socket: WebSocket | null;
    messages: Array<{ type: string; payload: any }>;
};

const WebSocketContext = createContext<WebSocketContextProps | undefined>(undefined);

type WebSocketProviderProps = {
    children: ReactNode;
};

export const WebSocketProvider: React.FC<WebSocketProviderProps> = ({ children }) => {
    const { t } = useTranslation();
    const [socket, setSocket] = useState<WebSocket | null>(null);
    const [messages, setMessages] = useState<any[]>([]);
    const { currentWorkspace } = useAuth();

    const createConnection = () => {
        if (currentWorkspace == null) {
            return;
        }
        const token = cyberpass.getState().auth.accessToken;
        if (token == null) {
            return;
        }

        if (socket != null && socket.readyState === WebSocket.OPEN) {
            return;
        }

        // Establish WebSocket connection to the Express backend
        const ws = new WebSocket(`${config.BACKEND_WEB_SOCKET_URL}?workspaceId=${currentWorkspace?.id}&token=${token}`);

        setSocket(ws);

        ws.onmessage = (event) => {
            const data = JSON.parse(event.data);

            if (data.type === 'notification') {
                notifyWithIcon(data.payload.type, t(`SHARED.SOCKET.${data.payload.key}`));
            }

            setMessages((prevMessages) => [data, ...prevMessages]);
        };

        ws.onclose = (event) => {
            setSocket(null);
            // If the connection was not closed cleanly, try to reconnect after 5 seconds
            // (ie backend is temporarily down)
            if (!event.wasClean) {
                setTimeout(() => {
                    createConnection();
                }, 5000);
            }
        };

        return ws;
    };

    useEffect(() => {
        const ws = createConnection();

        return () => {
            ws?.close();
        };
    }, [currentWorkspace?.id]);

    return <WebSocketContext.Provider value={{ socket, messages }}>{children}</WebSocketContext.Provider>;
};

export const useWebSocket = () => {
    const webSocketContext = useContext(WebSocketContext);
    if (webSocketContext === undefined) {
        throw new Error('WebSocketProvider is undefined');
    }
    return webSocketContext;
};
