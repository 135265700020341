import type React from 'react';
import { useState } from 'react';
import { Drawer, Typography } from 'antd';
import { CloseOutlined, DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons';
import { useIsMobile } from '@/hooks/useIsMobile';
import './styles.css';

type CustomDrawerProps = {
    className?: string;
    width?: number;
    title?: string | React.ReactNode;
    extra?: React.ReactNode;
    open?: boolean;
    destroyOnClose?: boolean;
    onClose?: (e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>) => void;
    children?: React.ReactNode;
};

export const CustomDrawer: React.FC<CustomDrawerProps> = ({
    className = '',
    width = 750,
    title = '',
    extra,
    open = false,
    destroyOnClose = true,
    onClose,
    children,
}) => {
    const [expand, setExpand] = useState<boolean>(false);
    const isMobile = useIsMobile();

    const handleExpand = () => {
        setExpand((previous) => !previous);
    };

    return (
        <Drawer
            className={`custom-drawer-wrapper ${className}`}
            width={expand ? '80%' : width}
            closable={false}
            placement={isMobile ? 'bottom' : 'right'}
            height="100%"
            title={
                <div className="custom-drawer-title">
                    <div className="custom-drawer-title-text">
                        {isMobile && <CloseOutlined onClick={onClose} style={{ paddingRight: '12px' }} />}
                        <Typography.Text ellipsis={true}>{title}</Typography.Text>
                    </div>
                    {extra == null ? null : <div className="custom-drawer-title-extras">{extra}</div>}
                </div>
            }
            onClose={onClose}
            destroyOnClose={destroyOnClose}
            open={open}
        >
            <div className="custom-drawer-action-bar">
                <CloseOutlined className="custom-drawer-action-button" onClick={onClose} />
                {expand ? (
                    <DoubleRightOutlined className="custom-drawer-action-button" onClick={handleExpand} />
                ) : (
                    <DoubleLeftOutlined className="custom-drawer-action-button" onClick={handleExpand} />
                )}
            </div>
            <div className="custom-drawer-body">{children}</div>
        </Drawer>
    );
};
