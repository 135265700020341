import { Col, Row } from 'antd';
import './styles.css';

type InfoActionBarProps = {
    title?: React.ReactNode;
    extra?: React.ReactNode;
    content?: React.ReactNode;
    contentClassName?: string;
    className?: string;
};

export const InfoActionBar = ({ title, extra, content, className, contentClassName }: InfoActionBarProps) => {
    return (
        <>
            <Row gutter={72} className={`info-action-bar-container ${className ?? ''}`} wrap={false}>
                {title != null && (
                    <Col flex="3 1 50%" className="info-action-bar-title-section">
                        {title}
                    </Col>
                )}
                {extra != null && (
                    <Col flex={`${title != null ? 0 : 1} 1 fit-content`} className="info-action-bar-extra-section">
                        {extra}
                    </Col>
                )}
            </Row>
            {content != null && (
                <Row>
                    <Col flex="1 1 100%" className={`info-action-bar-content-section ${contentClassName ?? ''}`}>
                        {content}
                    </Col>
                </Row>
            )}
        </>
    );
};
