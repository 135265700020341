import type React from 'react';

type ResultTabTitleProps = {
    icon: React.ReactNode;
    title: string;
    result?: string;
};

export const ResultTabTitle: React.FC<ResultTabTitleProps> = ({ icon, title, result }) => {
    return (
        <>
            {icon} {title} {result}
        </>
        // <div className="result-tabs-tab">
        //     <div className="result-tabs-tab-icon">{icon}</div>
        //     <span className="result-tabs-tab-title">{title}</span>
        //     <div className="result-tabs-tab-shape" style={result == null ? { backgroundColor: 'transparent' } : {}}>
        //         {result}
        //     </div>
        // </div>
    );
};
