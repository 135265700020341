import { useEffect, useState } from 'react';
import { type Certification } from '@/utils/cyberpass';
import { useTranslation } from 'react-i18next';
import { Input, Button, Form } from 'antd';
import { notifyWithIcon } from '@/utils/notifications';
import { useCyberpassCertificationLookupBySerialNumber } from '@/query';
import './styles.css';

type CertificateSearchFormProps = {
    onSubmit: (certification: Certification) => void;
    buttonLabel: string;
    placeholder: string;
};

export const CertificateSearchForm: React.FC<CertificateSearchFormProps> = ({ onSubmit, buttonLabel, placeholder }) => {
    const { t } = useTranslation();

    const [certificateSerialNumber, setCertificateSerialNumber] = useState<string | undefined>();

    const { data: certification, isError } = useCyberpassCertificationLookupBySerialNumber(certificateSerialNumber);

    const [showErrorNotification, setShowErrorNotification] = useState(false);

    const handleSubmit = (values: any) => {
        if (isError) {
            setShowErrorNotification(false);
        }
        setCertificateSerialNumber(values.serialNumber);
    };

    useEffect(() => {
        const hasError = isError && !showErrorNotification;
        if (hasError) {
            notifyWithIcon('error', t('SHARED.INVALID_CERTIFICATE_SERIAL_NUMBER'));
            setShowErrorNotification(true);
            return;
        }

        if (certificateSerialNumber != null && certification != null) {
            onSubmit(certification);
        }
    }, [certificateSerialNumber, certification, isError, t, onSubmit, showErrorNotification]);

    return (
        <Form //
            className="certification-search-form"
            initialValues={{ certificateSerialNumber }}
            onFinish={handleSubmit}
        >
            <Form.Item
                name="serialNumber"
                className="certification-search-form-input"
                rules={[
                    { required: true, message: t('COMPONENTS.FORM_ITEMS_BUILDER.RULE_FAILED_REQUIRED') },
                    { type: 'string', min: 8, message: t('COMPONENTS.FORM_ITEMS_BUILDER.RULE_FAILED_MIN', { count: 8 }) },
                    { type: 'string', max: 30, message: t('COMPONENTS.FORM_ITEMS_BUILDER.RULE_FAILED_MAX', { count: 30 }) },
                ]}
            >
                <Input size="middle" placeholder={placeholder} />
            </Form.Item>
            <Button type="primary" size="middle" htmlType="submit">
                {buttonLabel}
            </Button>
        </Form>
    );
};
