import productImage1 from '@/assets/images/product-image-1.svg';
import productImage2 from '@/assets/images/product-image-2.svg';
import productImage3 from '@/assets/images/product-image-3.svg';
import productImage4 from '@/assets/images/product-image-4.svg';

export const productImages: any[] = [
    //
    productImage1,
    productImage2,
    productImage3,
    productImage4,
];

export const byteHash = (input: string) => {
    let hash = 0;
    for (const string of [...input]) {
        hash += string.charCodeAt(0);
    }
    return hash;
};

/**
 * Get default image of product when product doesn't have photo.
 * @param id product id
 * @returns static link to image
 */
export const getProductDefaultImage = (id: string): string => {
    const pixId = byteHash(id) % productImages.length;
    return productImages[pixId];
};
