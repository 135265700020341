import { useState } from 'react';
import { Button } from 'antd';
import { TbFileDiff } from 'react-icons/tb';
import { useTranslation } from 'react-i18next';
import { CustomDrawer } from '@/components/CustomDrawer';
import { FormItemsBuilder, type FormItemsBuilderItemType, type FormItemsBuilderItem, FormItemsBuilderItemMode } from '@/components/FormItemsBuilder';
import { diffArrays, diffWordsWithSpace } from 'diff';
import { FileDownloader } from '../FileDownloader';
import { ProductInfo } from '../ProductInfo';
import { SwapRightOutlined } from '@ant-design/icons';
import { SmartDate } from '../SmartDate';
import './styles.css';

const diffRenderer = (item: FormItemsBuilderItem) => {
    if (item.diff == null) {
        return null;
    }

    if ((['file-downloader', 'file-avatar', 'file-carousel', 'file-image'] as FormItemsBuilderItemType[]).includes(item.type)) {
        const diffArray = diffArrays(
            Array.isArray(item.diff.old) ? item.diff.old : item.diff.old != null ? [item.diff.old] : [],
            Array.isArray(item.diff.new) ? item.diff.new : item.diff.new != null ? [item.diff.new] : [],
        );

        const diffElements = diffArray.map((diff, index) => {
            if (diff.added != null && diff.added) {
                return (
                    <FileDownloader
                        key={index}
                        fileClassName="form-field-diff-drawer-new-changes-file form-field-diff-drawer-changes-list-item"
                        showHeader={false}
                        fileIds={diff.value as string[]}
                    />
                );
            } else if (diff.removed != null && diff.removed) {
                return (
                    <FileDownloader
                        key={index}
                        fileClassName="form-field-diff-drawer-old-changes-file form-field-diff-drawer-changes-list-item"
                        showHeader={false}
                        fileIds={diff.value as string[]}
                    />
                );
            } else {
                return (
                    <FileDownloader key={index} fileClassName="form-field-diff-drawer-changes-list-item" showHeader={false} fileIds={diff.value as string[]} />
                );
            }
        });

        return <span>{diffElements}</span>;
    }
    if ((['product'] as FormItemsBuilderItemType[]).includes(item.type)) {
        const diffArray = diffWordsWithSpace(item.diff?.old, item.diff?.new);
        const diffElements = diffArray.map((diff, index) => {
            if (diff.added != null && diff.added) {
                return (
                    <ProductInfo
                        key={index}
                        id={diff?.value}
                        className="form-field-diff-drawer-new-changes form-field-diff-drawer-changes-list-item"
                        showImage
                        showName
                        showDescription
                    />
                );
            } else if (diff.removed != null && diff.removed) {
                return (
                    <ProductInfo
                        key={index}
                        id={diff?.value}
                        className="form-field-diff-drawer-old-changes form-field-diff-drawer-changes-list-item"
                        showImage
                        showName
                        showDescription
                    />
                );
            } else {
                return <ProductInfo key={index} id={diff?.value} showImage showName showDescription />;
            }
        });
        return <span>{diffElements}</span>;
    }
    if ((['date'] as FormItemsBuilderItemType[]).includes(item.type)) {
        return (
            <span>
                <span className="form-field-diff-drawer-old-changes">
                    <SmartDate date={item.diff.old} />
                </span>
                <span className="form-field-diff-drawer-new-changes">
                    <SmartDate date={item.diff.new} />
                </span>
            </span>
        );
    }
    if ((['date-range'] as FormItemsBuilderItemType[]).includes(item.type)) {
        return (
            <span>
                <span className="form-field-diff-drawer-old-changes">
                    <SmartDate date={item.diff.old?.[0]} />
                    <SwapRightOutlined />
                    <SmartDate date={item.diff.old?.[1]} />
                </span>
                <span className="form-field-diff-drawer-new-changes">
                    <SmartDate date={item.diff.new?.[0]} />
                    <SwapRightOutlined />
                    <SmartDate date={item.diff.new?.[1]} />
                </span>
            </span>
        );
    }
    if ((['text', 'text-area'] as FormItemsBuilderItemType[]).includes(item.type)) {
        const diffArray = diffWordsWithSpace(item.diff?.old ?? '', item.diff?.new ?? '');
        const diffElements = diffArray.map((diff, index) => {
            if (diff.added != null && diff.added) {
                return (
                    <span key={index} className="form-field-diff-drawer-new-changes">
                        {diff?.value}
                    </span>
                );
            } else if (diff.removed != null && diff.removed) {
                return (
                    <span key={index} className="form-field-diff-drawer-old-changes">
                        {diff?.value}
                    </span>
                );
            } else {
                return <span key={index}>{diff?.value}</span>;
            }
        });
        return <span>{diffElements}</span>;
    }
    if ((['address'] as FormItemsBuilderItemType[]).includes(item.type)) {
        return (
            <span>
                <span className="form-field-diff-drawer-old-changes">{Object.values(item.diff.old).join(',')}</span>
                <span className="form-field-diff-drawer-new-changes">{Object.values(item.diff.new).join(',')}</span>
            </span>
        );
    }

    const render =
        item.options?.render != null && typeof item.options?.render === 'function'
            ? item.options.render
            : (value: string, mode?: unknown) => (item.options?.render as any)?.[value] ?? value;
    return (
        <span>
            <span className="form-field-diff-drawer-old-changes">{render(item.diff?.old, FormItemsBuilderItemMode.VIEW)}</span>
            <span className="form-field-diff-drawer-new-changes">{render(item.diff?.new, FormItemsBuilderItemMode.VIEW)}</span>
        </span>
    );
};

type FormFieldDiffDrawerProps = {
    item: FormItemsBuilderItem | undefined;
};

export const FormFieldDiffDrawer = ({ item }: FormFieldDiffDrawerProps) => {
    const { t } = useTranslation();

    const [open, setOpen] = useState(false);

    if (item == null) {
        return;
    }

    return (
        <>
            <Button
                icon={<TbFileDiff color="var(--red-6)" />}
                className="no-style-button form-field-diff-drawer-button"
                onClick={() => {
                    setOpen(true);
                }}
            />
            <CustomDrawer
                title={t('COMPONENTS.FORM_FIELD_DIFF_DRAWER.TITLE')}
                onClose={() => {
                    setOpen(false);
                }}
                open={open}
                destroyOnClose={true}
            >
                <FormItemsBuilder
                    items={[
                        {
                            ...item,
                            type: 'text',
                            diff: undefined,
                            options: {
                                ...item?.options,
                                help: null,
                            },
                            value: item?.diff != null ? diffRenderer(item) : null,
                        },
                    ]}
                    editable={false}
                />
            </CustomDrawer>
        </>
    );
};
