import moment, { type Moment } from 'moment';
import type React from 'react';
import { DatePicker } from 'antd';

type InputDateRangeProps = {
    id?: string;
    value?: [Moment | null, Moment | null] | null;
    onChange?: (value: [Moment | null, Moment | null] | null) => void;
    size?: 'small' | 'middle' | 'large';
    disabled?: boolean;
    style?: React.CSSProperties;
};

export const InputDateRange: React.FC<InputDateRangeProps> = ({ id, value, onChange, size = 'middle', disabled = false, style }) => {
    const handleChange = (value: [Moment | null, Moment | null] | null) => {
        onChange?.(value);
    };

    return (
        <DatePicker.RangePicker
            id={id}
            size={size}
            style={{ ...style, width: '100%' }}
            value={value != null ? [moment(value[0]), moment(value[1])] : undefined}
            onChange={handleChange}
            disabled={disabled}
        />
    );
};
