// QUESTION convention for enums and order of files (types, global functions, components,...)

import type React from 'react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SettingOutlined } from '@ant-design/icons';
import { notifyWithIcon } from '@/utils/notifications';
import { type VerifyPinRequest } from '@/utils/cyberpass';
import { useCyberpassUserBind, useCyberpassUserVerifyPin } from '@/query';
import { FormItemsBuilder } from '@/components/FormItemsBuilder';
import { SimpleForm, type SimpleFormPropsFactory } from '@/components/SimpleForm';
import { buildLoginFormItems } from './form';
import './styles.css';

// //////////////////////////////////////////////////////////////////////////

enum LoginFormStep {
    STEP_1_EMAIL = 1,
    STEP_2_VERIFY_PIN = 2,
}

const defaultVerifyPinRequest: VerifyPinRequest = {
    email: '',
    verificationPin: '',
    termsAccepted: false,
};

// //////////////////////////////////////////////////////////////////////////

type LoginFormProps = SimpleFormPropsFactory<'login', VerifyPinRequest>;

export const LoginForm: React.FC<LoginFormProps> = ({ login, editable, onSubmit, onSuccess, onCancel, onError }) => {
    // Note: LoginForm is a bit particular, it is a 2-step dynamic form and breaks the same in-out data-model
    const { t } = useTranslation();
    const [renderKey, setRenderKey] = useState(Math.random());
    const [verifyPinRequest, setVerifyPinRequest] = useState<VerifyPinRequest>(defaultVerifyPinRequest);
    const [step, setStep] = useState<LoginFormStep>(LoginFormStep.STEP_1_EMAIL);
    const userBind = useCyberpassUserBind();
    const userVerifyPin = useCyberpassUserVerifyPin();

    const userHasAcceptedTerms = userBind.data?.termsAccepted ?? false;
    const items = buildLoginFormItems(verifyPinRequest, step === LoginFormStep.STEP_2_VERIFY_PIN, userHasAcceptedTerms);

    const handleCancel = () => {
        setStep(LoginFormStep.STEP_1_EMAIL);
        // force-re-render and thereby clear the form
        setRenderKey(Math.random());
    };

    const handleSubmit = (values: VerifyPinRequest, done: () => void) => {
        onSubmit?.(values);
        switch (step) {
            case LoginFormStep.STEP_1_EMAIL:
                userBind.mutate((values.email ?? '').toLocaleLowerCase(), {
                    onSuccess: () => {
                        notifyWithIcon('success', t('COMPONENTS.LOGIN_FORM.NOTIFY.SUBMIT_SUCCEEDED_STEP_1', { email: values.email }));
                        setStep(LoginFormStep.STEP_2_VERIFY_PIN);
                    },
                    onError: () => {
                        notifyWithIcon('error', t('COMPONENTS.LOGIN_FORM.NOTIFY.SUBMIT_FAILED_STEP_1'));
                        onError?.();
                    },
                    onSettled: () => {
                        done();
                    },
                });
                break;

            case LoginFormStep.STEP_2_VERIFY_PIN: {
                userVerifyPin.mutate(
                    {
                        email: (values.email ?? '').toLocaleLowerCase(),
                        verificationPin: values.verificationPin ?? '',
                        termsAccepted: values.termsAccepted ?? false,
                    },
                    {
                        onSuccess: (data) => {
                            notifyWithIcon('success', t('COMPONENTS.LOGIN_FORM.NOTIFY.SUBMIT_SUCCEEDED_STEP_2'));
                            onSuccess?.(defaultVerifyPinRequest);
                        },
                        onError: () => {
                            notifyWithIcon('error', t('COMPONENTS.LOGIN_FORM.NOTIFY.SUBMIT_FAILED_STEP_2'));
                            onError?.();
                        },
                        onSettled: () => {
                            done();
                        },
                    },
                );
                break;
            }
        }
    };

    useEffect(() => {
        setVerifyPinRequest(defaultVerifyPinRequest);
        setStep(LoginFormStep.STEP_1_EMAIL);
    }, []);

    return (
        <SimpleForm<VerifyPinRequest> //
            key={renderKey}
            initialValues={verifyPinRequest}
            editable={editable}
            submitLabel={t(`COMPONENTS.LOGIN_FORM.BUTTON_SUBMIT_STEP_${step}`)}
            onCancel={handleCancel}
            showCancel={step === LoginFormStep.STEP_2_VERIFY_PIN}
            cancelLabel={t('COMPONENTS.LOGIN_FORM.BUTTON_CANCEL')}
            onSubmit={handleSubmit}
            showReset={false}
        >
            <FormItemsBuilder //
                items={items}
                defaultImage={<SettingOutlined />}
                editable={editable}
            />
        </SimpleForm>
    );
};
