import React from 'react';
import { useTranslation } from 'react-i18next';
import { Carousel } from 'antd';
import { FileImage } from '@/components/FileImage';
import './styles.css';

type FileCarouselProps = {
    fileIds: string[];
    className?: string;
    showFileCount?: boolean;
    showPreview?: boolean;
};

export const FileCarousel: React.FC<FileCarouselProps> = React.memo(({ fileIds, className, showFileCount = false, showPreview = false }) => {
    const { t } = useTranslation();
    return showFileCount ? (
        t('SHARED.FILE', { count: fileIds?.length ?? 0 })
    ) : (
        <Carousel autoplay className="file-carousel-container">
            {fileIds?.map((fileId) => <FileImage key={fileId} fileId={fileId} className={className} showPreview={showPreview} />)}
        </Carousel>
    );
});
