import en from 'world_countries_lists/data/countries/en/world.json';
import type React from 'react';
import { Select } from 'antd';

type InputCountryProps = {
    id?: string;
    size?: 'large' | 'middle' | 'small';
    defaultValue?: string;
    value?: string;
    onChange?: (value: string) => void;
    showSearch?: boolean;
    placeholder?: string;
    disabled?: boolean;
    style?: React.CSSProperties;
};

export const InputCountry: React.FC<InputCountryProps> = ({
    id,
    size = 'middle',
    value,
    defaultValue,
    onChange,
    showSearch = true,
    placeholder,
    disabled = false,
    style,
}) => {
    return (
        <Select
            id={id}
            style={style}
            size={size}
            value={value}
            defaultValue={defaultValue}
            onChange={onChange}
            showSearch={showSearch}
            placeholder={placeholder}
            disabled={disabled}
        >
            {en.map((country) => (
                <Select.Option key={country.id} value={country.name}>
                    {country.name}
                </Select.Option>
            ))}
        </Select>
    );
};
