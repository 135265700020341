import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import cyberpass, { type File, type FilePropsProps, type Query } from '@/utils/cyberpass';
import { type UploadRequestOption } from 'rc-upload/lib/interface';
import { fileKeys as keys } from './index.keys';

export const useCyberpassFileLookup = (id: string | undefined) => {
    return useQuery(
        keys.detail(id),
        async () => {
            if (id != null) {
                return cyberpass.api.files.lookupById(id);
            }
        },
        {
            enabled: id != null,
        },
    );
};

export const useCyberpassFileSearch = (query: Query, options?: { enabled?: boolean }) => {
    const queryClient = useQueryClient();
    return useQuery(
        keys.list(query),
        async () => {
            if (query != null) {
                const response = await cyberpass.api.files.search(query);
                response?.items?.map((file) => queryClient.setQueryData(keys.detail(file.id), file));
                return response;
            }
        },
        {
            enabled: query != null && (options?.enabled ?? true),
        },
    );
};

export const useCyberpassFileUpload = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async ({ fileProps, options }: { fileProps?: FilePropsProps; options: UploadRequestOption }) => {
            const { file } = options;
            const formData = new FormData();
            formData.append('files[]', file);
            const res = await cyberpass.api.files.create(formData);
            if (fileProps != null) {
                // TODO QUESTION Will we ever do a file update?
                await cyberpass.api.files.update(res.data[0].id, { props: fileProps });
            }
            const uploadedFile: File = res.data[0];

            return uploadedFile;
        },
        onSuccess: (data: File, { options, fileProps }) => {
            if (options?.onSuccess != null) {
                options.onSuccess(data);
            }

            if (data?.id != null) {
                queryClient.invalidateQueries(keys.detail(data.id));

                if (fileProps != null) {
                    // TODO QUESTION we should delete this if there is not file update feature
                    queryClient.setQueriesData(keys.lists(), (queryData: any) => {
                        if (queryData != null) {
                            return {
                                ...queryData,
                                items: queryData.items.map((item: File) => (item.id === data.id ? data : item)),
                            };
                        }
                    });
                    queryClient.invalidateQueries({ queryKey: keys.lists(), refetchType: 'none' });
                } else {
                    queryClient.invalidateQueries({ queryKey: keys.lists() });
                }
            }
        },
        onError: (error, { options }) => {
            if (options?.onError != null) {
                options.onError(new Error(JSON.stringify(error)));
            }
        },
    });
};
