import { notification } from 'antd';

type NotificationType = 'success' | 'info' | 'warning' | 'error';

export const notifyWithIcon = (type: NotificationType, message: any) => {
    notification[type]({
        style: {
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '24px',
            color: 'var(--gray-9)',
        },
        message,
        placement: 'bottomRight',
        duration: 5,
    });
};
