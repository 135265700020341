import type React from 'react';
import { useTranslation } from 'react-i18next';
import { CustomDrawer } from '@/components/CustomDrawer';
import { AssuranceScoreCardLegendItem } from '../AssuranceScoreCardLegendItem';
import { RiskLevel } from '../../PercentageBar';
import './styles.css';

type AssuranceScoreCardDrawerProps = {
    open: boolean | undefined;
    onClose: () => void;
};

export const AssuranceScoreCardDrawer: React.FC<AssuranceScoreCardDrawerProps> = ({ open, onClose }) => {
    const { t } = useTranslation();

    const getLevelSection = (level: RiskLevel) => {
        return (
            <div className="assurance-score-card-drawer-entry">
                <AssuranceScoreCardLegendItem riskLevel={level} />
                <div className="assurance-score-card-drawer-entry-description">{t(`SHARED.RISK.DESCRIPTION.${level}`)}</div>
            </div>
        );
    };

    return (
        <CustomDrawer
            title={t('PAGES.RESULT.ASSURANCE_SCORE')}
            onClose={() => {
                onClose?.();
            }}
            open={open}
        >
            <div className="assurance-score-card-drawer-description">{t('PAGES.RESULT.ASSURANCE_SCORE_DESCRIPTION')}</div>
            {getLevelSection(RiskLevel.MINIMALISTIC)}
            {getLevelSection(RiskLevel.ADEQUATE)}
            {getLevelSection(RiskLevel.ROBUST)}
            {getLevelSection(RiskLevel.EXCEPTIONAL)}
        </CustomDrawer>
    );
};
