import { Button, Col, Divider, Input, Row } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CopyOutlined, FileImageOutlined, FilePdfOutlined } from '@ant-design/icons';
import { type Certification, type User } from '@/utils/cyberpass';
import { copyToClipboard } from '@/utils/copyToClipboard';
import { triggerFileDownload } from '@/utils/file';
import config from '@/utils/config';
import { useCyberpassFileLookup, useCyberpassProductLookupById, useCyberpassWorkspaceLookupById } from '@/query';
import { FormItemLayout } from '../FormItemsBuilder/FormItemLayout';
import { AssessorDetailsForm } from '../AssessorDetailsForm';
import { FileDownloader } from '../FileDownloader';
import { CustomDrawer } from '../CustomDrawer';
import { useAuth } from '@/hooks/useAuth';
import './styles.css';

type DownloadCertificateDrawerProps = {
    certification: Certification | undefined;
    lab: User | undefined;
};
export const DownloadCertificateDrawer: React.FC<DownloadCertificateDrawerProps> = ({ certification, lab }) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const { user, currentWorkspace } = useAuth();

    const serialNumber = certification?.serialNumber;
    const labelFileId = certification?.labelFileId;
    const shareableObjects = certification?.evaluationResultProps?.shareableObjects;

    const { data: product } = useCyberpassProductLookupById(certification?.productId);
    const { data: productWorkspace } = useCyberpassWorkspaceLookupById(product?.workspaceId);

    const { data: file } = useCyberpassFileLookup(labelFileId);
    const { data: assessmentReportFile } = useCyberpassFileLookup(
        shareableObjects?.find((shareableObject) => shareableObject.name === 'assessmentReportFile')?.content,
    );

    const isMemberOfProductWorkspace = user != null && productWorkspace != null && productWorkspace.membersUserIds.includes(user?.id);

    const isReviewer = currentWorkspace != null && currentWorkspace?.id === certification?.reviewerWorkspaceId;

    const filteredShareableObjects = shareableObjects?.filter((shareableObject) => shareableObject.name !== 'assessmentReportFile');

    const embedCode =
        file != null ? `<a href="${config.FRONTEND_BASE_URL}/certification/${serialNumber}"><img src="${file.url}" alt="Certification label"></img></a>` : null;

    if (!(isMemberOfProductWorkspace || isReviewer)) {
        return null;
    }
    return (
        <>
            <Button
                type="default"
                className="download-certificate-drawer-button"
                onClick={() => {
                    setOpen(true);
                }}
            >
                {t('COMPONENTS.EVALUATION_RESULT_CARD.DOWNLOAD_CERTIFICATE_DRAWER.LABEL')}
            </Button>

            <CustomDrawer
                title={t('COMPONENTS.EVALUATION_RESULT_CARD.DOWNLOAD_CERTIFICATE_DRAWER.TITLE')}
                open={open}
                onClose={(e) => {
                    setOpen(false);
                }}
            >
                <>
                    {file != null && (
                        <>
                            <FormItemLayout
                                label={t('COMPONENTS.EVALUATION_RESULT_CARD.DOWNLOAD_CERTIFICATE_DRAWER.DOWNLOAD_LABEL')}
                                value={
                                    <div className="download-certificate-drawer-download-buttons-container">
                                        <Button
                                            type="primary"
                                            onClick={async () => {
                                                triggerFileDownload(file);
                                            }}
                                        >
                                            <FileImageOutlined /> {t('SHARED.DOWNLOAD_SVG')}
                                        </Button>
                                    </div>
                                }
                            />
                            <Divider orientation="left" plain />
                        </>
                    )}
                    {embedCode != null && (
                        <>
                            <FormItemLayout
                                label={t('COMPONENTS.EVALUATION_RESULT_CARD.DOWNLOAD_CERTIFICATE_DRAWER.EMBED_LABEL')}
                                value={
                                    <div className="download-certificate-drawer-embed-container">
                                        <Input.TextArea //
                                            value={embedCode}
                                            rows={4}
                                            disabled={true}
                                        />
                                        <Button
                                            type="primary"
                                            className="download-certificate-drawer-embed-copy-button"
                                            onClick={() => {
                                                copyToClipboard(embedCode);
                                            }}
                                        >
                                            <CopyOutlined /> {t('SHARED.COPY_CODE')}
                                        </Button>
                                    </div>
                                }
                            />
                            <Divider orientation="left" plain />
                        </>
                    )}
                    {lab != null ? (
                        <FormItemLayout
                            label={t('COMPONENTS.EVALUATION_RESULT_CARD.DOWNLOAD_CERTIFICATE_DRAWER.LAB_REPORT.TITLE')}
                            value={
                                <div className="download-certificate-drawer-download-buttons-container">
                                    {assessmentReportFile != null ? (
                                        <Button
                                            type="primary"
                                            onClick={async () => {
                                                triggerFileDownload(assessmentReportFile);
                                            }}
                                        >
                                            <FilePdfOutlined /> {t('SHARED.DOWNLOAD_REPORT')}
                                        </Button>
                                    ) : (
                                        <div>
                                            <p>{t('COMPONENTS.EVALUATION_RESULT_CARD.DOWNLOAD_CERTIFICATE_DRAWER.LAB_REPORT.EMPTY_DESCRIPTION')}</p>
                                            <AssessorDetailsForm //
                                                user={lab ?? undefined}
                                                editable={false}
                                            />
                                        </div>
                                    )}
                                </div>
                            }
                        />
                    ) : (
                        <Row>
                            <Col span={8}>{t('COMPONENTS.EVALUATION_RESULT_CARD.DOWNLOAD_CERTIFICATE_DRAWER.LAB_REPORT.TITLE')}: </Col>
                            <Col span={16}>{t('COMPONENTS.EVALUATION_RESULT_CARD.DOWNLOAD_CERTIFICATE_DRAWER.LAB_REPORT.NO_LAB')}</Col>
                        </Row>
                    )}
                    {filteredShareableObjects != null && filteredShareableObjects.length > 0 && (
                        <>
                            <Divider orientation="left" plain />
                            <FormItemLayout
                                label={t('SHARED.ADDITIONAL_FILES')}
                                value={filteredShareableObjects.flatMap((shareableObject) => {
                                    if (shareableObject.type === 'file-downloader') {
                                        return [
                                            <FileDownloader
                                                key={shareableObject.name}
                                                fileIds={[shareableObject.content]}
                                                title={shareableObject.name}
                                                showHeader={false}
                                            />,
                                        ];
                                    }
                                    return [];
                                })}
                            />
                        </>
                    )}

                    {/* {file != null && (
                        <>
                            <Divider orientation="left" plain />
                            <FormItemLayout
                                label={t('COMPONENTS.EVALUATION_RESULT_CARD.DOWNLOAD_CERTIFICATE_DRAWER.AUTOMATIC_GENERATED_REPORT_DOWNLOAD')}
                                value={
                                    <div className="download-certificate-drawer-download-buttons-container">
                                        <Button
                                            type="primary"
                                            onClick={async () => {
                                                triggerFileDownload(file);
                                            }}
                                        >
                                            <FilePdfOutlined /> {t('SHARED.DOWNLOAD_REPORT')}
                                        </Button>
                                    </div>
                                }
                            />
                        </>
                    )} */}
                </>
            </CustomDrawer>
        </>
    );
};
